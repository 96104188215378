/**
 * @generated SignedSource<<e0be84131ba109acb1fc388cbe74ca92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HomepageEditorialsQuery$variables = {};
export type HomepageEditorialsQuery$data = {
  readonly editorial: {
    readonly editorials: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly author: {
            readonly biography: string | null;
            readonly followerCount: number;
            readonly fullName: string | null;
            readonly id: string;
            readonly pk: string;
            readonly profileImageUrl: string;
            readonly totalVolume: {
              readonly totalVolumeInEth: number | null;
              readonly totalVolumeInUsd: number | null;
            } | null;
            readonly username: string;
          } | null;
          readonly backgroundImageUrl: string;
          readonly description: string;
          readonly id: string;
          readonly pageUrl: string;
          readonly pk: string;
          readonly slug: string;
          readonly title: string | null;
          readonly type: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};
export type HomepageEditorialsQuery = {
  variables: HomepageEditorialsQuery$variables;
  response: HomepageEditorialsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExploreEditorialQuery",
    "kind": "LinkedField",
    "name": "editorial",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 12
          },
          {
            "kind": "Literal",
            "name": "sortOrder",
            "value": "DESC"
          },
          {
            "kind": "Literal",
            "name": "sortType",
            "value": "PUBLISHED_AT"
          }
        ],
        "concreteType": "EditorialConnection",
        "kind": "LinkedField",
        "name": "editorials",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EditorialEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ModularPageType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountAbstractType",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "biography",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "followerCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profileImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TotalVolumeType",
                        "kind": "LinkedField",
                        "name": "totalVolume",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalVolumeInEth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalVolumeInUsd",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "backgroundImageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pageUrl",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "editorials(first:12,sortOrder:\"DESC\",sortType:\"PUBLISHED_AT\")"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomepageEditorialsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomepageEditorialsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4e8dc68dfca7f8bc705efb9e423cabc0",
    "id": null,
    "metadata": {},
    "name": "HomepageEditorialsQuery",
    "operationKind": "query",
    "text": "query HomepageEditorialsQuery {\n  editorial {\n    editorials(first: 12, sortType: PUBLISHED_AT, sortOrder: DESC) {\n      edges {\n        node {\n          author {\n            biography\n            followerCount\n            fullName\n            id\n            pk\n            profileImageUrl\n            totalVolume {\n              totalVolumeInEth\n              totalVolumeInUsd\n            }\n            username\n          }\n          backgroundImageUrl\n          description\n          id\n          pageUrl\n          pk\n          slug\n          title\n          type\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "05d8e6e518665de84b19504e845327ca";

export default node;
