import { KeyboardEvent, MouseEvent, ReactNode } from 'react';

import { MPColorClass, MPFonts } from '@mp-frontend/core-components';
import { LikesIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import useLikeProduct from 'hooks/product/useLikeProduct';
import { NFTType } from 'types/graphql/NFT';
import withLoginRequiredClick from 'utils/hocs/withLoginRequiredClick';
import toNumericShorthand from 'utils/numericShorthand';

import * as styles from 'css/pages/product/ProductSocialBar.module.css';

interface LikeButtonProps {
  children: ReactNode;
  className: string;
  onClick: (event: MouseEvent) => void;
  onKeyPress: (event: KeyboardEvent) => void;
}

const LikeButton = withLoginRequiredClick<LikeButtonProps>(
  ({ onClick, onKeyPress, className, children }: LikeButtonProps) => (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onKeyPress}
      className={className}
    >
      {children}
    </div>
  )
);

interface LikeProps {
  nft: NFTType;
}

export default function ProductLike({ nft }: LikeProps) {
  const [likesCount, didUserLike, onMouseLike, onKeyboardLike] = useLikeProduct(
    { nft }
  );

  return (
    <LikeButton
      onClick={onMouseLike}
      onKeyPress={onKeyboardLike}
      className={joinClasses('hoverableLink', styles.action, styles.likes, {
        [MPColorClass.GoldMain]: didUserLike,
      })}
    >
      <LikesIcon
        variant={didUserLike ? 'filled' : 'outlined'}
        sx={{ width: '20px' }}
      />
      <span className={MPFonts.textSmallSemiBold}>
        {likesCount > 0 ? toNumericShorthand(likesCount) : 'Like'}
      </span>
    </LikeButton>
  );
}
