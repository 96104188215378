import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  Formik,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import {
  fetchQuery,
  PreloadedQuery,
  usePreloadedQuery,
  useRelayEnvironment,
} from 'react-relay';
import { Hash } from 'viem';
import * as Yup from 'yup';

import {
  MPActionButton,
  MPFonts,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AddressIsNotCustodialTaggedNode, {
  AddressIsNotCustodialQuery,
} from 'graphql/__generated__/AddressIsNotCustodialQuery.graphql';
import NFTsManagementRequest, {
  NFTsManagementQuery,
} from 'graphql/__generated__/NFTsManagementQuery.graphql';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import ErrorDisplay from 'components/Error';
import { WalletActionButton } from 'components/wallet/WalletClickActionComponent';
import { APP_NAME } from 'constants/Utils';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import CSSMargin from 'types/enums/css/Margin';
import { NFTType } from 'types/graphql/NFT';
import { areSameAddress } from 'utils/areSameAddress';

import NFTTransferConfirm from './Confirm';
import PayByCreditCard from './PayByCreditCard';
import WaitOnTransactionHashDialog from './WaitOnTransactionHashDialog';

interface NFTTransferActionProps {
  closeAndInvalidate: () => void;
  managementQueryRef: PreloadedQuery<NFTsManagementQuery>;
  nft: NFTType;
}

const custodialAddressMap = {};

const VALID_ETH_ADDRESS_REGEX = /^0x[0-9a-fA-F]{40}$/;

function useValidate(currentOwnerAddress) {
  const schema = useMemo(
    () =>
      Yup.object().shape({
        address: Yup.string()
          .required('Required')
          .matches(VALID_ETH_ADDRESS_REGEX, 'Please enter a valid address')
          .test({
            message: 'You cannot send to yourself',
            name: 'sameAddress',
            test: (val) =>
              !areSameAddress(val.trim() as Hash, currentOwnerAddress),
          })
          .test({
            message: `Please do not send anything to a ${APP_NAME} address`,
            name: 'isCustodial',
            test: (val) => custodialAddressMap[val] !== false,
          }),
      }),
    [currentOwnerAddress]
  );
  return useCallback(
    (values) => {
      try {
        validateYupSchema(values, schema, true);
      } catch (err) {
        return yupToFormErrors(err);
      }
      return undefined;
    },
    [schema]
  );
}

function TransferContent({ nft, close, closeAndInvalidate }) {
  const formikProps = useFormikContext<{ address: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [isDialogOpen, openDialog, closeDialog] = useSimpleDialogController({
    preventDefault: true,
  });
  const environment = useRelayEnvironment();

  const { handleChange, setTouched } = formikProps;
  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      if (VALID_ETH_ADDRESS_REGEX.test(event.target.value.trim())) {
        setIsLoading(true);
        const isValid = await fetchQuery<AddressIsNotCustodialQuery>(
          environment,
          AddressIsNotCustodialTaggedNode,
          { address: event.target.value.trim() },
          { networkCacheConfig: { force: false } }
        )
          .toPromise()
          .then((resp) => resp.isValidRecipientAddress)
          .catch((err) => setError(err));
        custodialAddressMap[event.target.value.trim()] = isValid;
        setTouched({ address: true }, true);
      }
      setIsLoading(false);
    },
    [handleChange, environment, setTouched]
  );
  return (
    <StackStateDialog
      title="Transfer"
      onClose={close}
      actionButton={
        <MPActionButton
          fullWidth
          onClick={openDialog}
          isLoading={isLoading}
          disabled={
            formikProps.isSubmitting ||
            !formikProps.isValid ||
            custodialAddressMap[formikProps.values.address.trim()] !== true
          }
        >
          Continue
        </MPActionButton>
      }
    >
      <div
        className={joinClasses(MPFonts.textSmallSemiBold, CSSMargin.BOTTOM[4])}
      >
        Transfer &#34;{nft.metadata.title}&#34; to:
      </div>
      <ErrorDisplay error={error} />
      <MPStyledTextField
        name="address"
        error={!!formikProps.touched.address && formikProps.errors.address}
        value={formikProps.values.address}
        onChange={onChange}
        onBlur={formikProps.handleBlur}
        placeholder="eg. 0x42...0a80b"
      />
      {!!isDialogOpen && (
        <NFTTransferConfirm
          nft={nft}
          toAddress={formikProps.values.address}
          close={closeDialog}
          closeAndInvalidate={closeAndInvalidate}
        />
      )}
    </StackStateDialog>
  );
}

const initialValues = {
  address: '',
};

export default function NFTTransferAction({
  nft,
  managementQueryRef,
  closeAndInvalidate,
}: NFTTransferActionProps) {
  const [isDialogOpen, openDialog, closeDialog] = useSimpleDialogController({
    preventDefault: true,
  });
  const [isPendingDialogOpen, openPendingDialog, closePendingDialog] =
    useSimpleDialogController({
      preventDefault: true,
    });
  const [
    isPayByCreditCardDialogOpen,
    openPayByCreditCardDialog,
    closePayByCreditCardDialog,
  ] = useSimpleDialogController({
    preventDefault: true,
  });
  const { nfts } = usePreloadedQuery<NFTsManagementQuery>(
    NFTsManagementRequest,
    managementQueryRef
  );

  const pendingSend = nfts.edges?.[0].node?.pendingSend;

  const validate = useValidate(nft.currentOwnerAddress);

  return (
    <>
      {pendingSend ? (
        <MPActionButton
          variant="tertiary"
          onClick={
            pendingSend.ethTx ? openPendingDialog : openPayByCreditCardDialog
          }
        >
          Sending...
        </MPActionButton>
      ) : nft.isCustodialOwner ? (
        <MPActionButton variant="tertiary" onClick={openDialog}>
          Transfer
        </MPActionButton>
      ) : (
        <WalletActionButton
          requiredAddress={nft.currentOwnerAddress as Hash}
          variant="tertiary"
          onAction={openDialog}
        >
          Transfer
        </WalletActionButton>
      )}
      {!!isDialogOpen && (
        <Formik
          initialValues={initialValues}
          onSubmit={() => null}
          validate={validate}
        >
          <TransferContent
            nft={nft}
            close={closeDialog}
            closeAndInvalidate={closeAndInvalidate}
          />
        </Formik>
      )}
      {!!isPendingDialogOpen && (
        <WaitOnTransactionHashDialog
          nft={nft}
          close={closePendingDialog}
          closeAndInvalidate={closeAndInvalidate}
        />
      )}
      {!!isPayByCreditCardDialogOpen && !!pendingSend && (
        <PayByCreditCard
          gasRequestID={pendingSend.gasRequest.pk}
          close={closePayByCreditCardDialog}
          address={pendingSend.recipientAddress}
          closeAndInvalidate={closeAndInvalidate}
          nft={nft}
        />
      )}
    </>
  );
}
