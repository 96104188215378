import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const MakersPlaceIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <rect
      width="16"
      height="16"
      transform="translate(4 4)"
      fill="currentColor"
    />
    <path
      d="M15.1499 8.84993V15.1499H8.84985V8.84993H15.1499ZM16.7975 7.19934H7.19751V16.7993H16.7975V7.19934Z"
      fill="white"
    />
  </MPSVGIcon>
));

export default MakersPlaceIcon;
