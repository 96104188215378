import { ReactNode, useEffect, useState } from 'react';

import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import * as styles from 'css/components/common/LineClamp.module.css';

interface LineClampProps {
  className: string;
  lineClamp: number;
  text: string;
  clampedNode?: ReactNode;
  heightPct?: number;
}

export default function LineClamp({
  lineClamp,
  text,
  className,
  clampedNode,
  heightPct,
}: LineClampProps) {
  const [clampedDiv, setRef] = useRefState<HTMLDivElement>(null);
  const [isClamped, setClamped] = useState(false);

  useEffect(() => {
    setClamped(false);
  }, [text]);

  useEffect(() => {
    if (clampedDiv) {
      setClamped(clampedDiv.scrollHeight > clampedDiv.clientHeight);
    }
  }, [clampedDiv]);

  return (
    <>
      <div
        ref={setRef}
        style={
          {
            '--height-pct': heightPct ? `${heightPct}%` : undefined,
            '--line-clamp': lineClamp,
          } as any
        }
        className={joinClasses(className, styles.lineClampText)}
      >
        {text}
      </div>
      {!!isClamped && clampedNode}
    </>
  );
}
