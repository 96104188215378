import { startTransition, useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-relay';
import * as Sentry from '@sentry/react';

import { MPActionButton } from '@mp-frontend/core-components';

import CreateSaleWithCustodialWalletMutation from 'graphql/__generated__/ListingCreateSaleWithCustodialWalletMutation.graphql';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import { useHandleSetUSD } from 'hooks/pricing/useMax';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';

import Listing from '..';
import Congratulations from './Congratulations';

interface CustodialListingProps {
  close: () => void;
  invalidate: () => void;
  nft: NFTType;
}

function zeroToBlank(n: number): string {
  return n ? n.toString() : '';
}

export default function CustodialListing({
  nft,
  close,
  invalidate,
}: CustodialListingProps) {
  const [buyNowPrice, setBuyNowPrice] = useState(
    zeroToBlank(nft.listing.lowestAskInUsd)
  );
  const [, handleSetBuyNowUSD] = useHandleSetUSD(setBuyNowPrice);
  const [isValidating, setIsValidating] = useState(false);

  const [commitCreateSaleWithCustodialWalletMutation] = useMutation(
    CreateSaleWithCustodialWalletMutation
  );
  const [finishedListing, setFinishedListing] = useState(false);
  const [error, setError] = useState<Error>(undefined);

  const createOrUpdateSaleWithCustodialWallet = useCallback(
    async ({ productId, salePrice, availableForSale }) =>
      new Promise((resolve, reject) => {
        setError(undefined);
        commitCreateSaleWithCustodialWalletMutation({
          onCompleted: (data: any) => {
            resolve(data);
          },
          onError(err) {
            setError(err);
            reject(err);
          },
          variables: {
            request_data: {
              availableForSale,
              productId,
              salePrice,
            },
          },
        });
      }),
    [commitCreateSaleWithCustodialWalletMutation]
  );

  const confirmCustodialListing = useCallback(async () => {
    try {
      setIsValidating(true);
      await createOrUpdateSaleWithCustodialWallet({
        availableForSale: true,
        productId: parseInt(nft.listing.pk, 10),
        salePrice: buyNowPrice,
      });
      setIsValidating(false);
      startTransition(() => {
        setFinishedListing(true);
      });
    } catch (err) {
      setIsValidating(false);
      setError(err);
      err.errorContext = { nftId: nft.pk };
      Sentry.captureException(err);
    }
  }, [
    nft.listing.pk,
    nft.pk,
    buyNowPrice,
    createOrUpdateSaleWithCustodialWallet,
  ]);

  const closeAndInvalidate = useCallback(() => {
    startTransition(() => {
      invalidate();
      close();
    });
  }, [close, invalidate]);

  const actionButtonJSX = useMemo(
    () => (
      <MPActionButton
        fullWidth
        onClick={confirmCustodialListing}
        disabled={
          isValidating ||
          (parseFloat(buyNowPrice) || 0) === 0 ||
          (!!nft.listing.lowestAskInUsd &&
            nft.listing.lowestAskInUsd.toString() === buyNowPrice)
        }
        size="large"
      >
        {nft.listing.liveSale ? 'Confirm' : 'List Artwork'}
      </MPActionButton>
    ),
    [
      confirmCustodialListing,
      isValidating,
      nft.listing.liveSale,
      buyNowPrice,
      nft.listing.lowestAskInUsd,
    ]
  );

  return (
    <StackStateDialog
      title={nft.listing.liveSale ? 'Edit Price' : 'Sell Artwork'}
      onClose={close}
      actionButton={actionButtonJSX}
    >
      <Listing
        nft={nft}
        error={error}
        currencyDisplay={CurrencyDisplayMode.USD}
        price={buyNowPrice}
        handleSetPrice={handleSetBuyNowUSD}
      />
      {!!finishedListing && (
        <Congratulations
          onClose={closeAndInvalidate}
          didList={nft.listing.liveSale}
        />
      )}
    </StackStateDialog>
  );
}
