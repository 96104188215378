import { startTransition, Suspense, useCallback, useState } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { CheckCircleIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import useApprovedCreatorRegistry from 'hooks/contracts/ApprovedCreatorRegistry';
import useSaleContract from 'hooks/useSaleContract';
import ProductPendingOnChain from 'pages/product/ProductPendingOnChain';
import SuccessView from 'pages/product/purchaseOfferDialog/SuccessView';
import CSSMargin from 'types/enums/css/Margin';

import ProductApprovalExternal from '../../ProductApprovalExternal';
import ProductApprovalOBO from '../../ProductApprovalOBO';

export default function GrantApproveAll({
  nft,
  message,
  close,
  invalidate,
  saleContractApprovalRefetch,
  finishGrantingApproval,
}) {
  const [currentTransactionHash, setCurrentTransactionHash] =
    useState<string>(null);
  const [finishedApproval, setFinishedApproval] = useState(false);
  const showCongrats = useCallback(() => setFinishedApproval(true), []);
  const saleContract = useSaleContract(nft);
  const invalidateNFTForSaleApproval = useCallback(
    () =>
      startTransition(() => {
        saleContractApprovalRefetch();
        invalidate();
      }),
    [saleContractApprovalRefetch, invalidate]
  );
  const approvalRegistryContract = useApprovedCreatorRegistry();

  const actionButtonJSX = !nft.contract.isExternal ? (
    <ProductApprovalOBO
      approvalRegistryContract={approvalRegistryContract}
      currentTransactionHash={currentTransactionHash}
      invalidateNFTForSaleApproval={invalidateNFTForSaleApproval}
      saleContract={saleContract}
      setCurrentTransactionHash={setCurrentTransactionHash}
      nft={nft}
    />
  ) : (
    <ProductApprovalExternal
      externalTokenContract={nft.contract}
      currentTransactionHash={currentTransactionHash}
      invalidateNFTForSaleApproval={invalidateNFTForSaleApproval}
      saleContract={saleContract}
      setCurrentTransactionHash={setCurrentTransactionHash}
      nft={nft}
    />
  );

  return (
    <StackStateDialog
      title={nft.listing.liveSale ? 'Edit Price' : 'Sell Artwork'}
      onClose={close}
      actionButton={actionButtonJSX}
    >
      <>
        <div
          className={joinClasses(CSSMargin.BOTTOM[10], MPFonts.paragraphNormal)}
        >
          {message}
        </div>
        {!!currentTransactionHash && (
          <StackStateDialog title="Granting Approval" shouldBreak>
            <Suspense>
              <ProductPendingOnChain
                queryVariables={{ txtId: currentTransactionHash }}
                onSuccess={showCongrats}
                nft={nft}
              />
            </Suspense>
            {!!finishedApproval && (
              <SuccessView
                Icon={CheckCircleIcon}
                title="Success!"
                message="Approval Granted"
                onClose={finishGrantingApproval}
              />
            )}
          </StackStateDialog>
        )}
      </>
    </StackStateDialog>
  );
}
