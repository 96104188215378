import { useMemo } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import DropVideoPlayer, {
  DropVideoPlayerMode,
} from 'pages/drops/components/VideoPlayer';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/modularPage/sections/VideoBlockSection.module.css';

const VIDEO_ID_REGEX: ReadonlyArray<{ baseUrl: string; regex: RegExp }> = [
  {
    baseUrl: 'https://www.youtube.com/embed/',
    regex:
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(live\/)|(watch\?))\??v?=?(?<videoId>[^#&?]*).*/,
  },
  {
    baseUrl: 'https://www.tiktok.com/embed/v3/',
    regex: /^.*tiktok\.com\/@[^/]+\/video\/(?<videoId>[^#&?]*).*/,
  },
  {
    baseUrl: 'https://player.vimeo.com/video/',
    regex: /^.*vimeo\.com\/(?<videoId>[^#&?]*).*/,
  },
];

const useEmbedUrl = (url: string): string =>
  useMemo(() => {
    if (!url) return url;

    const result = VIDEO_ID_REGEX.reduce((memo, { baseUrl, regex }) => {
      if (memo) return memo;

      const match = url.match(regex);
      if (!match?.groups) return null;

      const { videoId } = match.groups;
      if (!videoId) return null;

      return `${baseUrl}${videoId}`;
    }, null as string);

    return result || url;
  }, [url]);

interface VideoBlockSectionProps {
  externalUrl: string;
  videoUrl: string;
  className?: string;
}

function VideoBlockSection({
  videoUrl,
  externalUrl,
  className,
}: VideoBlockSectionProps) {
  const embedUrl = useEmbedUrl(externalUrl);

  return (
    <section
      className={joinClasses(
        CSSGlobal.Flex.Col,
        CSSGap[16],
        styles.container,
        className
      )}
    >
      {videoUrl ? (
        <DropVideoPlayer
          source={videoUrl}
          cover
          mode={DropVideoPlayerMode.HERO}
        />
      ) : (
        <iframe
          title="Video Player"
          src={embedUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </section>
  );
}

export default VideoBlockSection;
