.assetWrapper {
  height: 120px;
  width: 120px;
}

.nftCard {
  padding: 16px;
  gap: 10px;
  background-color: var(--color-backgroundDefault);
  box-sizing: border-box;
  flex-wrap: nowrap;
}

.info {
  align-self: unset;
  flex-direction: column;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
