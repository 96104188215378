.container {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
}

.price {
  margin-left: auto;
}

.secondaryCurrency {
  margin-left: auto;
  color: var(--color-SolidNeutralGray5);
}
