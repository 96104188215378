import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-relay';
import * as Sentry from '@sentry/react';

import NFTIgnoreGlobalBidMutation, {
  NFTIgnoreGlobalBidMutation$data,
  NFTIgnoreGlobalBidMutation$variables,
} from 'graphql/__generated__/NFTIgnoreGlobalBidMutation.graphql';
import NFTRejectProductBidWithCustodialWalletMutation, {
  NFTRejectProductBidWithCustodialWalletMutation$data,
  NFTRejectProductBidWithCustodialWalletMutation$variables,
} from 'graphql/__generated__/NFTRejectProductBidWithCustodialWalletMutation.graphql';
import NFTRejectProductBidWithPersonalWalletInCCMutation, {
  NFTRejectProductBidWithPersonalWalletInCCMutation$data,
  NFTRejectProductBidWithPersonalWalletInCCMutation$variables,
} from 'graphql/__generated__/NFTRejectProductBidWithPersonalWalletInCCMutation.graphql';

import { NFTType } from 'types/graphql/NFT';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

import useRejectPersonalWalletEthBid from './useRejectPersonalWalletEthBid';

interface ProductRejectBidProps {
  invalidate: () => void;
  nft: NFTType;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
  setShowDialog?: Dispatch<SetStateAction<boolean>>;
}

const useProductRejectBid = ({
  nft,
  invalidate,
  setCurrentTransactionHash,
  setShowDialog,
}: ProductRejectBidProps) => {
  const currentBid = nft.listing.liveBid;

  const [rejectCustodialProductBidMutation] = useMutation(
    NFTRejectProductBidWithCustodialWalletMutation
  );
  const rejectCustodialProductBidMutationAsync =
    promisifyMutationWithRequestData<
      NFTRejectProductBidWithCustodialWalletMutation$variables,
      NFTRejectProductBidWithCustodialWalletMutation$data
    >(rejectCustodialProductBidMutation);

  const [rejectCustodialGlobalBidMutation] = useMutation(
    NFTIgnoreGlobalBidMutation
  );
  const rejectCustodialGlobalBidMutationAsync =
    promisifyMutationWithRequestData<
      NFTIgnoreGlobalBidMutation$variables,
      NFTIgnoreGlobalBidMutation$data
    >(rejectCustodialGlobalBidMutation);

  const [rejectCCBidPersonalWalletMutation] = useMutation(
    NFTRejectProductBidWithPersonalWalletInCCMutation
  );
  const rejectCCBidPersonalWalletMutationAsync =
    promisifyMutationWithRequestData<
      NFTRejectProductBidWithPersonalWalletInCCMutation$variables,
      NFTRejectProductBidWithPersonalWalletInCCMutation$data
    >(rejectCCBidPersonalWalletMutation);

  const transitionTxHashShowDialog = (txHash: string) => {
    setCurrentTransactionHash(txHash);
    setTimeout(() => {
      setShowDialog(true);
    }, 200);
  };

  const rejectCustodialCCProductBid = async (): Promise<boolean> => {
    try {
      await rejectCustodialProductBidMutationAsync({
        bidId: currentBid.pk,
      });
      invalidate();
      return true;
    } catch (error) {
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
      return false;
    }
  };

  const ignoreGlobalBid = async (): Promise<boolean> => {
    try {
      await rejectCustodialGlobalBidMutationAsync({
        mediaBidId: parseInt(currentBid.pk, 10),
        productId: parseInt(nft.listing.pk, 10),
      });
      invalidate();
      return true;
    } catch (error) {
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
      return false;
    }
  };

  const rejectPersonalCCBid = async (): Promise<boolean> => {
    try {
      await rejectCCBidPersonalWalletMutationAsync({
        bidId: currentBid.pk,
      });
      invalidate();
      return true;
    } catch (error) {
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
      return false;
    }
  };

  const rejectPersonalWalletEthBid = useRejectPersonalWalletEthBid(nft);

  const handleRejectBidPersonalWallet = async (): Promise<boolean> => {
    if (currentBid.isGlobalBid) {
      return ignoreGlobalBid();
    }
    if (currentBid.isCcBid) {
      return rejectPersonalCCBid();
    }
    return rejectPersonalWalletEthBid
      .rejectPersonalWalletEthBid()
      .then((transactionResult) => {
        transitionTxHashShowDialog(transactionResult);
        return true;
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleRejectBidCustodial = async (): Promise<boolean> => {
    if (currentBid.isGlobalBid) {
      return ignoreGlobalBid();
    }
    return rejectCustodialCCProductBid();
  };

  return {
    handleRejectBidCustodial,
    handleRejectBidPersonalWallet,
    handleRejectBidPersonalWalletError:
      !currentBid.isCcBid && !currentBid.isGlobalBid
        ? rejectPersonalWalletEthBid.error
        : undefined,
    ignoreGlobalBid,
    rejectCustodialCCProductBid,
    rejectPersonalCCBid,
  };
};

export default useProductRejectBid;
