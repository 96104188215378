import {
  ComponentType,
  KeyboardEvent,
  MouseEvent,
  startTransition,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { useMutation } from 'react-relay';
import { Link } from 'react-router-dom';
import { Config, ConnectorNotFoundError, useDisconnect } from 'wagmi';
import { ConnectData } from 'wagmi/query';
import * as Yup from 'yup';
import { useStatsigUser } from '@statsig/react-bindings';

import {
  MPActionButton,
  MPAlert,
  MPAnimations,
  MPColorClass,
  MPDivider,
  MPFonts,
  MPInlineTextLinkButton,
  MPStandardDialog,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import SessionCreateAccountMutationOp, {
  SessionCreateAccountMutation,
} from 'graphql/__generated__/SessionCreateAccountMutation.graphql';
import SessionLoginWithEmailMutationRelayOp, {
  SessionLoginWithEmailMutation,
} from 'graphql/__generated__/SessionLoginWithEmailMutation.graphql';
import Get2FACodeRelayOp, {
  TwoFactorGetCodeMutation,
} from 'graphql/__generated__/TwoFactorGetCodeMutation.graphql';
import { MpErrors, VerifyChannelEnum } from 'types/__generated__/graphql';

import SignUpCompleteDialog from 'components/login/SignUpCompleteDialog';
import { convertAccountToStatsigUser } from 'components/StatsigProvider';
import GOOGLE_API_KEYS from 'constants/Google';
import ROUTES from 'constants/Routes';
import supportedWallets from 'constants/supportedWallets';
import MPGraphQLError from 'errors/MPGraphQLError';
import GTM, { AuthenticationMethod } from 'GTM';
import usePreventScrollEffect from 'hooks/django/usePreventScrollEffect';
import useSession, { useRefreshSession } from 'hooks/useSession';
import useSignInWithWallet from 'hooks/useSignInWithWallet';
import useMPConnect from 'hooks/wallet/useMPConnect';
import IsDjangoContext from 'pages/navbar/IsDjangoContext';
import emptyFunc from 'utils/emptyFunc';
import isWalletError from 'utils/errors/wallet';
import {
  ConnectorName,
  openMetaMaskDeepLink,
  WalletId,
} from 'utils/jwt/walletUtils';
import { EMAIL_REG_EX } from 'utils/loginUtils';
import promisifyMutation from 'utils/promisifyMutation';
import getObfuscatedEmailString from 'utils/string/getObfuscatedEmailString';
import parseUsernameFromEmailString from 'utils/string/parseUserNameFromEmailString';
import {
  getConnectorIcon,
  getConnectorLink,
  getConnectorName,
} from 'utils/wallet/connectorUtils';

import * as styles from 'css/components/LoginRequiredButton.module.css';

import Session from 'Session';

const CONNECTOR_AUTH_METHOD_MAPPING: Record<WalletId, AuthenticationMethod> = {
  [WalletId.Coinbase]: AuthenticationMethod.CoinbaseWallet,
  [WalletId.CoinbaseSDK]: AuthenticationMethod.CoinbaseWallet,
  [WalletId.Metamask]: AuthenticationMethod.MetaMask,
  [WalletId.MetamaskMobile]: AuthenticationMethod.MetaMask,
  [WalletId.MetamaskSDK]: AuthenticationMethod.MetaMask,
  [WalletId.WalletConnect]: AuthenticationMethod.WalletConnect,
} as const;

export enum LoginSignUpModalType {
  Login,
  SignUp,
}

export enum AuthenticationType {
  Email,
  Wallet,
}

declare global {
  interface Window {
    // Note that this is loaded via script tag and may not be loaded right away.
    // Something could go wrong if the user is inhumanly fast or Google is unusually slow.
    grecaptcha: any;
  }
}

const validateLoginForm = (function setValidateForm() {
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required')
      .matches(EMAIL_REG_EX, {
        excludeEmptyString: true,
        message: 'Please enter a valid email',
      }),
    password: Yup.string().required('Password is required'),
  });

  return function validateFormInner(values) {
    try {
      validateYupSchema(values, LoginSchema, true);
    } catch (err) {
      return yupToFormErrors(err);
    }
    return undefined;
  };
})();

const validateSignUpForm = (function setValidateForm() {
  const SignUpSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required')
      .matches(EMAIL_REG_EX, {
        excludeEmptyString: true,
        message: 'Please enter a valid email',
      }),
    password: Yup.string().required('Password is required'),
    passwordConfirm: Yup.string()
      .required('You must confirm your password')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  return function validateFormInner(values) {
    try {
      validateYupSchema(values, SignUpSchema, true);
    } catch (err) {
      return yupToFormErrors(err);
    }
    return undefined;
  };
})();

const parseFormikError = (
  data: string | string[] | FormikErrors<any> | FormikErrors<any>[]
) => {
  if (typeof data === 'string') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.join(', ');
  }

  if (typeof data === 'object') {
    return Object.values(data).join(', ');
  }

  return '';
};

const getTwoFactorConfirmationMessage = (
  channel: string,
  recipient: string
): string => {
  if (channel === VerifyChannelEnum.Email)
    return `Enter the code that was just sent to ${getObfuscatedEmailString(
      recipient
    )}`;

  return `Enter the code send to the number ending in ${recipient.slice(-4)}`;
};

interface BaseWrapperProps {
  // What is this even for?
  onClick?: (event: MouseEvent) => void;
  // This is incorrect, we only use this as boolean
  onKeyPress?: (event: KeyboardEvent) => void;
}

interface BaseComponentProps {
  onClick: (event: MouseEvent) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
}

// This is a convenience type should match passedProps, not an actual guard.
type WrapperPassedProps<T> = Omit<
  T & BaseWrapperProps,
  'onClick' | 'onKeyPress'
>;

const withLoginRequiredClick = <TProps extends BaseWrapperProps>(
  Component: ComponentType<WrapperPassedProps<TProps> & BaseComponentProps>
) => {
  function Wrapper({
    onClick = emptyFunc,
    onKeyPress = emptyFunc,
    ...passedProps
  }: TProps & BaseWrapperProps) {
    // TODO(unbrick): remove this and related logic to restore signup functionality
    const disableSignup = true;

    const session = useSession();
    const isDjango = useContext(IsDjangoContext);
    const {
      signInWithWallet,
      isLoading: isSignInWithWalletLoading,
      isError: isSignInWithWalletError,
      error: signInWithWalletError,
      reset: resetSignInWithWallet,
    } = useSignInWithWallet();
    const {
      connectAsync,
      findConnector,
      isPending: isConnectLoading,
      isError: isConnectError,
      error: connectError,
      reset: resetConnect,
    } = useMPConnect();
    const { disconnectAsync, reset: resetDisconnect } = useDisconnect();
    const { updateUserAsync } = useStatsigUser();

    const refreshSession = useRefreshSession();

    const [modalType, setModalType] = useState<LoginSignUpModalType>(
      disableSignup ? LoginSignUpModalType.Login : LoginSignUpModalType.SignUp
    );
    const changeModalType = (type: LoginSignUpModalType) => {
      setModalType(type);
      GTM.loginModal.trackSwitchModalType(type);
    };

    const [signUpType, setSignUpType] = useState<AuthenticationType>(
      AuthenticationType.Wallet
    );

    const [isLoginRequiredModalOpen, setIsLoginRequiredModalOpen] =
      useState(false);

    const [
      showWelcomeToMakersPlaceMessage,
      setShowWelcomeToMakersPlaceMessage,
    ] = useState(false);

    const [loginError, setLoginError] = useState<MPGraphQLError>();
    const [signUpError, setSignUpError] = useState<MPGraphQLError>();
    const [signUpLoading, setSignUpLoading] = useState(false);

    const [show2FAModal, setShow2FAModal] = useState(false);
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const [twoFactorSessionId, setTwoFactorSessionId] = useState<string>();
    const [sendCodeError, setSendCodeError] = useState<Error>();
    const [twoFactorConfirmationMessage, setTwoFactorConfirmationMessage] =
      useState('');
    const [confirmLoginError, setConfirmLoginError] = useState<Error>();

    const [showRequestRejectedModal, setShowRequestRejectedModal] =
      useState(false);

    const clear2FAData = () => {
      setTwoFactorCode('');
      setTwoFactorSessionId(undefined);
      setSendCodeError(undefined);
      setConfirmLoginError(undefined);
      setTwoFactorConfirmationMessage('');
    };

    const resetModalOnClose = () => {
      setModalType(
        disableSignup ? LoginSignUpModalType.Login : LoginSignUpModalType.SignUp
      );
      setSignUpType(AuthenticationType.Wallet);
      setLoginError(undefined);
      setSignUpError(undefined);
      setSignUpLoading(false);
      setShow2FAModal(false);
      clear2FAData();
      resetSignInWithWallet();
      resetConnect();
      resetDisconnect();
    };

    const closeLoginRequiredModal = () => {
      setIsLoginRequiredModalOpen(false);
      resetModalOnClose();
    };

    const [commitLoginWithEmailMutation, isLoggingInWithEmail] =
      useMutation<SessionLoginWithEmailMutation>(
        SessionLoginWithEmailMutationRelayOp
      );

    const [commitCreateAccountMutation] =
      useMutation<SessionCreateAccountMutation>(SessionCreateAccountMutationOp);

    const commitGenerateAccountMutationAsync = promisifyMutation<
      SessionCreateAccountMutation['variables'],
      SessionCreateAccountMutation['response']
    >(commitCreateAccountMutation);

    const defaultLoginFormikProps = {
      handleSubmit: emptyFunc,
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) =>
        undefined,
      values: {
        email: '',
        password: '',
      },
    };

    const defaultSignUpFormikProps = {
      handleSubmit: emptyFunc,
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) =>
        undefined,
      values: {
        email: '',
        password: '',
        passwordConfirm: '',
      },
    };

    const formikPropsLoginRef = useRef(defaultLoginFormikProps);
    const setLoginEmail = useCallback(
      (val: string) => formikPropsLoginRef.current.setFieldValue('email', val),
      [formikPropsLoginRef]
    );
    const setLoginPassword = useCallback(
      (val: string) =>
        formikPropsLoginRef.current.setFieldValue('password', val),
      [formikPropsLoginRef]
    );
    const formikPropsSignUpRef = useRef(defaultSignUpFormikProps);
    const setSignUpEmail = useCallback(
      (val: string) => formikPropsSignUpRef.current.setFieldValue('email', val),
      [formikPropsSignUpRef]
    );
    const setSignUpPassword = useCallback(
      (val: string) =>
        formikPropsSignUpRef.current.setFieldValue('password', val),
      [formikPropsSignUpRef]
    );
    const setSignUpPasswordConfirm = useCallback(
      (val: string) =>
        formikPropsSignUpRef.current.setFieldValue('passwordConfirm', val),
      [formikPropsSignUpRef]
    );

    const [commitGetVerificationCode, isGettingVerificationCode] =
      useMutation<TwoFactorGetCodeMutation>(Get2FACodeRelayOp);

    const commitGetVerificationCodeAsync = promisifyMutation<
      TwoFactorGetCodeMutation['variables'],
      TwoFactorGetCodeMutation['response']
    >(commitGetVerificationCode);

    const MODAL_TITLES = {
      [LoginSignUpModalType.Login]: {
        [AuthenticationType.Email]: 'Log In',
        [AuthenticationType.Wallet]: 'Log In',
      },
      [LoginSignUpModalType.SignUp]: {
        [AuthenticationType.Email]: 'Sign Up',
        [AuthenticationType.Wallet]: 'Log In or Sign Up',
      },
    };

    const loadVerificationCode = async () => {
      try {
        const { sendTwoFactorCode } = await commitGetVerificationCodeAsync({
          requestData: {
            email: formikPropsLoginRef.current.values.email,
            password: formikPropsLoginRef.current.values.password,
          },
        });
        if (sendTwoFactorCode.success) {
          setTwoFactorConfirmationMessage(
            getTwoFactorConfirmationMessage(
              sendTwoFactorCode.channel,
              sendTwoFactorCode.recipient
            )
          );
          setTwoFactorSessionId(sendTwoFactorCode.twoFactorSessionId);
        } else {
          setSendCodeError({
            message: 'Something unexpected happened, please contact support',
            name: '',
          });
        }
      } catch (error) {
        setSendCodeError(error);
      }
    };

    const onSignUpComplete = () => {
      setIsLoginRequiredModalOpen(false);
      setShowWelcomeToMakersPlaceMessage(true);
    };

    const goToRequestRejectedModal = () => {
      setIsLoginRequiredModalOpen(false);
      setShowRequestRejectedModal(true);
    };

    const tryLoginAgain = () => {
      resetModalOnClose();
      setShowRequestRejectedModal(false);
      setIsLoginRequiredModalOpen(true);
    };

    const connectToSourceAndLoginWithWallet = useCallback(
      async (id: WalletId) => {
        const connector = findConnector(id);

        if (!connector) {
          if (getConnectorLink(id)) window.open(getConnectorLink(id));
          return null;
        }

        let connection: ConnectData<Config>;
        try {
          await disconnectAsync();
          connection = await connectAsync({ connector });
        } catch (error) {
          if (
            isWalletError.userRejected(error) ||
            isWalletError.walletConnectUserRejectedConnection(error)
          ) {
            goToRequestRejectedModal();
            return null;
          }
          if (
            connector?.name === ConnectorName.MetaMask &&
            error instanceof ConnectorNotFoundError
          ) {
            openMetaMaskDeepLink();
          } else {
            setIsLoginRequiredModalOpen(true);
            return null;
          }
        }

        GTM.loginModal.trackConnectWallet(CONNECTOR_AUTH_METHOD_MAPPING[id]);
        setIsLoginRequiredModalOpen(true);
        return signInWithWallet(connection.accounts[0]);
      },
      [findConnector, connectAsync, disconnectAsync, signInWithWallet]
    );

    const handleMouseClick = (event: MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();

      if (session.isLoggedIn()) {
        setIsLoginRequiredModalOpen(false);
        setShowWelcomeToMakersPlaceMessage(false);
        onClick(event);
      } else {
        GTM.loginModal.trackOpenModal();
        setIsLoginRequiredModalOpen(true);
      }
    };

    const handleKeyPress = useOnEnterKey(handleMouseClick);

    const createLoginHandler = useCallback(
      (id: WalletId, closeAtStart = false) =>
        async (event: MouseEvent) => {
          if (closeAtStart) setIsLoginRequiredModalOpen(false);

          const result = await connectToSourceAndLoginWithWallet(id);
          if (!result) return;

          setIsLoginRequiredModalOpen(false);
          GTM.loginModal.trackLoginSuccessful(
            CONNECTOR_AUTH_METHOD_MAPPING[id]
          );
          updateUserAsync(
            convertAccountToStatsigUser(result.loginWithWallet.account)
          );
          if (isDjango) {
            window.parent.location.reload();
          } else {
            onClick(event);
          }
        },
      [updateUserAsync, connectToSourceAndLoginWithWallet, onClick, isDjango]
    );

    const createSignUpHandler = useCallback(
      (id: WalletId, closeAtStart = false) =>
        async (event: MouseEvent) => {
          const authMethod = CONNECTOR_AUTH_METHOD_MAPPING[id];
          GTM.loginModal.trackStartSignUp(authMethod);
          if (closeAtStart) setIsLoginRequiredModalOpen(false);
          const result = await connectToSourceAndLoginWithWallet(id);
          if (!result) return;

          GTM.loginModal.trackSignUpSuccessful(authMethod);
          if (isDjango) {
            window.parent.location.reload();
            return;
          }
          if (result.loginWithWallet.isNewUser) {
            onSignUpComplete();
          } else {
            setIsLoginRequiredModalOpen(false);
            onClick(event);
          }
        },
      [connectToSourceAndLoginWithWallet, onClick, isDjango]
    );

    const CONNECTOR_ACTION_MAPPING = useMemo(
      () =>
        ({
          LOGIN: {
            [WalletId.Coinbase]: createLoginHandler(WalletId.Coinbase),
            [WalletId.Metamask]: createLoginHandler(WalletId.Metamask),
            [WalletId.WalletConnect]: createLoginHandler(
              WalletId.WalletConnect,
              true
            ),
          },
          SIGN_UP: {
            [WalletId.Coinbase]: createSignUpHandler(WalletId.Coinbase),
            [WalletId.Metamask]: createSignUpHandler(WalletId.Metamask),
            [WalletId.WalletConnect]: createSignUpHandler(
              WalletId.WalletConnect,
              true
            ),
          },
        } as const),
      [createLoginHandler, createSignUpHandler]
    );

    const goToSignUpWithEmail = () => {
      GTM.loginModal.trackStartSignUp(AuthenticationMethod.Email);
      changeModalType(LoginSignUpModalType.SignUp);
      setSignUpType(AuthenticationType.Email);
    };

    const getBackButtonCallback = () => {
      if (modalType === LoginSignUpModalType.SignUp) {
        if (signUpType === AuthenticationType.Email) {
          return () => setSignUpType(AuthenticationType.Wallet);
        }
        return null;
      }

      return () => changeModalType(LoginSignUpModalType.SignUp);
    };

    const commitLoginWithEmailMutationAsync = promisifyMutation<
      SessionLoginWithEmailMutation['variables'],
      SessionLoginWithEmailMutation['response']
    >(commitLoginWithEmailMutation);

    const createAccountWithEmail = async (recaptchaToken) => {
      try {
        setSignUpError(undefined);
        const { email, password } = formikPropsSignUpRef.current.values;

        setSignUpLoading(true);

        await commitGenerateAccountMutationAsync({
          requestData: {
            creatorInviteCode: '',
            email,
            fullName: parseUsernameFromEmailString(email),
            inviteCode: '',
            isCreator: false,
            password,
            recaptchaToken,
          },
        });

        const { loginWithEmail } = await commitLoginWithEmailMutationAsync({
          requestData: { email, password, recaptchaToken },
        });
        Session.processLogin(loginWithEmail.token, loginWithEmail.expireAt);
        startTransition(refreshSession);

        await Session.awaitSessionData();

        GTM.loginModal.trackSignUpSuccessful(AuthenticationMethod.Email);
        updateUserAsync(convertAccountToStatsigUser(loginWithEmail.account));

        onSignUpComplete();
        if (isDjango) {
          window.parent.location.reload();
        }
      } catch (error) {
        setSignUpError(error);
      } finally {
        setSignUpLoading(false);
      }
    };

    const present2FAModal = async () => {
      setIsLoginRequiredModalOpen(false);
      setShow2FAModal(true);
      await loadVerificationCode();
    };

    const createSubmitHandler =
      (originalHandler: (recaptchaToken: string) => void) => () => {
        const { grecaptcha } = window;

        grecaptcha.ready(() =>
          grecaptcha
            .execute(GOOGLE_API_KEYS.RECAPTCHA, { action: 'submit' })
            .then((token: string) => originalHandler(token))
        );
      };

    const loginWithEmail = (recaptchaToken: string) => {
      setLoginError(undefined);
      commitLoginWithEmailMutation({
        onCompleted: ({ loginWithEmail: resp }) => {
          Session.processLogin(resp.token, resp.expireAt);
          refreshSession();
          setIsLoginRequiredModalOpen(false);
          GTM.loginModal.trackLoginSuccessful(AuthenticationMethod.Email);
          updateUserAsync(convertAccountToStatsigUser(resp.account));
          if (isDjango) {
            window.parent.location.reload();
          }
          onClick(null);
        },
        onError(error) {
          if (error instanceof MPGraphQLError) setLoginError(error);

          if (error?.name === MpErrors.TwoFactorSessionIdMissing) {
            GTM.loginModal.trackStart2FA();
            setIsLoginRequiredModalOpen(false);
            present2FAModal();
          }
        },
        variables: {
          requestData: {
            email: formikPropsLoginRef.current.values.email,
            password: formikPropsLoginRef.current.values.password,
            recaptchaToken,
          },
        },
      });
    };

    const loginWithEmailWith2FA = (recaptchaToken: string) => {
      setLoginError(undefined);
      commitLoginWithEmailMutation({
        onCompleted: ({ loginWithEmail: resp }) => {
          Session.processLogin(resp.token, resp.expireAt);
          setIsLoginRequiredModalOpen(false);
          refreshSession();
          GTM.loginModal.trackLoginSuccessful(AuthenticationMethod.Email);
          updateUserAsync(convertAccountToStatsigUser(resp.account));
          setShow2FAModal(false);
          clear2FAData();
          if (isDjango) {
            window.parent.location.reload();
          }
          onClick(null);
        },
        onError(error) {
          if (error instanceof MPGraphQLError) setConfirmLoginError(error);
        },
        variables: {
          requestData: {
            email: formikPropsLoginRef.current.values.email,
            password: formikPropsLoginRef.current.values.password,
            recaptchaToken,
            twoFactorCode,
            twoFactorSessionId,
          },
        },
      });
    };

    const handleSubmitOnEnter = useCallback(
      (submitter: () => void) => (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          submitter();
        }
      },
      []
    );

    const backButtonCallback = getBackButtonCallback();

    const leave2FA = () => {
      setShow2FAModal(false);
      setIsLoginRequiredModalOpen(true);
    };

    const onContinueButtonClick = (event: MouseEvent) => {
      setIsLoginRequiredModalOpen(false);
      setShowWelcomeToMakersPlaceMessage(false);
      onClick(event);
    };

    const handleForgotPasswordClick = () =>
      GTM.loginModal.trackForgotPasswordClicked();

    const isLoginLoadingState =
      isSignInWithWalletLoading || isConnectLoading || isLoggingInWithEmail;

    // This should contain all the dialogs opened in this file.
    usePreventScrollEffect(show2FAModal || isLoginRequiredModalOpen);

    if (showRequestRejectedModal) {
      return (
        <MPStandardDialog
          title="Login or Sign Up"
          actionButton={
            <MPActionButton fullWidth onClick={tryLoginAgain} size="large">
              Try Again
            </MPActionButton>
          }
          open={showRequestRejectedModal}
          onClose={() => setShowRequestRejectedModal(false)}
          container={isDjango ? window.parent.document.body : document.body}
        >
          <div className={styles.requestRejectedModal}>
            <p className={styles.requestRejectedMessage}>
              You rejected the request. Please try again.
            </p>
          </div>
        </MPStandardDialog>
      );
    }

    if (show2FAModal) {
      return (
        <MPStandardDialog
          title="Two-Factor Authentication"
          actionButton={
            <>
              <div>
                Didn&apos;t receive a code?&nbsp;
                <MPInlineTextLinkButton onClick={loadVerificationCode}>
                  Resend
                </MPInlineTextLinkButton>
              </div>
              <MPActionButton
                fullWidth
                onClick={createSubmitHandler(loginWithEmailWith2FA)}
                isLoading={isGettingVerificationCode}
              >
                Submit
              </MPActionButton>
            </>
          }
          open={show2FAModal}
          onClose={() => setShow2FAModal(false)}
          onPrefixClick={leave2FA}
          container={isDjango ? window.parent.document.body : document.body}
        >
          <div>
            {!!twoFactorConfirmationMessage && (
              <p>{twoFactorConfirmationMessage}</p>
            )}

            <MPStyledTextField
              placeholder="Authentication Code"
              type="password"
              autoComplete="on"
              fullWidth
              onChange={(e) => setTwoFactorCode(e.target.value)}
              onKeyPress={handleSubmitOnEnter(
                createSubmitHandler(loginWithEmailWith2FA)
              )}
              value={twoFactorCode}
              error={sendCodeError?.message ?? sendCodeError?.toString()}
              size="small"
            />
            {!!confirmLoginError && (
              <>
                <MPAlert severity="error">
                  {confirmLoginError?.message ?? confirmLoginError?.toString()}
                </MPAlert>
                <br />
              </>
            )}
          </div>
        </MPStandardDialog>
      );
    }

    if (showWelcomeToMakersPlaceMessage) {
      return (
        <SignUpCompleteDialog
          open={showWelcomeToMakersPlaceMessage}
          onClose={(event) => onClick(event as any)}
          onContinue={onContinueButtonClick}
          container={isDjango ? window.parent.document.body : document.body}
        />
      );
    }

    return (
      <>
        <Component
          {...passedProps}
          onClick={handleMouseClick}
          onKeyPress={onKeyPress ? handleKeyPress : undefined}
        />
        {!!isLoginRequiredModalOpen && (
          <MPStandardDialog
            title={MODAL_TITLES[modalType][signUpType]}
            open={isLoginRequiredModalOpen}
            container={isDjango ? window.parent.document.body : document.body}
            onClose={closeLoginRequiredModal}
            onPrefixClick={backButtonCallback}
          >
            <div>
              {modalType === LoginSignUpModalType.Login && (
                <div className={styles.modalLoginContent}>
                  {supportedWallets.map((id) => {
                    const connector = findConnector(id);
                    const Icon = getConnectorIcon(id);
                    return (
                      <MPActionButton
                        key={id}
                        onClick={CONNECTOR_ACTION_MAPPING.LOGIN[id]}
                        disabled={isLoginLoadingState}
                        size="large"
                      >
                        <Icon
                          className={
                            isLoginLoadingState ? styles.walletIconDisabled : ''
                          }
                        />
                        &nbsp; &nbsp;Log In with{' '}
                        {connector?.name || getConnectorName(id)}
                      </MPActionButton>
                    );
                  })}

                  {!!isSignInWithWalletError && (
                    <>
                      <MPAlert severity="error">
                        {signInWithWalletError}
                      </MPAlert>
                      <br />
                    </>
                  )}
                  {!!isConnectError && (
                    <>
                      <MPAlert severity="error">
                        {connectError?.message ?? connectError?.toString()}
                      </MPAlert>
                      <br />
                    </>
                  )}

                  <MPDivider className={styles.loginDividerMiddle} />

                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                    onSubmit={createSubmitHandler(loginWithEmail)}
                    validate={validateLoginForm}
                  >
                    {(props) => {
                      formikPropsLoginRef.current = props;

                      return (
                        <form
                          onSubmit={formikPropsLoginRef.current.handleSubmit}
                          className={styles.emailLoginForm}
                        >
                          <MPStyledTextField
                            dontReserveErrorSpace={false}
                            placeholder="Email Address"
                            className={styles.loginEmailAddress}
                            type="email"
                            autoComplete="on"
                            fullWidth
                            onChange={(e) => setLoginEmail(e.target.value)}
                            onBlur={props.handleBlur}
                            onKeyPress={handleSubmitOnEnter(
                              formikPropsLoginRef.current.handleSubmit
                            )}
                            name="email"
                            value={props.values.email}
                            size="small"
                          />

                          <MPStyledTextField
                            dontReserveErrorSpace={false}
                            placeholder="Password"
                            className={styles.loginEmailPassword}
                            type="password"
                            fullWidth
                            onBlur={props.handleBlur}
                            value={props.values.password}
                            onChange={(e) => setLoginPassword(e.target.value)}
                            onKeyPress={handleSubmitOnEnter(
                              formikPropsLoginRef.current.handleSubmit
                            )}
                            name="password"
                            autoComplete="on"
                            size="small"
                          />

                          {!!loginError && (
                            <>
                              <MPAlert severity="error">
                                {loginError?.message ?? loginError?.toString()}
                              </MPAlert>
                              <br />
                            </>
                          )}

                          <MPActionButton
                            type="submit"
                            fullWidth
                            disabled={
                              isLoginLoadingState ||
                              !!validateLoginForm(props.values)
                            }
                            size="large"
                          >
                            Log In
                          </MPActionButton>
                        </form>
                      );
                    }}
                  </Formik>

                  <Link
                    to={ROUTES.FORGOT_PASSWORD()}
                    reloadDocument
                    className={joinClasses(
                      MPFonts.bodyMedium,
                      'underline',
                      'invisibleAnchor',
                      disableSignup
                        ? styles.dontHavePasswordText
                        : styles.forgotPasswordLink,
                      MPAnimations.Color.DarkToLight
                    )}
                    onClick={handleForgotPasswordClick}
                  >
                    Forgot Password?
                  </Link>

                  {!disableSignup && (
                    <span
                      className={joinClasses(
                        MPFonts.bodyMedium,
                        styles.dontHavePasswordText
                      )}
                    >
                      Don&apos;t have an account?{' '}
                      <MPInlineTextLinkButton
                        onClick={() =>
                          changeModalType(LoginSignUpModalType.SignUp)
                        }
                      >
                        Sign Up
                      </MPInlineTextLinkButton>
                    </span>
                  )}

                  <MPDivider className={styles.loginDividerMiddle} />
                  <p
                    className={joinClasses(
                      MPFonts.bodySmall,
                      MPColorClass.SolidNeutralGray3,
                      styles.termsAndAgreementsText
                    )}
                  >
                    This site is protected by reCAPTCHA and the Google{' '}
                    <a
                      href="https://policies.google.com/privacy"
                      className={joinClasses('underline', 'invisibleAnchor')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://policies.google.com/terms"
                      className={joinClasses('underline', 'invisibleAnchor')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>{' '}
                    apply.
                  </p>
                </div>
              )}
              {modalType === LoginSignUpModalType.SignUp && (
                <div className={styles.modalSignUpContent}>
                  {signUpType === AuthenticationType.Wallet && (
                    <div className={styles.signInButtons}>
                      <p
                        className={joinClasses(
                          MPFonts.bodyMedium,
                          styles.signUpSecondaryText
                        )}
                      >
                        Sign up with your wallet or email.
                      </p>
                      {supportedWallets.map((id) => {
                        const connector = findConnector(id);
                        const Icon = getConnectorIcon(id);
                        return (
                          <MPActionButton
                            key={id}
                            onClick={CONNECTOR_ACTION_MAPPING.SIGN_UP[id]}
                            fullWidth
                            disabled={isLoginLoadingState}
                            size="large"
                          >
                            <Icon
                              className={
                                isLoginLoadingState
                                  ? styles.walletIconDisabled
                                  : ''
                              }
                            />
                            &nbsp; &nbsp;Sign Up with{' '}
                            {connector?.name || getConnectorName(id)}
                          </MPActionButton>
                        );
                      })}

                      <MPActionButton
                        onClick={goToSignUpWithEmail}
                        disabled={isLoginLoadingState}
                        size="large"
                        fullWidth
                      >
                        Sign Up with Email
                      </MPActionButton>
                      {!!isSignInWithWalletError && (
                        <>
                          <MPAlert severity="error">
                            {signInWithWalletError}
                          </MPAlert>
                          <br />
                        </>
                      )}
                      {!!isConnectError && (
                        <>
                          <MPAlert severity="error">
                            {connectError?.message ?? connectError?.toString()}
                          </MPAlert>
                          <br />
                        </>
                      )}
                    </div>
                  )}
                  {signUpType === AuthenticationType.Email && (
                    <div className={styles.modalSignUpEmailFields}>
                      <Formik
                        initialValues={{
                          email: '',
                          password: '',
                          passwordConfirm: '',
                        }}
                        onSubmit={createSubmitHandler(createAccountWithEmail)}
                        validate={validateSignUpForm}
                      >
                        {(props) => {
                          formikPropsSignUpRef.current = props;

                          return (
                            <form
                              onSubmit={
                                formikPropsSignUpRef.current.handleSubmit
                              }
                              className={styles.emailLoginForm}
                            >
                              <MPStyledTextField
                                dontReserveErrorSpace={false}
                                label="Email Address"
                                placeholder="example@gmail.com"
                                className={styles.signInButtonsEmailAddress}
                                type="email"
                                autoComplete="on"
                                fullWidth
                                onChange={(e) => setSignUpEmail(e.target.value)}
                                onBlur={props.handleBlur}
                                onKeyPress={handleSubmitOnEnter(
                                  formikPropsSignUpRef.current.handleSubmit
                                )}
                                name="email"
                                value={props.values.email}
                                error={
                                  !!props.touched.email &&
                                  parseFormikError(props.errors.email)
                                }
                                size="small"
                              />

                              <MPStyledTextField
                                dontReserveErrorSpace={false}
                                className={styles.emailSignUpPassword}
                                label="Password"
                                placeholder="Password"
                                type="password"
                                fullWidth
                                onBlur={props.handleBlur}
                                onKeyPress={handleSubmitOnEnter(
                                  formikPropsSignUpRef.current.handleSubmit
                                )}
                                value={props.values.password}
                                onChange={(e) =>
                                  setSignUpPassword(e.target.value)
                                }
                                name="password"
                                error={
                                  !!props.touched.password &&
                                  parseFormikError(props.errors.password)
                                }
                                autoComplete="on"
                                size="small"
                              />
                              <MPStyledTextField
                                dontReserveErrorSpace={false}
                                className={styles.emailSignUpPasswordConfirm}
                                placeholder="Confirm Password"
                                type="password"
                                fullWidth
                                onBlur={props.handleBlur}
                                onKeyPress={handleSubmitOnEnter(
                                  formikPropsSignUpRef.current.handleSubmit
                                )}
                                value={props.values.passwordConfirm}
                                onChange={(e) =>
                                  setSignUpPasswordConfirm(e.target.value)
                                }
                                name="passwordConfirm"
                                error={
                                  !!props.touched.passwordConfirm &&
                                  parseFormikError(props.errors.passwordConfirm)
                                }
                                autoComplete="on"
                                size="small"
                              />

                              {!!signUpError && (
                                <>
                                  <MPAlert severity="error">
                                    {signUpError?.message ??
                                      signUpError?.toString()}
                                  </MPAlert>
                                  <br />
                                </>
                              )}

                              <MPDivider
                                className={styles.emailSignUpDivider}
                              />

                              <MPActionButton
                                type="submit"
                                fullWidth
                                disabled={
                                  signUpLoading ||
                                  !!validateSignUpForm(props.values)
                                }
                                size="large"
                              >
                                Sign Up with Email
                              </MPActionButton>
                            </form>
                          );
                        }}
                      </Formik>
                    </div>
                  )}

                  <p
                    className={joinClasses(
                      MPFonts.bodyMedium,
                      styles.alreadyHaveAnAccountText
                    )}
                  >
                    Already have an account?{' '}
                    <MPInlineTextLinkButton
                      onClick={() =>
                        changeModalType(LoginSignUpModalType.Login)
                      }
                    >
                      Log In
                    </MPInlineTextLinkButton>
                  </p>

                  <MPDivider
                    className={styles.loginDividerTermsAndConditions}
                  />

                  <p
                    className={joinClasses(
                      MPFonts.bodySmall,
                      MPColorClass.SolidNeutralGray3,
                      styles.termsAndAgreementsText
                    )}
                  >
                    By signing up, you agree to the{' '}
                    <Link
                      to={ROUTES.TERMS()}
                      reloadDocument
                      className={joinClasses('underline', 'invisibleAnchor')}
                    >
                      Terms and Conditions
                    </Link>{' '}
                    and{' '}
                    <Link
                      to={ROUTES.PRIVACY()}
                      reloadDocument
                      className={joinClasses('underline', 'invisibleAnchor')}
                    >
                      Privacy Policy
                    </Link>{' '}
                    and agree to receive periodic updates. This site is
                    protected by reCAPTCHA and the Google{' '}
                    <a
                      href="https://policies.google.com/privacy"
                      className={joinClasses('underline', 'invisibleAnchor')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://policies.google.com/terms"
                      className={joinClasses('underline', 'invisibleAnchor')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>{' '}
                    apply.
                  </p>
                </div>
              )}
            </div>
          </MPStandardDialog>
        )}
      </>
    );
  }
  Wrapper.displayName = `withLoginRequiredClick(${
    Component.displayName ?? Component.name ?? 'AnonymousComponent'
  })`;

  return Wrapper;
};

export default withLoginRequiredClick;
