import { usePreloadedQuery } from 'react-relay';

import ExploreHeroArtistQueryType, {
  ExploreHeroArtistQuery,
} from 'graphql/__generated__/ExploreHeroArtistQuery.graphql';

import HeroUserCard from 'components/cards/HeroUserCard';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

interface HeroProps {
  heroArtistQuery: WithLoadQueryProps<ExploreHeroArtistQuery>;
}

function Hero({ heroArtistQuery }: HeroProps) {
  const result = usePreloadedQuery<ExploreHeroArtistQuery>(
    ExploreHeroArtistQueryType,
    heroArtistQuery.queryRef
  );

  const artist = result.artist.heroArtist;

  return artist && <HeroUserCard username={artist.username} user={artist} />;
}

export default withDefaultErrorBoundary(
  withLoadQuery(
    Hero,
    {
      heroArtistQuery: { concreteRequest: ExploreHeroArtistQueryType },
    },
    { grouppedLoadingKey: 'explore:hero-artist' }
  ),
  { errorFallback: null, hideState: true }
);
