import { ComponentProps, ComponentType } from 'react';
import { merge } from 'lodash';
import { dialogTitleClasses } from '@mui/material';

import {
  MPActionButton,
  MPColorValue,
  MPFonts,
} from '@mp-frontend/core-components';
import { MPSVGIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import CopyLinkButton from 'components/buttons/shareButtons/CopyLinkButton';
import TwitterButton from 'components/buttons/shareButtons/TwitterButton';
import CongratulationDialog from 'components/dialogs/CongratulationDialog';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

const defaultDialogSx = {
  [`& .${dialogTitleClasses.root}`]: {
    display: 'none',
  },
};

const defaultIconSx = {
  fontSize: '48px',
};

const variantMapSx = {
  premium: {
    color: MPColorValue.GoldMain,
  },
};

const defaultButtonSx = {
  minWidth: '200px',
};

interface SuccessViewProps {
  Icon: ComponentType<ComponentProps<typeof MPSVGIcon>>;
  message: string;
  onClose: () => void;
  title: string;
  link?: string;
  shouldStack?: boolean;
  tweet?: string;
  variant?: 'default' | 'premium';
}

function SuccessView({
  Icon,
  message,
  onClose,
  title,
  link,
  tweet,
  variant = 'default',
  shouldStack = false,
}: SuccessViewProps) {
  const iconSx = merge({}, defaultIconSx, variantMapSx[variant] ?? {});

  return (
    <CongratulationDialog
      open
      onClose={onClose}
      title={null}
      sx={defaultDialogSx}
      shouldStack={shouldStack}
    >
      <div
        className={joinClasses(styles.successView, styles[variant] as string)}
      >
        <div className={styles.successViewContent}>
          <Icon className={styles.successViewIcon} sx={iconSx} />
          <div className={joinClasses('centerText', MPFonts.headline3)}>
            {title}
          </div>
          <div
            className={joinClasses(
              'centerText',
              MPFonts.textNormalMedium,
              styles.successViewSubTitle
            )}
          >
            {message}
          </div>
        </div>

        {link ? (
          <div className={styles.successViewActions}>
            {tweet ? (
              <TwitterButton link={link} tweet={tweet} variant={variant} />
            ) : null}

            <CopyLinkButton link={link} variant={variant} />
          </div>
        ) : null}

        <MPActionButton onClick={onClose} sx={defaultButtonSx} size="large">
          Continue
        </MPActionButton>
      </div>
    </CongratulationDialog>
  );
}

export default SuccessView;
