/**
 * @generated SignedSource<<a64f41affa0a5f575fcf2b2cad9f6096>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExploreHeroEditorialQuery$variables = {};
export type ExploreHeroEditorialQuery$data = {
  readonly editorial: {
    readonly heroEditorial: {
      readonly author: {
        readonly biography: string | null;
        readonly followerCount: number;
        readonly fullName: string | null;
        readonly id: string;
        readonly pk: string;
        readonly profileImageUrl: string;
        readonly totalVolume: {
          readonly totalVolumeInEth: number | null;
          readonly totalVolumeInUsd: number | null;
        } | null;
        readonly username: string;
      } | null;
      readonly backgroundImageUrl: string;
      readonly description: string;
      readonly id: string;
      readonly pageUrl: string;
      readonly pk: string;
      readonly slug: string;
      readonly title: string | null;
      readonly type: string | null;
    } | null;
  } | null;
};
export type ExploreHeroEditorialQuery = {
  variables: ExploreHeroEditorialQuery$variables;
  response: ExploreHeroEditorialQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExploreEditorialQuery",
    "kind": "LinkedField",
    "name": "editorial",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ModularPageType",
        "kind": "LinkedField",
        "name": "heroEditorial",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountAbstractType",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "biography",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "followerCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileImageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TotalVolumeType",
                "kind": "LinkedField",
                "name": "totalVolume",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalVolumeInEth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalVolumeInUsd",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backgroundImageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pageUrl",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExploreHeroEditorialQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExploreHeroEditorialQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "00eeefe3d12612fafdf0989e9419da79",
    "id": null,
    "metadata": {},
    "name": "ExploreHeroEditorialQuery",
    "operationKind": "query",
    "text": "query ExploreHeroEditorialQuery {\n  editorial {\n    heroEditorial {\n      author {\n        biography\n        followerCount\n        fullName\n        id\n        pk\n        profileImageUrl\n        totalVolume {\n          totalVolumeInEth\n          totalVolumeInUsd\n        }\n        username\n      }\n      backgroundImageUrl\n      description\n      id\n      pageUrl\n      pk\n      slug\n      title\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "082d808c65c9418c6ad67bd2f84d82c2";

export default node;
