/**
 * @generated SignedSource<<51376ea46da00344b03e336ee8db0a44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditorialSortTypeEnum = "PUBLISHED_AT";
export type SortOrderEnum = "DESC" | "ASC";
export type PaginatedExploreEditorialsQuery$variables = {
  cursor?: string | null;
  first?: number | null;
  query?: string | null;
  sortOrder?: SortOrderEnum | null;
  sortType?: EditorialSortTypeEnum | null;
  usernames?: ReadonlyArray<string | null> | null;
};
export type PaginatedExploreEditorialsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExploreEditorialsPaginatedFragment">;
};
export type PaginatedExploreEditorialsQuery = {
  variables: PaginatedExploreEditorialsQuery$variables;
  response: PaginatedExploreEditorialsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortOrder"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "usernames"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "sortOrder"
  },
  {
    "kind": "Variable",
    "name": "sortType",
    "variableName": "sortType"
  },
  {
    "kind": "Variable",
    "name": "usernames",
    "variableName": "usernames"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaginatedExploreEditorialsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ExploreEditorialsPaginatedFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaginatedExploreEditorialsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExploreEditorialQuery",
        "kind": "LinkedField",
        "name": "editorial",
        "plural": false,
        "selections": [
          {
            "alias": "results",
            "args": (v1/*: any*/),
            "concreteType": "EditorialConnection",
            "kind": "LinkedField",
            "name": "editorials",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EditorialEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModularPageType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountAbstractType",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "biography",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "followerCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImageUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TotalVolumeType",
                            "kind": "LinkedField",
                            "name": "totalVolume",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalVolumeInEth",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalVolumeInUsd",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "backgroundImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pageUrl",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "results",
            "args": (v1/*: any*/),
            "filters": [
              "query",
              "sortOrder",
              "sortType",
              "usernames"
            ],
            "handle": "connection",
            "key": "PaginatedExploreEditorialsQuery__results",
            "kind": "LinkedHandle",
            "name": "editorials"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "374a56f589b848e2399a985c006eebd4",
    "id": null,
    "metadata": {},
    "name": "PaginatedExploreEditorialsQuery",
    "operationKind": "query",
    "text": "query PaginatedExploreEditorialsQuery(\n  $cursor: String\n  $first: Int\n  $query: String\n  $sortOrder: SortOrderEnum\n  $sortType: EditorialSortTypeEnum\n  $usernames: [String]\n) {\n  ...ExploreEditorialsPaginatedFragment\n}\n\nfragment ExploreEditorialsPaginatedFragment on Query {\n  editorial {\n    results: editorials(first: $first, after: $cursor, query: $query, sortOrder: $sortOrder, sortType: $sortType, usernames: $usernames) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          author {\n            biography\n            followerCount\n            fullName\n            id\n            pk\n            profileImageUrl\n            totalVolume {\n              totalVolumeInEth\n              totalVolumeInUsd\n            }\n            username\n          }\n          backgroundImageUrl\n          description\n          id\n          pageUrl\n          pk\n          slug\n          title\n          type\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4540923b4970588f9599c0c6c4e703a";

export default node;
