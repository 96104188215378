.header {
  box-sizing: border-box;
  padding: 28px var(--activity-horizontal-padding);

  @media (--mq-is-mobile) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  & > .description {
    max-width: 436px;
  }
}

.list {
  margin: 60px var(--activity-horizontal-padding) 0;

  @media (--mq-is-mobile) {
    margin-top: 20px;
  }

  @media screen and (min-width: 1920px) {
    margin: 60px auto 0;
    max-width: 1700px;
  }

  & .item {
    transition:
      background-color 300ms var(--mp-tense-out),
      border-color 300ms var(--mp-tense-out);
    border: 1px solid;
    border-color: var(--color-commonWhite);
    box-sizing: border-box;
  }

  @media (--mq-is-not-mobile) {
    &:hover .item {
      background-color: var(--color-backgroundDefault);
      border-color: var(--color-SolidNeutralGray1);

      &:hover {
        background-color: var(--color-commonWhite);
        border-color: var(--color-commonWhite);
      }
    }
  }
}

.listItemContainer {
  margin-bottom: 16px;
  display: flex;

  & > .listItemContent {
    background-color: var(--color-commonWhite);
    padding: 15px;
    width: 100%;
  }
}

.listItemContentLayout {
  display: grid;
  grid-template-areas: "image title artist wallet action";
  grid-template-columns: var(--activity-preview-size) 3fr 2fr 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 16px;

  @media (--mq-is-mobile) {
    grid-template-areas:
      "image title title"
      "image artist artist"
      "image wallet wallet"
      "image action action";
    grid-template-columns: var(--activity-preview-size) 1fr max-content;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 4px;
  }

  & > div {
    align-items: center;
    display: flex;
  }

  & .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-area: image;
    height: var(--activity-preview-size);
    width: var(--activity-preview-size);
  }

  & .title {
    grid-area: title;
    overflow: hidden;
  }

  & .artist {
    grid-area: artist;
    overflow: hidden;
  }

  & .wallet {
    grid-area: wallet;
  }

  & .action {
    grid-area: action;
  }
}

.listItemContentHeader {
  padding: 0 16px 11px 16px;
  color: var(--color-SolidNeutralGray5);

  &.listItemContentLayout {
    grid-template-areas: "title image artist wallet action";
    grid-template-columns: 3fr var(--activity-preview-size) 2fr 1fr 1fr;
  }
}
