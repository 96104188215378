.currentMedia {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;

  &.zoomable {
    cursor: zoom-in;
  }

  @media (--mq-is-mobile) {
    height: auto;
    max-height: 70vh;
    overflow: initial;
  }

  > img,
  > picture,
  > picture > img,
  > .video > video {
    height: 100%;
    min-height: 100%;
    object-fit: contain;
    object-position: center;
    opacity: 1;
    position: relative;
    transition: filter 0.2s ease;
    width: 100%;
  }

  &.isLoading {
    > img,
    > picture,
    > picture > img,
    > .video > video {
      opacity: 0;
    }
  }
}


.containerLvl1 {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  top: 0;
  width: 100%;
}

.containerLvl1 > .containerLvl2 {
  flex-direction: column;
  gap: 10px;
  width: 100%;
  display: flex;
}

.containerLvl1 > .containerLvl2 > .containerLvl3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;

  &.zoomable {
    cursor: zoom-in;
  }

  @media (--mq-is-mobile) {
    max-height: 70vh;
    overflow: initial;
  }

  > img,
  > picture,
  > picture > img,
  > .video > video {
    height: 100%;
    min-height: 100%;
    object-fit: contain;
    object-position: center;
    opacity: 1;
    position: relative;
    transition: filter 0.2s ease;
    width: 100%;
  }

  &.isLoading {
    > img,
    > picture,
    > picture > img,
    > .video > video {
      opacity: 0;
    }
  }
}
