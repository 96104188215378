import StackStateDialog from 'components/dialogs/StackStateDialog';
import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import { NFTType } from 'types/graphql/NFT';

import NFTActions from './Actions';
import NFTCard from './Card';

interface ManageNFTDialogProps {
  close: () => void;
  nft: NFTType;
  invalidate?: () => void;
}

export default function ManageNFTDialog({
  nft,
  invalidate,
  close,
}: ManageNFTDialogProps) {
  return (
    <StackStateDialog title="Manage Artwork" open onClose={close}>
      <DefaultErrorBoundary>
        <NFTCard nft={nft} />
        <NFTActions nft={nft} invalidate={invalidate} />
      </DefaultErrorBoundary>
    </StackStateDialog>
  );
}
