.profileImage {
  display: inline-block;

  &.xSmall {
    width: 24px;
    height: 24px;
  }

  &.small {
    width: 28px;
    height: 28px;
  }

  &.medium {
    width: 38px;
    height: 38px;
  }

  &.large {
    width: 68px;
    height: 68px;
  }

  > img {
    border-radius: 50%;
    width: inherit;
    height: inherit;
  }
}
