import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  startTransition,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { usePreloadedQuery } from 'react-relay';

import { MPFonts, MPStyledTextField } from '@mp-frontend/core-components';
import { SearchIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountSearchBasicUserQueryType, {
  AccountSearchBasicUserQuery,
} from 'graphql/__generated__/AccountSearchBasicUserQuery.graphql';
import { UserSearchAccountTypesEnum } from 'types/__generated__/graphql';

import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/components/filters/UsersFilter.module.css';

const MINIMUM_SEARCH_TEXT_LENGTH = 2;
const WAIT_PERIOD_STOP_TYPING = 500;

interface UsersFilterSearchBaseProps {
  setSearchResults: Dispatch<SetStateAction<string[]>>;
  userNames: string[];
  userNamesToExclude: string[];
}

const UsersFilterSearchLoader = withDefaultErrorBoundary(
  withLoadQuery(
    ({
      accountsQuery,
      userNames,
      userNamesToExclude,
      searchText,
      setSearchResults,
    }: UsersFilterSearchBaseProps & {
      accountsQuery: WithLoadQueryProps<AccountSearchBasicUserQuery>;
      searchText: string;
    }) => {
      const { accounts } = usePreloadedQuery<AccountSearchBasicUserQuery>(
        AccountSearchBasicUserQueryType,
        accountsQuery.queryRef
      );
      const { accountSearchType } = accountsQuery.queryRef.variables;

      useEffect(() => {
        const availableUsernames = accounts.edges.map(
          ({ node: { username } }) => username
        );
        const uniqueUsernames = [
          ...new Set([...availableUsernames, ...userNamesToExclude]),
        ].sort();
        const availableForSelection = uniqueUsernames.filter(
          (username) => !userNames.includes(username)
        );
        setSearchResults(availableForSelection);
      }, [accounts, userNames, userNamesToExclude, setSearchResults]);

      useEffect(() => {
        const typingTimer = setTimeout(() => {
          startTransition(() => {
            if (
              searchText.length > MINIMUM_SEARCH_TEXT_LENGTH ||
              searchText.length === 0
            ) {
              accountsQuery.loadQuery({ accountSearchType, searchText });
              if (searchText.length === 0) setSearchResults([]);
            }
          });
        }, WAIT_PERIOD_STOP_TYPING);

        return () => {
          clearTimeout(typingTimer);
        };
      }, [accountSearchType, searchText, accountsQuery, setSearchResults]);

      return null;
    },
    { accountsQuery: { concreteRequest: AccountSearchBasicUserQueryType } }
  ),
  { hideState: true }
);

export default function UsersFilterSearchInput({
  accountSearchType,
  userNames,
  userNamesToExclude,
  label,
  placeholder,
  setSearchResults,
}: UsersFilterSearchBaseProps & {
  accountSearchType: UserSearchAccountTypesEnum;
  label: string;
  placeholder: string;
}) {
  const [searchText, setSearchText] = useState('');
  const handleSearchTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [setSearchText]
  );

  return (
    <div className={styles.usersFilterSearchSection}>
      <div
        className={joinClasses(
          MPFonts.textSmallMedium,
          styles.usersFilterSearchTitle
        )}
      >
        {label}
      </div>
      <MPStyledTextField
        placeholder={placeholder}
        inputMode="text"
        onChange={handleSearchTextChange}
        startAdornment={<SearchIcon className={styles.usersFilterSearchIcon} />}
      />
      <UsersFilterSearchLoader
        accountsQuery={{ variables: { accountSearchType, searchText } }}
        searchText={searchText}
        userNames={userNames}
        userNamesToExclude={userNamesToExclude}
        setSearchResults={setSearchResults}
      />
    </div>
  );
}
