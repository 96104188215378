import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Hash } from 'viem';

import { DefaultLoader } from 'components/DefaultSuspense';
import StackStateDialog from 'components/dialogs/StackStateDialog';
import useTokenReadContract from 'hooks/contracts/useTokenReadContract';
import useSaleContract from 'hooks/useSaleContract';
import { NFTType } from 'types/graphql/NFT';
import { nftHasCustodialSale } from 'utils/nftUtils';

import GrantApproveAll from '../../GrantApproveAll';
import CustodialListing from './CustodialListing';
import useEnsureWallet from './useEnsureWallet';
import WalletListing from './WalletListing';

interface FixedSaleProps {
  close: () => void;
  didCheckSaleContractStatusState: [boolean, Dispatch<SetStateAction<boolean>>];
  invalidate: () => void;
  nft: NFTType;
}

function WalletListingWithCheckApprovals({
  nft,
  invalidate,
  close,
  didCheckSaleContractStatusState,
}: FixedSaleProps) {
  const saleContract = useSaleContract(nft);
  const { useIsApprovedForAll } = useTokenReadContract({
    abi: JSON.parse(nft.contract.abidata).abi,
    contractAddress: nft.contract.address as Hash,
  });

  const [didApprove, setDidApprove] = useState(false);
  const finishGrantingApproval = useCallback(() => setDidApprove(true), []);

  const {
    data: isSaleContractApprovedByUser,
    isFetching: isSaleContractApprovalFetching,
    refetch: saleContractApprovalRefetch,
  } = useIsApprovedForAll({
    operatorAddress: saleContract.address,
    owner: nft.currentOwnerAddress as Hash,
  });

  const [didCheckSaleContractStatus, setDidCheckSaleContractStatus] =
    didCheckSaleContractStatusState;

  useEffect(() => {
    if (!isSaleContractApprovalFetching) setDidCheckSaleContractStatus(true);
  }, [isSaleContractApprovalFetching, setDidCheckSaleContractStatus]);

  const [needsWalletAction, WalletActionJSX] = useEnsureWallet(nft, close);

  return needsWalletAction ? (
    WalletActionJSX
  ) : isSaleContractApprovalFetching && !didCheckSaleContractStatus ? (
    <>
      <StackStateDialog
        title={nft.listing.liveSale ? 'Edit Price' : 'Sell Artwork'}
        onClose={close}
      >
        <DefaultLoader />
      </StackStateDialog>
    </>
  ) : (
    <>
      {!isSaleContractApprovedByUser && !didApprove ? (
        <GrantApproveAll
          invalidate={invalidate}
          saleContractApprovalRefetch={saleContractApprovalRefetch}
          message="You have not given wallet approval so we can transfer your NFT once it sells. Please do so now by clicking below."
          close={close}
          finishGrantingApproval={finishGrantingApproval}
          nft={nft}
        />
      ) : (
        <WalletListing close={close} nft={nft} invalidate={invalidate} />
      )}
    </>
  );
}

export default function FixedSale({
  nft,
  close,
  invalidate,
  didCheckSaleContractStatusState,
}: FixedSaleProps) {
  return nftHasCustodialSale(nft) ? (
    <CustodialListing close={close} nft={nft} invalidate={invalidate} />
  ) : (
    <WalletListingWithCheckApprovals
      close={close}
      nft={nft}
      invalidate={invalidate}
      didCheckSaleContractStatusState={didCheckSaleContractStatusState}
    />
  );
}
