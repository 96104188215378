import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ClickAwayListener, Popper, PopperProps } from '@mui/material';

import { joinClasses, UUID } from '@mp-frontend/core-utils';

import { MPActionButton, MPDialog, useIsMobile } from '../..';
import { ArrowDownIcon, ArrowUpIcon } from '../icons';

import * as styles from '../css/popovers/MPDropdown.module.css';

export interface MPDropdownProps {
  children: ReactNode;

  /**
   * Title of the dropdown.
   * This will be shown on the button that toggles the dropdown.
   */
  title: string;

  /**
   * Since we break dropdown behavior on mobile, we need to provide a title override for the dialog
   * that will be shown on mobile instead of the dropdown.
   * It defaults to the title.
   */
  mobileDialogTitleOverride?: string;

  onToggle?: (open: boolean) => void;
  // Popper placement
  placement?: PopperProps['placement'];
  // Custom class to apply to the popover
  popperClass?: string;
}

export default function MPDropdown({
  title,
  mobileDialogTitleOverride,
  children,
  placement = 'bottom-start',
  onToggle,
  popperClass,
}: MPDropdownProps) {
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useIsMobile();

  const handlePopperScroll = useCallback(
    (event) => {
      if (
        anchorEl &&
        popperRef.current &&
        !popperRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    },
    [anchorEl]
  );

  useEffect(() => {
    if (!isMobile && open) {
      window.addEventListener('scroll', handlePopperScroll, true);
    }
    return () => {
      if (!isMobile) {
        window.removeEventListener('scroll', handlePopperScroll, true);
      }
    };
  }, [open, isMobile, handlePopperScroll]);

  const handleToggle = useCallback(
    (newOpen: boolean) => {
      setOpen(newOpen);
      onToggle?.(newOpen);
    },
    [onToggle]
  );

  const toggle = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      handleToggle(!open);
    },
    [open, handleToggle]
  );
  const close = useCallback(() => handleToggle(false), [handleToggle]);

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const ariaId = useMemo(UUID.weak, []);

  return (
    <ClickAwayListener onClickAway={close}>
      <div ref={containerRef} className={styles.container}>
        <MPActionButton
          type="button"
          variant={open ? 'tertiary-inverted' : 'tertiary'}
          onClick={toggle}
          aria-describedby={ariaId}
          aria-owns={ariaId}
          aria-haspopup="true"
          className={styles.actionButton}
          endIcon={
            open ? (
              <ArrowUpIcon fontSize="15" />
            ) : (
              <ArrowDownIcon fontSize="15" />
            )
          }
        >
          {title}
        </MPActionButton>

        {isMobile ? (
          <MPDialog
            title={mobileDialogTitleOverride ?? title}
            open={open}
            onClose={close}
            classes={{
              container: styles.dialogContainer,
              paper: styles.dialogPaper,
            }}
            contentClasses={{
              root: styles.dialogContentRoot,
            }}
          >
            {children}
          </MPDialog>
        ) : (
          <Popper
            id={ariaId}
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            className={styles.popperContainer}
          >
            <div
              className={joinClasses(styles.popper, popperClass)}
              ref={popperRef}
            >
              {children}
            </div>
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  );
}
