import {
  startTransition,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useMutation } from 'react-relay';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CreateReservePriceMutation from 'graphql/__generated__/ListingCreateReservePriceMutation.graphql';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import Tabs from 'components/tabs/Tabs';
import { useHandleSetETH, useHandleSetUSD } from 'hooks/pricing/useMax';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';

import Congratulations from '../FixedSale/Congratulations';
import Listing from '..';

import * as styles from 'css/pages/product/ProductSetBuyNow.module.css';

const TABS = [CurrencyDisplayMode.ETH, CurrencyDisplayMode.USD].map(
  (currency: CurrencyDisplayMode) => ({
    label: currency,
    to: '#',
    value: currency,
  })
);

interface ProductSetReserveProps {
  close: () => void;
  invalidate: () => void;
  nft: NFTType;
}

export default function ReservePrice({
  nft,
  close,
  invalidate,
}: ProductSetReserveProps) {
  const [activeCurrency, setActiveCurrency] = useState<CurrencyDisplayMode>(
    nft.listing?.reserveCurrency === CurrencyDisplayMode.USD
      ? CurrencyDisplayMode.USD
      : CurrencyDisplayMode.ETH
  );
  const setActiveCurrencyHandler = useCallback(
    (event: SyntheticEvent, value: CurrencyDisplayMode) =>
      setActiveCurrency(value),
    []
  );
  const [didList, setDidList] = useState(false);

  const [reservePriceUSD, setReservePriceUSD] = useState<string>(
    nft.listing.hasReservePrice && activeCurrency === CurrencyDisplayMode.USD
      ? nft.listing.reservePriceInUsd?.toString()
      : null
  );

  const [reservePriceETH, setReservePriceETH] = useState<string>(
    nft.listing.hasReservePrice && activeCurrency === CurrencyDisplayMode.ETH
      ? nft.listing.reservePriceInEth?.toString()
      : null
  );

  const [commitCreateReservePriceMutation] = useMutation(
    CreateReservePriceMutation
  );

  const promisifyMutateReservePrice = useCallback(
    async (productId, reservePrice, reserveCurrency) =>
      new Promise((resolve, reject) => {
        commitCreateReservePriceMutation({
          onCompleted: (data: any) => {
            resolve(data);
          },
          onError(error) {
            reject(error);
          },
          variables: {
            request_data: {
              availableForSale: true,
              productId,
              reserveCurrency,
              reservePrice,
            },
          },
        });
      }),
    [commitCreateReservePriceMutation]
  );

  const [, handleSetReserveUSD] = useHandleSetUSD(setReservePriceUSD);
  const [, handleSetReserveETH] = useHandleSetETH(setReservePriceETH);
  const [usdError, setUsdError] = useState<Error>();
  const [ethError, setEthError] = useState<Error>();

  const [isValidatingTransaction, setIsValidatingTransaction] =
    useState<boolean>(false);

  const confirmListing = useCallback(async () => {
    const errorHandler =
      activeCurrency === CurrencyDisplayMode.USD ? setUsdError : setEthError;
    try {
      errorHandler(undefined);
      setIsValidatingTransaction(true);
      await promisifyMutateReservePrice(
        parseInt(nft.listing.pk, 10),
        activeCurrency === CurrencyDisplayMode.USD
          ? reservePriceUSD
          : reservePriceETH,
        activeCurrency
      );
      setDidList(true);
    } catch (error) {
      errorHandler(error);
    }
    setIsValidatingTransaction(false);
  }, [
    activeCurrency,
    reservePriceUSD,
    reservePriceETH,
    nft.listing.pk,
    promisifyMutateReservePrice,
  ]);

  const isReservePriceZero =
    (parseFloat(
      activeCurrency === CurrencyDisplayMode.USD
        ? reservePriceUSD
        : reservePriceETH
    ) || 0) === 0;
  const isReservePriceTheSame =
    !!nft.listing.hasReservePrice &&
    (activeCurrency === CurrencyDisplayMode.USD
      ? nft.listing.reservePriceInUsd?.toString() === reservePriceUSD
      : nft.listing.reservePriceInEth?.toString() === reservePriceETH);

  const closeAndInvalidate = useCallback(() => {
    startTransition(() => {
      invalidate();
      close();
    });
  }, [close, invalidate]);

  const actionButtonJSX = useMemo(
    () => (
      <MPActionButton
        fullWidth
        onClick={confirmListing}
        disabled={
          isValidatingTransaction || isReservePriceZero || isReservePriceTheSame
        }
        size="large"
      >
        {nft.listing.hasReservePrice ? 'Confirm' : 'List Artwork'}
      </MPActionButton>
    ),
    [
      confirmListing,
      isValidatingTransaction,
      isReservePriceTheSame,
      isReservePriceZero,
      nft.listing.hasReservePrice,
    ]
  );

  return (
    <StackStateDialog
      title={nft.listing.liveSale ? 'Edit Price' : 'Sell Artwork'}
      onClose={close}
      actionButton={actionButtonJSX}
    >
      <div>
        <div
          className={joinClasses(
            MPFonts.paragraphNormal,
            styles.productSellMessageContainer
          )}
        >
          A 24 hour countdown will begin once an offer is made that meets the
          reserve price. The highest offer will be accepted after 24 hours.
        </div>
        <div>
          <Tabs
            currentTab={activeCurrency}
            tabs={TABS}
            onChange={setActiveCurrencyHandler}
          />
          {activeCurrency === CurrencyDisplayMode.USD && (
            <Listing
              nft={nft}
              currencyDisplay={activeCurrency}
              price={reservePriceUSD}
              handleSetPrice={handleSetReserveUSD}
              error={usdError}
            />
          )}
          {activeCurrency === CurrencyDisplayMode.ETH && (
            <Listing
              nft={nft}
              currencyDisplay={activeCurrency}
              price={reservePriceETH}
              handleSetPrice={handleSetReserveETH}
              error={ethError}
            />
          )}
          {!!didList && (
            <Congratulations
              onClose={closeAndInvalidate}
              didList={nft.listing.hasReservePrice}
            />
          )}
        </div>
      </div>
    </StackStateDialog>
  );
}
