/**
 * @generated SignedSource<<32c6273ecc5084ba27426612220e69ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityActionEnum = "SALE" | "TRANSFER" | "OFFER" | "MINT" | "BID" | "LISTING" | "SOLD" | "PURCHASE" | "RECEIVE_BID" | "PLACE_BID" | "REJECT_BID" | "RECEIVE_OFFER" | "PLACE_OFFER" | "REJECT_OFFER";
export type ActivitySortTypeEnum = "CREATED_AT" | "PRICE";
export type CurrencyModeEnum = "ETH" | "USD";
export type SortOrderEnum = "DESC" | "ASC";
export type PriceRangeType = {
  min?: number | null;
  max?: number | null;
};
export type DateTimeRangeType = {
  min?: any | null;
  max?: any | null;
};
export type ActivityPaginatedQuery$variables = {
  first?: number | null;
  cursor?: string | null;
  storeCollectionSlug?: string | null;
  sortOrder?: SortOrderEnum | null;
  sortType?: ActivitySortTypeEnum | null;
  currencyMode?: CurrencyModeEnum | null;
  actions?: ReadonlyArray<ActivityActionEnum | null> | null;
  price?: PriceRangeType | null;
  activityDate?: DateTimeRangeType | null;
  isShowFollowing?: boolean | null;
  userNames?: ReadonlyArray<string | null> | null;
  collectors?: ReadonlyArray<string | null> | null;
  curators?: ReadonlyArray<string | null> | null;
  showFollowingCollectors?: boolean | null;
  isSettings?: boolean | null;
};
export type ActivityPaginatedQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActivityPaginatedFragment">;
};
export type ActivityPaginatedQuery = {
  variables: ActivityPaginatedQuery$variables;
  response: ActivityPaginatedQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "actions"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "activityDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectors"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "curators"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currencyMode"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSettings"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isShowFollowing"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "price"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showFollowingCollectors"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortOrder"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortType"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "storeCollectionSlug"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userNames"
},
v15 = [
  {
    "kind": "Variable",
    "name": "actions",
    "variableName": "actions"
  },
  {
    "kind": "Variable",
    "name": "activityDate",
    "variableName": "activityDate"
  },
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "collectors",
    "variableName": "collectors"
  },
  {
    "kind": "Variable",
    "name": "curators",
    "variableName": "curators"
  },
  {
    "kind": "Variable",
    "name": "currencyMode",
    "variableName": "currencyMode"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isSettings",
    "variableName": "isSettings"
  },
  {
    "kind": "Variable",
    "name": "isShowFollowing",
    "variableName": "isShowFollowing"
  },
  {
    "kind": "Variable",
    "name": "price",
    "variableName": "price"
  },
  {
    "kind": "Variable",
    "name": "showFollowingCollectors",
    "variableName": "showFollowingCollectors"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "sortOrder"
  },
  {
    "kind": "Variable",
    "name": "sortType",
    "variableName": "sortType"
  },
  {
    "kind": "Variable",
    "name": "storeCollectionSlug",
    "variableName": "storeCollectionSlug"
  },
  {
    "kind": "Variable",
    "name": "userNames",
    "variableName": "userNames"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v19 = [
  (v16/*: any*/),
  (v17/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  (v18/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityPaginatedQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ActivityPaginatedFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v5/*: any*/),
      (v13/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v9/*: any*/),
      (v1/*: any*/),
      (v8/*: any*/),
      (v14/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v10/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "ActivityPaginatedQuery",
    "selections": [
      {
        "alias": "results",
        "args": (v15/*: any*/),
        "concreteType": "ActivityConnection",
        "kind": "LinkedField",
        "name": "activities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "transactionUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "action",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceUsd",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceEth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isEthTransaction",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountAbstractType",
                    "kind": "LinkedField",
                    "name": "fromUser",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountAbstractType",
                    "kind": "LinkedField",
                    "name": "toUser",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTAbstractType",
                    "kind": "LinkedField",
                    "name": "digitalMediaRelease",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "printEdition",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTMetadataAbstractType",
                        "kind": "LinkedField",
                        "name": "metadata",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnailImage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalSupply",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountAbstractType",
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v16/*: any*/),
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTListingAbstractType",
                        "kind": "LinkedField",
                        "name": "listing",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "productSlug",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "results",
        "args": (v15/*: any*/),
        "filters": [
          "storeCollectionSlug",
          "sortOrder",
          "sortType",
          "currencyMode",
          "actions",
          "price",
          "activityDate",
          "isShowFollowing",
          "userNames",
          "collectors",
          "curators",
          "showFollowingCollectors",
          "isSettings"
        ],
        "handle": "connection",
        "key": "PaginatedActivityNFTsQuery__results",
        "kind": "LinkedHandle",
        "name": "activities"
      }
    ]
  },
  "params": {
    "cacheID": "4e23d94c2a87b2346cf9433a145a59d8",
    "id": null,
    "metadata": {},
    "name": "ActivityPaginatedQuery",
    "operationKind": "query",
    "text": "query ActivityPaginatedQuery(\n  $first: Int\n  $cursor: String\n  $storeCollectionSlug: String\n  $sortOrder: SortOrderEnum\n  $sortType: ActivitySortTypeEnum\n  $currencyMode: CurrencyModeEnum\n  $actions: [ActivityActionEnum]\n  $price: PriceRangeType\n  $activityDate: DateTimeRangeType\n  $isShowFollowing: Boolean\n  $userNames: [String]\n  $collectors: [String]\n  $curators: [String]\n  $showFollowingCollectors: Boolean\n  $isSettings: Boolean\n) {\n  ...ActivityPaginatedFragment\n}\n\nfragment ActivityPaginatedFragment on Query {\n  results: activities(first: $first, after: $cursor, storeCollectionSlug: $storeCollectionSlug, sortOrder: $sortOrder, sortType: $sortType, currencyMode: $currencyMode, actions: $actions, price: $price, activityDate: $activityDate, isShowFollowing: $isShowFollowing, userNames: $userNames, collectors: $collectors, curators: $curators, showFollowingCollectors: $showFollowingCollectors, isSettings: $isSettings) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        transactionUrl\n        action\n        createdAt\n        priceUsd\n        priceEth\n        isEthTransaction\n        fromUser {\n          id\n          pk\n          username\n          fullName\n        }\n        toUser {\n          id\n          pk\n          username\n          fullName\n        }\n        digitalMediaRelease {\n          id\n          pk\n          printEdition\n          metadata {\n            id\n            pk\n            thumbnailImage\n            title\n            totalSupply\n            author {\n              pk\n              id\n              fullName\n            }\n          }\n          listing {\n            id\n            pk\n            productSlug\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9890cfa27eb38d91800b64c16ab410d0";

export default node;
