import { startTransition, useCallback } from 'react';
import { useMutation } from 'react-relay';

import { MPActionButton, MPTooltip } from '@mp-frontend/core-components';

import UpdateVisibilityRequest, {
  type NFTUpdateVisibilityMutation,
} from 'graphql/__generated__/NFTUpdateVisibilityMutation.graphql';

import { NFTType } from 'types/graphql/NFT';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

interface ToggleVisibilityProps {
  invalidate: () => void;
  nft: NFTType;
}

export default function ToggleVisibility({
  nft,
  invalidate,
}: ToggleVisibilityProps) {
  const [commitVisibility, isCommittingVisibility] = useMutation(
    UpdateVisibilityRequest
  );

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const commitVisibilityAsync = useCallback(
    promisifyMutationWithRequestData<
      NFTUpdateVisibilityMutation['variables'],
      NFTUpdateVisibilityMutation['response']
    >(commitVisibility),
    [commitVisibility]
  );

  const toggleVisibility = useCallback(async () => {
    const response = await commitVisibilityAsync({
      isAllowIndex: !nft.isLive,
      nftId: parseInt(nft.pk, 10),
    });
    if (response.updateNftVisibility?.success) startTransition(invalidate);
  }, [commitVisibilityAsync, invalidate, nft.isLive, nft.pk]);

  return (
    <MPTooltip
      title={
        nft.isLive
          ? 'Hide this artwork from your public profile'
          : 'Display this artwork on your public profile'
      }
    >
      <div>
        <MPActionButton
          variant="tertiary"
          onClick={toggleVisibility}
          disabled={isCommittingVisibility}
        >
          {nft.isLive ? 'Hide' : 'Show'}
        </MPActionButton>
      </div>
    </MPTooltip>
  );
}
