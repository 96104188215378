import { Suspense, useCallback, useMemo, useState } from 'react';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import ProductPendingOnChain from 'pages/product/ProductPendingOnChain';

export default function PendingTransferAndSuccess({
  nft,
  currentTransactionHash,
  toAddress,
  closeAndInvalidate,
}) {
  const queryVariables = useMemo(
    () => ({ txtId: currentTransactionHash }),
    [currentTransactionHash]
  );
  const [finishedTransaction, setFinishedListing] = useState(false);
  const onSuccess = useCallback(() => setFinishedListing(true), []);
  return (
    <StackStateDialog title="Transferring" shouldBreak>
      <Suspense>
        <ProductPendingOnChain
          queryVariables={queryVariables}
          onSuccess={onSuccess}
          nft={nft}
        />
      </Suspense>
      {!!finishedTransaction && (
        <StackStateDialog
          title="Transferred"
          shouldBreak
          onClose={closeAndInvalidate}
          actionButton={
            <MPActionButton fullWidth onClick={closeAndInvalidate}>
              Continue
            </MPActionButton>
          }
        >
          <div className={MPFonts.paragraphSmall}>
            You successfully transferred &#34;
            <span className={MPFonts.textSmallMedium}>
              {nft.metadata.title}
            </span>
            &#34; to{' '}
            <span className={MPFonts.textSmallMedium}>{toAddress}</span>
          </div>
        </StackStateDialog>
      )}
    </StackStateDialog>
  );
}
