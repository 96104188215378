:root {
  --navBar-height: 80px;
}

@media (--mq-is-mobile) {
  :root {
    --navBar-height: 64px;
  }
}

.navBar {
  display: flex;
  height: var(--navBar-height);
  background-color: var(--color-backgroundDefault);
  box-sizing: border-box;
  padding: 0 32px;
}

.navBarCol {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 30px;
}

.navBarCol:nth-child(2) {
  justify-content: center;
}

.navBarCol:nth-child(3) {
  justify-content: end;
}

.navIconSection {
  display: flex;
  gap: 14px;
}

.navIcon {
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: var(--color-commonWhite);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-SolidNeutralGray5);
}

.navIcon.dark {
  background-color: var(--color-primaryDark);
}


.navIcon.dark:not(:hover):not(:active) {
  color: var(--color-commonWhite);
}

.navIcon.profileIconWrapper {
  box-sizing: border-box;
  background-color: var(--color-SolidNeutralGray1);
  border: 2px solid var(--color-commonWhite);
}

.navIcon.profileIconWrapper > div > img {
  border: none;
}

.navIcon.profileIconWrapper:hover > div > img {
  opacity: 0.5;
  transition: opacity 300ms ease-in-out;
}

.navIcon.profileIconWrapper:active > div > img {
  opacity: 0.2;
  transition: opacity 1200 ease-in-out;
}

span.logo {
  letter-spacing: -0.66px;
}

@media (--mq-is-mobile) {
  .navBar {
    padding: 0 24px;
  }

  .navBarCol {
    gap: 16px;
  }
}
