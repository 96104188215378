import { joinClasses } from '@mp-frontend/core-utils';

import InViewVideo from 'components/cards/InViewVideo';
import Embed from 'pages/product/productPreview/Embed';
import { NFTType } from 'types/graphql/NFT';
import { isNFTDynamic } from 'utils/nftUtils';

import * as styles from 'css/components/artwork/NFTAssetPreview.module.css';
import * as artworkStyles from 'css/components/cards/ArtworkCard.module.css';

interface NFTAssetPreviewProps {
  nft: NFTType;
  autoPlay?: boolean;
}

export default function NFTAssetPreview({
  nft,
  autoPlay = true,
}: NFTAssetPreviewProps) {
  return (
    <div className={styles.containerLvl1}>
      <div className={styles.containerLvl2}>
        <div className={styles.containerLvl3}>
          {nft.metadata.hasVideo ? (
            <InViewVideo
              className={joinClasses(
                artworkStyles.artworkAsset,
                artworkStyles.artworkAssetMedia
              )}
              poster={nft.metadata.artworkImageUrl}
              src={nft.metadata.artworkVideoUrl}
              autoPlay={autoPlay}
            />
          ) : isNFTDynamic(nft.metadata.rawfileExtension) ? (
            <Embed
              className={joinClasses(
                artworkStyles.artworkAsset,
                artworkStyles.artworkAssetEmbedded
              )}
              media={{
                hasVideo: nft.metadata.hasVideo,
                highResUrl: nft.metadata.highResImage,
                id: nft.metadata.id,
                lowResUrl: nft.metadata.artworkImageUrl,
                mediumResUrl: nft.metadata.artworkImageUrl,
                rawfileExtension: nft.metadata.rawfileExtension,
                videoUrl: nft.metadata.artworkVideoUrl,
              }}
            />
          ) : (
            <img
              src={nft.metadata.artworkImageUrl}
              alt={nft.metadata.title}
              loading="lazy"
              className={joinClasses(
                artworkStyles.artworkAsset,
                artworkStyles.artworkAssetMedia
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}
