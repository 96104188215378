import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generatePriceString from 'utils/currency/generatePricing';
import toNumericShorthand from 'utils/numericShorthand';

import * as styles from 'css/components/payment/TotalRow.module.css';

export default function TotalRow({ usdAmount, ethAmount, currencyDisplay }) {
  const dollarDisplayAmt =
    currencyDisplay === CurrencyDisplayMode.ETH
      ? toNumericShorthand(usdAmount)
      : parseFloat(usdAmount).toFixed(2);
  const ethDisplayAmt = generatePriceString(ethAmount, CurrencyDisplayMode.ETH);

  return (
    <div className={styles.container}>
      <div className={MPFonts.textNormalMedium}>Total</div>
      <div className={styles.price}>
        <span className={MPFonts.price}>
          {currencyDisplay === CurrencyDisplayMode.USD
            ? `$${dollarDisplayAmt}`
            : ethDisplayAmt}
        </span>
        &nbsp;
        <span
          className={joinClasses(MPFonts.subPrice, styles.secondaryCurrency)}
        >
          {currencyDisplay === CurrencyDisplayMode.USD
            ? ethDisplayAmt
            : `$${dollarDisplayAmt}`}
        </span>
      </div>
    </div>
  );
}
