import { useMemo } from 'react';
import { useSwitchChain } from 'wagmi';

import { MPActionButton } from '@mp-frontend/core-components';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import SelectWalletConnectDialog from 'components/wallet/SelectWalletConnectDialog';
import useSession from 'hooks/useSession';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import { NFTType } from 'types/graphql/NFT';
import {
  areSameAddress,
  hasUserRegisteredWalletAddress,
} from 'utils/areSameAddress';
import { AccountConnectionStatus } from 'utils/jwt/walletUtils';

function useGetValidationState(
  requiredAddress,
  warnIfAddressNotRegistered = false
) {
  const { status, address, chainId } = useCachedAccount();
  const session = useSession();

  return {
    isDisconnected: status === AccountConnectionStatus.Disconnected,
    isUnregisteredAddress:
      warnIfAddressNotRegistered &&
      !!address &&
      !hasUserRegisteredWalletAddress(address, session.account.wallets),
    isWrongAddress:
      !!requiredAddress && !areSameAddress(address, requiredAddress),
    isWrongNetwork: session.contractNetwork !== chainId,
  };
}

export default function useEnsureWallet(
  nft: NFTType,
  close: () => void
): [boolean, JSX.Element] {
  const validationState = useGetValidationState(nft.currentOwnerAddress);
  const { contractNetwork } = useSession();
  const { switchChainAsync } = useSwitchChain();
  const switchNetworkButtonJSX = useMemo(
    () => (
      <MPActionButton
        fullWidth
        onClick={async () => {
          await switchChainAsync({ chainId: contractNetwork });
        }}
        size="large"
      >
        Switch Network
      </MPActionButton>
    ),
    [switchChainAsync, contractNetwork]
  );

  return [
    validationState.isDisconnected ||
      validationState.isWrongAddress ||
      validationState.isWrongNetwork,
    validationState.isDisconnected || validationState.isWrongAddress ? (
      <SelectWalletConnectDialog
        address={nft.currentOwnerAddress}
        open
        onClose={close}
      />
    ) : validationState.isWrongNetwork ? (
      <StackStateDialog
        title="Switch Network"
        actionButton={switchNetworkButtonJSX}
        onClose={close}
      >
        <>We&#39;ve detected that you are connected to the wrong network.</>
      </StackStateDialog>
    ) : null,
  ];
}
