import { useCallback, useMemo } from 'react';
import { PreloadedQuery } from 'react-relay';

import { MPActionButton } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ActivityPaginatedQueryType, {
  ActivityPaginatedQuery,
} from 'graphql/__generated__/ActivityPaginatedQuery.graphql';
import { ActivityActionEnum } from 'types/__generated__/graphql';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import FiltersSection from 'components/filters/FilterSection';
import useLoadQuery from 'hooks/useLoadQuery';
import useSession from 'hooks/useSession';
import ActivityPageTable from 'pages/activity/ActivityPageTable';
import { useDateFilter } from 'pages/activity/filters/DateFilter';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import useSetDocTitle from '../useSetDocTitle';
import { useTransactionsFilter } from './activity/TransactionsFilter';
import NoResults from './NoResults';

import * as gridStyles from 'css/components/grid/ThreeItemGrid.module.css';
import * as styles from 'css/pages/settings/collection/Activity.module.css';

interface ActivityInnerProps {
  hasFilters: boolean;
  queryRef: PreloadedQuery<ActivityPaginatedQuery, Record<string, unknown>>;
}

function ActivityInner({ queryRef, hasFilters }: ActivityInnerProps) {
  return (
    <div style={{ minHeight: '770px' }}>
      {queryRef ? (
        <DefaultErrorBoundary>
          <ActivityPageTable
            activityQuery={queryRef}
            noResultsReplaceJSX={
              <NoResults
                isFiltered={hasFilters}
                unFilteredMessage="You have not created or collected any artworks yet."
              />
            }
          />
        </DefaultErrorBoundary>
      ) : null}
    </div>
  );
}

function remapFilterActionToActivityAction(
  transactions: Array<ActivityActionEnum>
) {
  const actions = transactions.slice();
  if (actions.includes(ActivityActionEnum.PlaceBid))
    actions.push(ActivityActionEnum.PlaceOffer);
  if (actions.includes(ActivityActionEnum.ReceiveBid))
    actions.push(ActivityActionEnum.ReceiveOffer);
  if (actions.includes(ActivityActionEnum.RejectBid))
    actions.push(ActivityActionEnum.RejectOffer);
  return actions;
}

export default function Activity() {
  useSetDocTitle('My Activity');
  const session = useSession();
  const [from, to, dateFilterChipsJSX, DateFilterJSX, resetDateFilter] =
    useDateFilter();
  const [
    transactions,
    transactionsFilterChipsJSX,
    TransactionsFilterJSX,
    resetTransactions,
  ] = useTransactionsFilter();
  const reset = useCallback(() => {
    resetDateFilter();
    resetTransactions();
  }, [resetTransactions, resetDateFilter]);

  const activityDate = useMemo(
    () => ({
      max: to || undefined,
      min: from || undefined,
    }),
    [from, to]
  );

  const actions = useMemo(
    () => remapFilterActionToActivityAction(transactions),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [transactions.join('__')]
  );

  const [queryRef] = useLoadQuery<ActivityPaginatedQuery>(
    ActivityPaginatedQueryType,
    {
      actions,
      activityDate,
      first: 6,
      isSettings: true,
      storeCollectionSlug: session.account.username,
    },
    'store-and-network'
  );

  return (
    <div
      className={joinClasses(
        styles.root,
        gridStyles.container,
        CSSGlobal.Flex.Col,
        CSSGap[16]
      )}
    >
      <FilterScrollContainer>
        <FiltersSection inline inlineMobile>
          <FiltersGroup title="Filters">
            <FiltersGroup>
              <>
                {TransactionsFilterJSX}
                {DateFilterJSX}
              </>
            </FiltersGroup>
          </FiltersGroup>
        </FiltersSection>
      </FilterScrollContainer>
      {(!!transactions.length || !!from) && (
        <FilterScrollContainer>
          <FiltersSection inline inlineMobile>
            <FiltersGroup>
              {transactionsFilterChipsJSX}
              {dateFilterChipsJSX}
              <MPActionButton
                className={CSSGlobal.Width.MaxContent}
                variant="tertiary"
                onClick={reset}
              >
                Clear All
              </MPActionButton>
            </FiltersGroup>
          </FiltersSection>
        </FilterScrollContainer>
      )}
      <ActivityInner
        queryRef={queryRef}
        hasFilters={!!(transactions.length || from || to)}
      />
    </div>
  );
}
