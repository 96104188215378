import { MPColorClass, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTAssetPreview from 'components/artwork/NftAssetPreview';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';
import { generateShortPriceString } from 'utils/currency/generatePricing';
import generateEditionString from 'utils/generateEditionText';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import generateTitleText from 'utils/generateTitleText';

import * as styles from 'css/pages/product/owner/Manage/Card.module.css';

interface NFTCardProps {
  nft: NFTType;
}

export default function NFTCard({ nft }: NFTCardProps) {
  const {
    listing: { hasReservePrice, liveSale },
  } = nft;
  const price =
    hasReservePrice || liveSale
      ? liveSale
        ? nft.isCustodialOwner
          ? nft.listing.lowestAskInUsd
          : nft.listing.lowestAskInEth
        : nft.listing?.reserveCurrency === CurrencyDisplayMode.USD
        ? nft.listing.reservePriceInUsd
        : nft.listing.reservePriceInEth
      : false;

  // const price = nft.isCustodialOwner ?
  return (
    <div
      className={joinClasses(
        styles.nftCard,
        CSSGlobal.Flex.Row,
        MPFonts.textSmallMedium
      )}
    >
      <div className={styles.assetWrapper}>
        <NFTAssetPreview nft={nft} autoPlay={false} />
      </div>
      <div className={styles.info}>
        <div className={CSSGlobal.Ellipsis}>{generateTitleText(nft)}</div>
        <div className={CSSGlobal.Ellipsis}>
          <span className={MPColorClass.SolidNeutralGray5}>by&nbsp;</span>
          <span>
            {generateFormattedUserFullName(nft.metadata.author.fullName)}
          </span>
        </div>
        <div>
          <span className={MPColorClass.SolidNeutralGray5}>Edition&nbsp;</span>
          <span>
            {generateEditionString(nft.printEdition, nft.metadata.totalSupply)}
          </span>
        </div>
        {!!price &&
          generateShortPriceString(
            price,
            hasReservePrice
              ? nft.listing?.reserveCurrency === CurrencyDisplayMode.USD
                ? CurrencyDisplayMode.USD
                : CurrencyDisplayMode.ETH
              : nft.isCustodialOwner
              ? CurrencyDisplayMode.USD
              : CurrencyDisplayMode.ETH
          )}
      </div>
    </div>
  );
}
