/**
 * @generated SignedSource<<cbd58cee63455827b3725332df2c7a3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NFTsManagementQuery$variables = {
  productSlug: string;
  password?: string | null;
};
export type NFTsManagementQuery$data = {
  readonly nfts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly pendingSend: {
          readonly id: string;
          readonly recipientAddress: string | null;
          readonly ethTx: {
            readonly txid: string | null;
          } | null;
          readonly senderAddress: string;
          readonly gasRequest: {
            readonly id: string;
            readonly pk: string;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};
export type NFTsManagementQuery = {
  variables: NFTsManagementQuery$variables;
  response: NFTsManagementQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productSlug"
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  },
  {
    "kind": "Variable",
    "name": "productSlug",
    "variableName": "productSlug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipientAddress",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "txid",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "senderAddress",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "GasRequestAbstractType",
  "kind": "LinkedField",
  "name": "gasRequest",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTsManagementQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "nfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTSendType",
                    "kind": "LinkedField",
                    "name": "pendingSend",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionAbstractType",
                        "kind": "LinkedField",
                        "name": "ethTx",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NFTsManagementQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "nfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTSendType",
                    "kind": "LinkedField",
                    "name": "pendingSend",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionAbstractType",
                        "kind": "LinkedField",
                        "name": "ethTx",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21220acf1f48321581549a9786b10adf",
    "id": null,
    "metadata": {},
    "name": "NFTsManagementQuery",
    "operationKind": "query",
    "text": "query NFTsManagementQuery(\n  $productSlug: String!\n  $password: String\n) {\n  nfts(productSlug: $productSlug, first: 1, password: $password) {\n    edges {\n      node {\n        id\n        pk\n        pendingSend {\n          id\n          recipientAddress\n          ethTx {\n            txid\n            id\n          }\n          senderAddress\n          gasRequest {\n            id\n            pk\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c69bbcd10272b54ac98ec61df60743a9";

export default node;
