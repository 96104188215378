import { joinClasses } from '@mp-frontend/core-utils';

import SettingsSavedNFTsPaginatedFragmentRequest, {
  SettingsSavedNFTsPaginatedFragment$key,
} from 'graphql/__generated__/SettingsSavedNFTsPaginatedFragment.graphql';
import SettingsSavedNFTsPaginatedRequest, {
  SettingsSavedNFTsPaginatedQuery,
} from 'graphql/__generated__/SettingsSavedNFTsPaginatedQuery.graphql';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import useLoadQuery from 'hooks/useLoadQuery';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import useSetDocTitle from '../useSetDocTitle';
import SettingsGrid from './Grid';

import * as gridStyles from 'css/components/grid/ThreeItemGrid.module.css';
import * as pageStyles from 'css/pages/settings/collection/index.module.css';

function SavedArtworksInner({ queryRef }) {
  return (
    <div
      className={joinClasses(
        pageStyles.threeItemGridMinHeight,
        gridStyles.gridMinHeight
      )}
    >
      {queryRef ? (
        <DefaultErrorBoundary>
          <SettingsGrid<
            SettingsSavedNFTsPaginatedFragment$key,
            SettingsSavedNFTsPaginatedQuery
          >
            queryRef={queryRef}
            fragmentRequest={SettingsSavedNFTsPaginatedFragmentRequest}
            paginatedRequest={SettingsSavedNFTsPaginatedRequest}
            unFilteredMessage="You have not saved any artworks yet."
          />
        </DefaultErrorBoundary>
      ) : null}
    </div>
  );
}

export default function SavedArtworks() {
  useSetDocTitle('Saved Artworks');
  const [queryRef] = useLoadQuery<SettingsSavedNFTsPaginatedQuery>(
    SettingsSavedNFTsPaginatedRequest,
    {
      first: 6,
    },
    'store-and-network'
  );

  return (
    <div
      className={joinClasses(
        gridStyles.container,
        CSSGlobal.Flex.Col,
        CSSGap[16]
      )}
    >
      <div className={gridStyles.gridMinHeight}>
        <SavedArtworksInner queryRef={queryRef} />
      </div>
    </div>
  );
}
