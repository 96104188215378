/**
 * @generated SignedSource<<65ed3ee1023c3cc7890f8696caa17857>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TwoFactorConfirmationStatusEnum = "SMS_CHANNEL" | "EMAIL_CHANNEL" | "CONFIRMED";
export type AccountStartConfirmingTwoFactorMutation$variables = {};
export type AccountStartConfirmingTwoFactorMutation$data = {
  readonly startConfirmingTwoFactor: {
    readonly status: TwoFactorConfirmationStatusEnum | null;
    readonly success: boolean | null;
  } | null;
};
export type AccountStartConfirmingTwoFactorMutation = {
  variables: AccountStartConfirmingTwoFactorMutation$variables;
  response: AccountStartConfirmingTwoFactorMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StartConfirmingTwoFactorMutation",
    "kind": "LinkedField",
    "name": "startConfirmingTwoFactor",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountStartConfirmingTwoFactorMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountStartConfirmingTwoFactorMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "98c33d8c9aef0cde79d1a2ba14b831d8",
    "id": null,
    "metadata": {},
    "name": "AccountStartConfirmingTwoFactorMutation",
    "operationKind": "mutation",
    "text": "mutation AccountStartConfirmingTwoFactorMutation {\n  startConfirmingTwoFactor {\n    status\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "eba2b510532cbb92cc17b233cbc18b76";

export default node;
