/**
 * @generated SignedSource<<f18ccad0795d0cb53a536cba3fa6bd98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GasRequestByIdQuery$variables = {
  id: string;
};
export type GasRequestByIdQuery$data = {
  readonly gasRequests: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly ethToUsd: any | null;
        readonly amountInWei: any;
        readonly ethTx: {
          readonly txid: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};
export type GasRequestByIdQuery = {
  variables: GasRequestByIdQuery$variables;
  response: GasRequestByIdQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "gasRequestId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ethToUsd",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountInWei",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "txid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GasRequestByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GasRequestAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "gasRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GasRequestAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GasRequestAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionAbstractType",
                    "kind": "LinkedField",
                    "name": "ethTx",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GasRequestByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GasRequestAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "gasRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GasRequestAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GasRequestAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionAbstractType",
                    "kind": "LinkedField",
                    "name": "ethTx",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4313216e274df05c8305f4b221a39291",
    "id": null,
    "metadata": {},
    "name": "GasRequestByIdQuery",
    "operationKind": "query",
    "text": "query GasRequestByIdQuery(\n  $id: String!\n) {\n  gasRequests(gasRequestId: $id) {\n    edges {\n      node {\n        id\n        ethToUsd\n        amountInWei\n        ethTx {\n          txid\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b35de2412008c6dc373c0894c1ccd811";

export default node;
