import { FunctionComponent } from 'react';

import {
  MPIconButton,
  MPIconButtonProps,
  MPIconButtonThemes,
} from '@mp-frontend/core-components';
import { MPSVGIconProps } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/pages/drops/components/VideoPlayer/ActionIcon.module.css';

export enum DropVideoPlayerActionIconSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

const ICON_MAP: {
  [key: string]: { fontSize: 'medium' } | { style: { fontSize: string } };
} = {
  [DropVideoPlayerActionIconSize.LARGE]: { fontSize: 'medium' } as const,
  [DropVideoPlayerActionIconSize.MEDIUM]: { fontSize: 'medium' } as const,
  [DropVideoPlayerActionIconSize.SMALL]: {
    style: { fontSize: `8px` } as const,
  } as const,
};

interface DropVideoPlayerActionIconProps
  extends Omit<MPIconButtonProps, 'children'> {
  Icon: FunctionComponent<MPSVGIconProps>;
  size?: DropVideoPlayerActionIconSize;
}

export default function DropVideoPlayerActionIcon({
  Icon,
  theme = MPIconButtonThemes.NAV,
  size = DropVideoPlayerActionIconSize.MEDIUM,
  ...props
}: DropVideoPlayerActionIconProps) {
  return (
    <MPIconButton
      className={joinClasses(styles.actionButton, styles[size])}
      {...props}
      theme={theme}
    >
      <Icon {...ICON_MAP[size]} />
    </MPIconButton>
  );
}
