import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import pluralize from 'pluralize';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
  MPStandardDialog,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { MPStandardDialogProps } from '@mp-frontend/core-components/src/dialog/MPStandardDialog';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountFinalizeConfirmingTwoFactorMutationType, {
  AccountFinalizeConfirmingTwoFactorMutation,
} from 'graphql/__generated__/AccountFinalizeConfirmingTwoFactorMutation.graphql';
import AccountStartConfirmingTwoFactorMutationType, {
  AccountStartConfirmingTwoFactorMutation,
  AccountStartConfirmingTwoFactorMutation$data,
} from 'graphql/__generated__/AccountStartConfirmingTwoFactorMutation.graphql';
import SettingsSendNftsFromTransferPortalMutationType, {
  SettingsSendNftsFromTransferPortalMutation,
} from 'graphql/__generated__/SettingsSendNftsFromTransferPortalMutation.graphql';
import { TwoFactorConfirmationStatusEnum } from 'types/__generated__/graphql';

import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { HexString } from 'utils/jwt/walletUtils';
import promisifyMutation, {
  usePromisifyMutationWithRequestData,
} from 'utils/promisifyMutation';

import { TransferSectionType } from './TransferSection';

enum DialogType {
  EmailTwoFactorConfirmation = 'EmailTwoFactorConfirmation',
  None = 'None',
  SmsTwoFactorConfirmation = 'SmsTwoFactorConfirmation',
  Transfer = 'Transfer',
}

export interface TransferDialogProps {
  nftIds: number[];
  onClose: MPStandardDialogProps['onClose'];
  onSuccess: () => void;
  transferType: TransferSectionType;
}

function TransferDialog({
  nftIds,
  transferType,
  onClose,
  onSuccess,
}: TransferDialogProps) {
  const session = useSession();

  const [dialogType, setDialogType] = useState<DialogType>(DialogType.None);

  const [twoFactorCodeValue, setTwoFactorCodeValue] = useState('');
  const [twoFactorCodeError, setTwoFactorCodeError] = useState('');
  const [twoFactorLoading, setTwoFactorLoading] = useState(false);

  const [recipientWallet, setRecipientWallet] = useState<string>('');
  const [recipientWalletError, setRecipientWalletError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toAddressRef = useRef<HexString>();

  const [startConfirmingTwoFactorMutation] =
    useMutation<AccountStartConfirmingTwoFactorMutation>(
      AccountStartConfirmingTwoFactorMutationType
    );

  const [commitFinalizeConfirmingTwoFactorAsync] =
    usePromisifyMutationWithRequestData<AccountFinalizeConfirmingTwoFactorMutation>(
      AccountFinalizeConfirmingTwoFactorMutationType
    );

  const [commitSendNftsFromTransferPortalAsync] =
    usePromisifyMutationWithRequestData<SettingsSendNftsFromTransferPortalMutation>(
      SettingsSendNftsFromTransferPortalMutationType
    );

  useEffect(() => {
    if (
      !session.account.twoFactorEmailEnabled &&
      !session.account.twoFactorSmsEnabled
    ) {
      setDialogType(DialogType.Transfer);
      return;
    }

    promisifyMutation(startConfirmingTwoFactorMutation)({})
      .then((data: AccountStartConfirmingTwoFactorMutation$data) => {
        const { status, success } = data.startConfirmingTwoFactor;
        if (!success) return;

        switch (status) {
          case TwoFactorConfirmationStatusEnum.Confirmed:
            setDialogType(DialogType.Transfer);
            break;
          case TwoFactorConfirmationStatusEnum.EmailChannel:
            setDialogType(DialogType.EmailTwoFactorConfirmation);
            break;
          case TwoFactorConfirmationStatusEnum.SmsChannel:
            setDialogType(DialogType.SmsTwoFactorConfirmation);
            break;
          default:
            break;
        }
      })
      .catch(() => onClose({}, 'closeIconClick'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTwoFactorSubmit = useCallback(async () => {
    try {
      setTwoFactorLoading(true);

      const {
        finalizeConfirmingTwoFactor: { success },
      } = await commitFinalizeConfirmingTwoFactorAsync({
        code: twoFactorCodeValue,
      });

      if (!success) throw new Error('Invalid 2FA code');

      setDialogType(DialogType.Transfer);
    } catch (error) {
      setTwoFactorCodeError(error?.message || 'An error occurred');
    } finally {
      setTwoFactorLoading(false);
    }
  }, [commitFinalizeConfirmingTwoFactorAsync, twoFactorCodeValue]);

  const handleWalletChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim();
      let error = '';
      if (!value) {
        error = 'Wallet address is required';
      } else if (!ethers.utils.isAddress(value)) {
        error = 'Invalid wallet address';
      }

      setRecipientWalletError(error);
      setRecipientWallet(value);

      if (!error) {
        toAddressRef.current = value as HexString;
      }
    },
    []
  );

  const handleTransferSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      await commitSendNftsFromTransferPortalAsync({
        nftIds,
        recipientAddress: toAddressRef.current,
      });

      onSuccess();
    } catch (_ignoredError) {
      // ignore
    } finally {
      setIsLoading(false);
    }
  }, [commitSendNftsFromTransferPortalAsync, nftIds, onSuccess]);

  return dialogType === DialogType.Transfer ? (
    <MPStandardDialog
      title={`Transfer ${
        transferType === TransferSectionType.SafeboxTokens
          ? 'Safebox'
          : 'Custodial Wallet'
      } ${pluralize('Token', nftIds.length)}`}
      actionButton={
        <MPActionButton
          disabled={!!(!recipientWallet || recipientWalletError || isLoading)}
          size="large"
          onClick={handleTransferSubmit}
        >
          Transfer
        </MPActionButton>
      }
      onClose={onClose}
    >
      <div
        className={joinClasses(
          CSSGlobal.Flex.Col,
          CSSGlobal.Cursor.Default,
          CSSGap[24]
        )}
      >
        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
          <div className={MPFonts.paragraphSmall}>
            You&#39;re about to transfer{' '}
            {nftIds.length > 1 ? `${nftIds.length} tokens` : 'a token'} to your
            personal wallet address. Please enter the wallet address carefully.
          </div>

          <MPStyledTextField
            label="Personal wallet address"
            placeholder="Wallet address"
            value={recipientWallet}
            error={recipientWalletError}
            onChange={handleWalletChange}
          />
        </div>
      </div>
    </MPStandardDialog>
  ) : dialogType === DialogType.EmailTwoFactorConfirmation ||
    dialogType === DialogType.SmsTwoFactorConfirmation ? (
    <MPStandardDialog title="2FA Confirmation" onClose={onClose}>
      <div
        className={joinClasses(
          MPColorClass.CommonBlack,
          CSSGlobal.Cursor.Default,
          CSSGlobal.Flex.Col,
          CSSGap[24]
        )}
      >
        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
          <div className={MPFonts.paragraphSmall}>
            Enter the authentication code that was just sent to{' '}
            <span className={MPFonts.textSmallMedium}>
              {dialogType === DialogType.EmailTwoFactorConfirmation
                ? session.account.email
                : session.account.phoneNumber}
            </span>
            .
          </div>

          <MPStyledTextField
            autoFocus
            error={twoFactorCodeError}
            fullWidth
            label="Authentication Code"
            placeholder="Enter 2FA code"
            type="text"
            value={twoFactorCodeValue}
            setValue={setTwoFactorCodeValue}
          />
        </div>

        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
          <MPActionButton
            disabled={
              !twoFactorCodeValue || !!twoFactorCodeError || twoFactorLoading
            }
            fullWidth
            size="large"
            variant="primary"
            onClick={handleTwoFactorSubmit}
          >
            Continue
          </MPActionButton>
        </div>
      </div>
    </MPStandardDialog>
  ) : null;
}

export default TransferDialog;
