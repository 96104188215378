import ExploreSeriesPaginatedFragmentType, {
  ExploreSeriesPaginatedFragment$data,
} from 'graphql/__generated__/ExploreSeriesPaginatedFragment.graphql';
import PaginatedExploreSeriesQueryType, {
  PaginatedExploreSeriesQuery,
} from 'graphql/__generated__/PaginatedExploreSeriesQuery.graphql';

import SeriesCard from 'components/cards/SeriesCard';

import Cards from '../Cards';
import Layout, { BaseLayoutProps } from '../Layout';
import { ExploreType } from '../types';
import Chips from './Chips';
import Filters, {
  SeriesFiltersState,
  useSeriesFiltersQueryValues,
} from './Filters';

interface ExploreSeriesProps extends BaseLayoutProps {
  filters: SeriesFiltersState;
  onFiltersChange: (value: Partial<SeriesFiltersState>) => void;
  onFiltersReset: () => void;
}

function ExploreSeries({
  filters,
  onFiltersChange,
  onFiltersReset,
  ...props
}: ExploreSeriesProps) {
  const queryValues = useSeriesFiltersQueryValues(filters);

  return (
    <Layout
      {...props}
      hero={null}
      cards={
        <Cards<
          PaginatedExploreSeriesQuery,
          ExploreSeriesPaginatedFragment$data['series']['results']['edges'][number]['node']
        >
          exploreType={ExploreType.Series}
          filters={
            <>
              <Filters state={filters} onChange={onFiltersChange} />
              <Chips
                filters={filters}
                onChange={onFiltersChange}
                onReset={onFiltersReset}
              />
            </>
          }
          variables={queryValues}
          fragmentConcreteRequest={ExploreSeriesPaginatedFragmentType}
          queryConcreteRequest={PaginatedExploreSeriesQueryType}
          renderElement={(series) => (
            <SeriesCard key={series.id} series={series} />
          )}
        />
      }
    />
  );
}

export default ExploreSeries;
