.container {
  margin: 0 0 15px;
  padding: 15px 0;
}

.action {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;

  &.likes {
    gap: 4px;
    margin-right: 24px;
  }

  &.comments {
    gap: 6px;
    margin-right: 20px;
  }

  &.views {
    cursor: default;
    margin-right: 17px;
  }
}

.commentDialog {
  display: grid;
  grid-template-areas:
    "nft"
    "content"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

.commentDialog > .commentNft {
  align-items: center;
  background-color: var(--color-backgroundDefault);
  display: flex;
  flex-direction: row;
  gap: 12px;
  grid-area: nft;
  overflow: hidden;
  padding: 16px 34px;

  @media (--mq-is-mobile) {
    display: none;
  }

  & > .commentNftImage {
    background-color: var(--color-primaryLight);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px;
    height: 48px;
    width: 48px;
  }

  & > .commentNftTitle {
    color: var(--color-SolidNeutralOffBlack);
    cursor: default;
  }
}

.commentDialog > .commentContent {
  grid-area: content;
  overflow-y: auto;

  &.empty {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  & > .commentNoContent {
    color: var(--color-SolidNeutralGray5);
    cursor: default;
    padding: 0 34px;
    text-align: center;

    @media (--mq-is-mobile) {
      padding: 0 24px;
    }
  }

  & .commentItem {
    padding: 16px 34px;

    @media (--mq-is-mobile) {
      padding-left: 24px;
      padding-right: 24px;
    }

    & > .commentItemHeader {
      align-items: center;
      color: var(--color-commonBlack);
      display: flex;
      flex-direction: row;
      gap: 6px;
      text-decoration: none;

      & > .commentItemProfileImage {
        background-color: var(--color-primaryLight);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        height: 24px;
        width: 24px;
      }

      & > .commentItemUsername {
        cursor: pointer;
        flex-basis: 0;
        flex-grow: 1;
      }

      & > .commentItemDate {
        color: var(--color-SolidNeutralGray5);
        cursor: default;
      }
    }

    & > .commentItemContent {
      color: var(--color-commonBlack);
      cursor: default;
      margin-top: 8px;
      max-width: 100%;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }

    & > .commentItemFooter {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: flex-end;

      & > .commentItemAction {
        color: var(--color-SolidNeutralGray5);
        background-color: transparent;
        cursor: pointer;
        min-width: unset;
        padding: 0;
        text-decoration: underline;

        &:hover {
          color: var(--color-SolidNeutralGray3);
        }
      }
    }
  }

  & .commentDivider {
    padding: 0 32px;
  }
}

.commentDialog > .commentFooter {
  border-top: 1px solid var(--color-SolidNeutralGray1);
  column-gap: 10px;
  display: grid;
  grid-area: footer;
  grid-template-areas:
    "profileImage profileUsername"
    "commentInput commentInput";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  padding: 14px 34px 32px 34px;
  row-gap: 18px;

  @media (--mq-is-mobile) {
    border-top: none;
    column-gap: 8px;
    grid-template-areas:
      "profileImage commentInput";
    grid-template-rows: auto;
    padding: 10px 24px 16px 24px;
  }

  & > .commentProfileImage {
    background-color: var(--color-primaryLight);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    grid-area: profileImage;
    height: 38px;
    width: 38px;
  }

  & > .commentProfileUsername {
    align-self: center;
    cursor: default;
    grid-area: profileUsername;

    @media (--mq-is-mobile) {
      display: none;
    }
  }

  & > .commentInput {
    grid-area: commentInput;

    & .commentSubmitButton {
      align-items: center;
      color: var(--color-goldMain);
      cursor: pointer;
      display: flex;
      justify-content: center;

      &[data-disabled="true"] {
        color: var(--color-SolidNeutralGray1);
        cursor: default;
        touch-action: none;
      }
    }
  }
}

.commentDeletingDialog {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.shareDialog {
  align-items: center;
  color: var(--color-SolidNeutralGray5);
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
}

.commentEndAdornment {
  margin-right: -4px;
}
