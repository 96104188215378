import { Route } from 'react-router-dom';

import Reload from 'components/Reload';
import SlashRedirect from 'pages/SlashRedirect';

import TransferPage from './TransferPage';

const TransferRoute = (
  <Route path="transfer" element={<TransferPage />}>
    <Route index element={<SlashRedirect />} />
    <Route path="*" element={<Reload />} />
  </Route>
);

export default TransferRoute;
