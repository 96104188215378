import { Fragment, useEffect } from 'react';
import { usePreloadedQuery } from 'react-relay';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTsMultiProvenanceQueryType, {
  NFTsMultiProvenanceQuery,
  NFTsMultiProvenanceQuery$data,
} from 'graphql/__generated__/NFTsMultiProvenanceQuery.graphql';
import { ProvenanceObjectType } from 'types/__generated__/graphql';

import UserProfileImage from 'components/accounts/UserProfileImage';
import ROUTES from 'constants/Routes';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { PurchasableNFTType } from 'types/graphql/NFT';
import generateEthAndUsdPricing from 'utils/currency/generateEthAndUsdPricing';
import generateEditionString from 'utils/generateEditionText';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/pages/product/ProductProvenance.module.css';

interface BaseProductMultiEditionProvenanceProps {
  multiProvenanceQuery: WithLoadQueryProps<NFTsMultiProvenanceQuery>;
  nft: PurchasableNFTType;
  onRefresh?: () => void;
}

function BaseProductMultiEditionProvenance({
  multiProvenanceQuery: { queryRef },
  nft,
  onRefresh,
}: BaseProductMultiEditionProvenanceProps) {
  const { nftMultiEditionProvenance } =
    usePreloadedQuery<NFTsMultiProvenanceQuery>(
      NFTsMultiProvenanceQueryType,
      queryRef
    );

  useEffect(() => {
    onRefresh?.();
  }, [onRefresh, nftMultiEditionProvenance]);

  const getProvenanceLineDisplay = (
    data: NFTsMultiProvenanceQuery$data['nftMultiEditionProvenance'][0]
  ) => {
    const editionText = generateEditionString(
      data.edition,
      nft.metadata.totalSupply,
      'long'
    );
    if (
      data.type === ProvenanceObjectType.Sale ||
      data.type === ProvenanceObjectType.Listing
    ) {
      return (
        <>
          <span className={MPFonts.textNormalMedium}>{editionText}</span>
          &nbsp;listed for&nbsp;
          <span className={MPFonts.textNormalMedium}>
            {generateEthAndUsdPricing(data.amountEth, data.amountUsd)}
          </span>
        </>
      );
    }
    if (data.type === ProvenanceObjectType.Purchase) {
      return (
        <>
          <span className={MPFonts.textNormalMedium}>{editionText}</span>
          &nbsp;purchased for&nbsp;
          <span className={MPFonts.textNormalMedium}>
            {generateEthAndUsdPricing(data.amountEth, data.amountUsd)}
          </span>
        </>
      );
    }
    return (
      <>
        <span className={MPFonts.textNormalMedium}>{editionText}</span>
        &nbsp;minted&nbsp;
      </>
    );
  };

  return (
    <div className={styles.productProvenanceMultiContainer}>
      {nftMultiEditionProvenance.map((data) => (
        <Fragment key={`${data.edition}${data.user?.id}${nft.pk}`}>
          <div
            className={joinClasses(
              CSSGlobal.Flex.InlineRowCenterAlign,
              CSSGap[10]
            )}
          >
            <UserProfileImage
              fullName={data.user?.fullName}
              profileImageUrl={data.user?.profileImageUrl}
              usernameToNavigate={data.user?.username}
            />

            <div className={styles.productMultiItemDescription}>
              <a
                className={styles.productMultiItemDescriptionLink}
                href={ROUTES.NFT(data.productSlug)}
              >
                {getProvenanceLineDisplay(data)}
              </a>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}

export const ProductMultiEditionProvenance = withDefaultErrorBoundary(
  withLoadQuery(BaseProductMultiEditionProvenance, {
    multiProvenanceQuery: {
      concreteRequest: NFTsMultiProvenanceQueryType,
    },
  })
);

interface ProductMultiEditionProvenanceExpandableProps {
  nft: PurchasableNFTType;
  onRefresh?: () => void;
}

function ProductMultiEditionProvenanceExpandable({
  nft,
  onRefresh,
}: ProductMultiEditionProvenanceExpandableProps) {
  return (
    <ProductMultiEditionProvenance
      multiProvenanceQuery={{
        variables: { nftMetadataId: parseInt(nft.metadata.pk, 10) },
      }}
      nft={nft}
      onRefresh={onRefresh}
    />
  );
}

export default ProductMultiEditionProvenanceExpandable;
