import { MouseEvent, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { MPAnimations } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ROUTES from 'constants/Routes';
import getProfileImage from 'utils/getProfileImage';

import * as styles from 'css/components/accounts/UserProfileImage.module.css';

export type Size = 'x-small' | 'small' | 'medium' | 'large';
const profileImageClassSizeMap: Record<Size, string> = {
  large: styles.large,
  medium: styles.medium,
  small: styles.small,
  'x-small': styles.xSmall,
};

export function UserProfileLinkOrNot({
  usernameToNavigate,
  className,
  children,
  onClick,
}: {
  children: ReactNode;
  className: string;
  usernameToNavigate: string;
  onClick?: (event: MouseEvent) => void;
}) {
  const classNameWithoutTransitions = useMemo(
    () =>
      Object.values(MPAnimations.Color).reduce(
        (memo, value) => memo.replace(value, ''),
        className
      ),
    [className]
  );

  return usernameToNavigate ? (
    <Link
      className={joinClasses('anchor', className)}
      to={ROUTES.PROFILE(usernameToNavigate)}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : (
    <div className={classNameWithoutTransitions}>{children}</div>
  );
}

interface UserProfileImageProps {
  fullName: string;
  profileImageUrl: string;
  onClick?: (event: MouseEvent) => void;
  size?: Size;
  usernameToNavigate?: string;
}

export default function UserProfileImage({
  usernameToNavigate,
  fullName,
  profileImageUrl,
  size = 'medium',
  onClick,
}: UserProfileImageProps) {
  return (
    <UserProfileLinkOrNot
      usernameToNavigate={usernameToNavigate}
      className={joinClasses(
        styles.profileImage,
        profileImageClassSizeMap[size]
      )}
      onClick={onClick}
    >
      <img src={getProfileImage(profileImageUrl)} alt={fullName} />
    </UserProfileLinkOrNot>
  );
}
