import { useCallback, useState } from 'react';
import { Hash } from 'viem';

import { MPFonts } from '@mp-frontend/core-components';

import NFTSendFromCustodialWalletRequest, {
  NFTSendFromCustodialWalletMutation,
} from 'graphql/__generated__/NFTSendFromCustodialWalletMutation.graphql';

import StackStateConfirmDialog from 'components/dialogs/StackStateConfirmDialog';
import ErrorDisplay from 'components/Error';
import useTokenContract from 'hooks/contracts/useTokenContract';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import CSSGlobal from 'types/enums/css/Global';
import { usePromisifyMutationWithRequestData } from 'utils/promisifyMutation';

import NFTCard from '../../Card';
import PayByCreditCard from './PayByCreditCard';
import PendingTransferAndSuccess from './PendingAndSuccess';

export default function NFTTransferConfirm({
  toAddress,
  nft,
  close,
  closeAndInvalidate,
}) {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [gasId, setGasId] = useState<number>();
  const [isCCGasTxtDialogOpen, openCCGasTxtDialog, closeCCGasTxtDialog] =
    useSimpleDialogController({
      preventDefault: true,
    });

  // Custodial
  const [asyncSendNFTFromCustodialMutation] =
    usePromisifyMutationWithRequestData<NFTSendFromCustodialWalletMutation>(
      NFTSendFromCustodialWalletRequest
    );

  // Wallet
  const { useSafeTransferFrom } = useTokenContract({
    abi: JSON.parse(nft.contract.abidata).abi,
    contractAddress: nft.contract.address as Hash,
  });

  const {
    mutate: { writeAsync },
  } = useSafeTransferFrom({
    fromAddress: nft.currentOwnerAddress as Hash,
    toAddress,
    tokenId: parseInt(nft.onchainId, 10),
  });

  const submit = useCallback(async () => {
    try {
      setIsLoading(true);
      if (nft.isCustodialOwner) {
        if (!gasId) {
          const resp = await asyncSendNFTFromCustodialMutation({
            nftId: nft.pk,
            recipientAddress: toAddress,
          });
          setGasId(resp.sendNftFromCustodialWallet.gasId);
        }
        openCCGasTxtDialog();
      } else {
        setTransactionHash(await writeAsync());
      }
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  }, [
    writeAsync,
    nft.pk,
    nft.isCustodialOwner,
    gasId,
    openCCGasTxtDialog,
    asyncSendNFTFromCustodialMutation,
    toAddress,
  ]);

  return (
    <StackStateConfirmDialog
      open
      content={
        <>
          <div className={MPFonts.textSmallMedium}>
            <ErrorDisplay
              className={CSSGlobal.TextAlign.Centered}
              error={error}
            />
            <div className={MPFonts.paragraphSmall}>
              You are transferring this artwork to{' '}
              <span className={MPFonts.textSmallMedium}>{toAddress}</span>
              <div style={{ height: '145px', padding: '16px' }}>
                <NFTCard nft={nft} />
              </div>
            </div>
            {!!transactionHash && (
              <PendingTransferAndSuccess
                nft={nft}
                currentTransactionHash={transactionHash}
                toAddress={toAddress}
                closeAndInvalidate={closeAndInvalidate}
              />
            )}
            {!!isCCGasTxtDialogOpen && (
              <PayByCreditCard
                gasRequestID={gasId}
                close={closeCCGasTxtDialog}
                address={toAddress}
                closeAndInvalidate={closeAndInvalidate}
                nft={nft}
              />
            )}
          </div>
        </>
      }
      isLoading={isLoading}
      onCancel={close}
      onConfirm={submit}
      title="Transfer"
    />
  );
}
