import { useMemo } from 'react';

import { PaginatedExploreSeriesQuery } from 'graphql/__generated__/PaginatedExploreSeriesQuery.graphql';
import { UserSearchAccountTypesEnum } from 'types/__generated__/graphql';

import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import FiltersSection from 'components/filters/FilterSection';
import {
  SortSeriesFilter,
  SortSeriesFilterValue,
} from 'components/filters/SortFilter';
import UsersFilter from 'components/filters/UsersFilter';
import useFiltersGTM, { FilterType } from 'utils/GTM/filters';

export interface SeriesFiltersState {
  artists: Array<string>;
  query: string;
  showFollowingArtists: boolean;
  sort: SortSeriesFilterValue;
}

export const SERIES_FILTERS_DEFAULT_STATE: SeriesFiltersState = {
  artists: [],
  query: '',
  showFollowingArtists: false,
  sort: {},
};

export const useSeriesFiltersQueryValues = (
  filters: SeriesFiltersState
): PaginatedExploreSeriesQuery['variables'] => {
  const artists = useMemo(
    () => (filters.artists?.length ? filters.artists : undefined),
    [filters.artists]
  );
  const showFollowingArtists = useMemo(
    () => (filters.showFollowingArtists ? true : undefined),
    [filters.showFollowingArtists]
  );

  return {
    artists,
    first: 9,
    query: filters.query,
    showFollowingArtists,
    sortOrder: filters.sort?.order,
    sortType: filters.sort?.type,
  };
};

interface SeriesFiltersProps {
  onChange: (state: Partial<SeriesFiltersState>) => void;
  state: SeriesFiltersState;
}

export default function Filters({ state, onChange }: SeriesFiltersProps) {
  const { updateFilter } = useFiltersGTM();

  return (
    <FilterScrollContainer>
      <FiltersSection inline inlineMobile>
        <FiltersGroup>
          <FiltersGroup title="Sorting">
            <SortSeriesFilter
              value={state.sort}
              onChange={(sort) => {
                onChange({ sort });
                updateFilter(FilterType.SortFilter, sort);
              }}
            />
          </FiltersGroup>
        </FiltersGroup>

        <FiltersGroup title="Filters">
          <UsersFilter
            value={{
              isShowFollowing: state.showFollowingArtists,
              userNames: state.artists,
            }}
            onChange={(value) => {
              onChange({
                artists: value.userNames,
                showFollowingArtists: value.isShowFollowing,
              });
              updateFilter(FilterType.ArtistsFilter, value);
            }}
            accountSearchType={UserSearchAccountTypesEnum.Artist}
          />
        </FiltersGroup>
      </FiltersSection>
    </FilterScrollContainer>
  );
}
