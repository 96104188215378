/**
 * @generated SignedSource<<887cd3e4bef4bedfdea41c7fcc13d6ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AuctionTypeEnum = "PRODUCT" | "RANKED";
export type EthereumTransactionStateEnum = "POSTED" | "MINED" | "CANCELLED" | "PENDING" | "FAILED" | "TRYING" | "EXPIRED";
export type NFTSaleStatusEnum = "APPROVED" | "CANCELLED" | "DISABLED" | "DISPUTED_SALE" | "LIVE" | "NEW" | "PENDING_CANCELLED" | "PENDING_LIVE" | "PENDING_SALE" | "SOLD" | "FINALIZING";
export type ProductTypeBidEnum = "ETHER" | "CREDITCARD" | "WIRE_TRANSFER";
export type ReserveCurrencyEnum = "ETH" | "USD";
export type SalePublicTypeEnum = "PUBLIC_SALE" | "PRESALE" | "PUBLIC_LAZY_SALE" | "PRIVATE_SALE" | "PRIVATE_LAZY_SALE";
import { FragmentRefs } from "relay-runtime";
export type SettingsSavedNFTsPaginatedFragment$data = {
  readonly results: {
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currentOwner: {
          readonly fullName: string | null;
          readonly id: string;
        } | null;
        readonly contract: {
          readonly isExternal: boolean | null;
        } | null;
        readonly id: string;
        readonly listing: {
          readonly acceptsFiatOffers: boolean | null;
          readonly acceptsFiatPurchase: boolean | null;
          readonly availableForBid: boolean | null;
          readonly availableForPurchase: boolean | null;
          readonly availableForSale: boolean;
          readonly custodialSaleEnabled: boolean | null;
          readonly disableBidAccepts: boolean;
          readonly fullNftUrl: string | null;
          readonly hasReservePrice: boolean | null;
          readonly id: string;
          readonly isBidAccepted: boolean | null;
          readonly liveSale: {
            readonly contract: {
              readonly abidata: string;
              readonly address: string;
            } | null;
            readonly curators: ReadonlyArray<{
              readonly fullName: string | null;
              readonly id: string;
              readonly jobTitle: string | null;
              readonly pk: string;
              readonly profileImageUrl: string;
              readonly username: string;
            } | null> | null;
            readonly custodialPresalePriceEth: number | null;
            readonly custodialPresalePriceUsd: number | null;
            readonly ethTx: {
              readonly id: string;
              readonly pk: string;
              readonly transactionState: EthereumTransactionStateEnum;
              readonly transactionUrl: string | null;
              readonly txid: string | null;
            } | null;
            readonly id: string;
            readonly listingStatus: NFTSaleStatusEnum;
            readonly pk: string;
            readonly publicType: SalePublicTypeEnum | null;
          } | null;
          readonly liveBid: {
            readonly bidAmount: number | null;
            readonly bidInEther: number | null;
            readonly bidInUsd: number | null;
            readonly bidderAddress: `0x${string}` | null;
            readonly bidderUser: {
              readonly fullName: string | null;
            } | null;
            readonly contract: {
              readonly abidata: string;
              readonly address: string;
              readonly isExternal: boolean | null;
              readonly symbol: string;
              readonly title: string;
            } | null;
            readonly createdAt: any | null;
            readonly isBidConfirmed: boolean | null;
            readonly isEtherBid: boolean | null;
            readonly isGlobalBid: boolean | null;
            readonly isGlobalBidIgnored: boolean | null;
            readonly isCcBid: boolean | null;
            readonly id: string;
            readonly onchainId: string | null;
            readonly pk: string;
          } | null;
          readonly lowestAskInEth: number | null;
          readonly lowestAskInUsd: number | null;
          readonly lastSale: {
            readonly createdAt: any;
            readonly ethPriceAtSale: number;
            readonly pk: string;
            readonly usdPriceAtSale: number;
          } | null;
          readonly pk: string;
          readonly productAuction: {
            readonly auctionAbstractUnionType?: AuctionTypeEnum | null;
            readonly auctionEnded?: boolean | null;
            readonly createdAt?: any;
            readonly endsAt?: any;
            readonly id?: string;
            readonly info?: string | null;
          } | null;
          readonly productSlug: string;
          readonly rankedAuction: {
            readonly auctionAbstractUnionType?: AuctionTypeEnum | null;
            readonly auctionEnded?: boolean | null;
            readonly auctionType?: AuctionTypeEnum | null;
            readonly count?: number;
            readonly createdAt?: any;
            readonly endsAt?: any | null;
            readonly highestBid?: {
              readonly bidInEther: number | null;
              readonly bidInUsd: number | null;
              readonly id: string;
            } | null;
            readonly id?: string;
            readonly lastBid?: {
              readonly bidAmount: number | null;
              readonly bidInEther: number | null;
              readonly bidInUsd: number | null;
              readonly bidType: ProductTypeBidEnum | null;
              readonly isCcBid: boolean | null;
              readonly isEtherBid: boolean | null;
            } | null;
            readonly startsAt?: any | null;
          } | null;
          readonly reserveCurrency: ReserveCurrencyEnum;
          readonly reservePriceInEth: number | null;
          readonly reservePriceInUsd: number | null;
          readonly saleCommission: number | null;
          readonly shouldUseDropSaleContract: boolean | null;
        } | null;
        readonly metadata: {
          readonly artworkImageUrl: string | null;
          readonly artworkVideoUrl: string | null;
          readonly author: {
            readonly fullName: string | null;
            readonly id: string;
            readonly pk: string;
            readonly username: string;
          } | null;
          readonly cardImage: string | null;
          readonly cardVideoUrl: string | null;
          readonly currentAccountLike: boolean | null;
          readonly hasVideo: boolean | null;
          readonly highResImage: string | null;
          readonly id: string;
          readonly mediaMetadata: {
            readonly filesize: number | null;
            readonly height: number | null;
            readonly id: string;
            readonly width: number | null;
          } | null;
          readonly pk: string;
          readonly previewImage: string | null;
          readonly rawfileExtension: string;
          readonly totalSupply: number;
          readonly title: string;
          readonly videoUrl: string | null;
        } | null;
        readonly pk: string;
        readonly printEdition: number;
      } | null;
      readonly cursor: string;
    } | null>;
  } | null;
  readonly " $fragmentType": "SettingsSavedNFTsPaginatedFragment";
};
export type SettingsSavedNFTsPaginatedFragment$key = {
  readonly " $data"?: SettingsSavedNFTsPaginatedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsSavedNFTsPaginatedFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "results"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isExternal",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abidata",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidAmount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidInEther",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidInUsd",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEtherBid",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCcBid",
  "storageKey": null
},
v15 = {
  "alias": "auctionAbstractUnionType",
  "args": null,
  "kind": "ScalarField",
  "name": "auctionType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auctionEnded",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v18 = [
  (v15/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./SettingsSavedNFTsPaginatedQuery.graphql')
    }
  },
  "name": "SettingsSavedNFTsPaginatedFragment",
  "selections": [
    {
      "alias": "results",
      "args": [
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Literal",
          "name": "savedArtworks",
          "value": true
        }
      ],
      "concreteType": "NFTAbstractTypeConnection",
      "kind": "LinkedField",
      "name": "__SettingsSavedNFTsPaginatedQuery__results_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NFTAbstractTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NFTAbstractType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountAbstractType",
                  "kind": "LinkedField",
                  "name": "currentOwner",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NFTEthereumContractType",
                  "kind": "LinkedField",
                  "name": "contract",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NFTListingAbstractType",
                  "kind": "LinkedField",
                  "name": "listing",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "acceptsFiatOffers",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "acceptsFiatPurchase",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "availableForBid",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "availableForPurchase",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "availableForSale",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "custodialSaleEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "disableBidAccepts",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullNftUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasReservePrice",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isBidAccepted",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NFTSaleType",
                      "kind": "LinkedField",
                      "name": "liveSale",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "NFTEthereumContractType",
                          "kind": "LinkedField",
                          "name": "contract",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AccountAbstractType",
                          "kind": "LinkedField",
                          "name": "curators",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "jobTitle",
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "profileImageUrl",
                              "storageKey": null
                            },
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "custodialPresalePriceEth",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "custodialPresalePriceUsd",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TransactionAbstractType",
                          "kind": "LinkedField",
                          "name": "ethTx",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "transactionState",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "transactionUrl",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "txid",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "listingStatus",
                          "storageKey": null
                        },
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "publicType",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NFTBidType",
                      "kind": "LinkedField",
                      "name": "liveBid",
                      "plural": false,
                      "selections": [
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bidderAddress",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AccountAbstractType",
                          "kind": "LinkedField",
                          "name": "bidderUser",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "NFTEthereumContractType",
                          "kind": "LinkedField",
                          "name": "contract",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "symbol",
                              "storageKey": null
                            },
                            (v11/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v12/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isBidConfirmed",
                          "storageKey": null
                        },
                        (v13/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isGlobalBid",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isGlobalBidIgnored",
                          "storageKey": null
                        },
                        (v14/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "onchainId",
                          "storageKey": null
                        },
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lowestAskInEth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lowestAskInUsd",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NFTPurchaseHistoryType",
                      "kind": "LinkedField",
                      "name": "lastSale",
                      "plural": false,
                      "selections": [
                        (v12/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ethPriceAtSale",
                          "storageKey": null
                        },
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "usdPriceAtSale",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    {
                      "alias": "productAuction",
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "auction",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v15/*: any*/),
                            (v16/*: any*/),
                            (v12/*: any*/),
                            (v17/*: any*/),
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "info",
                              "storageKey": null
                            }
                          ],
                          "type": "NFTAuctionType",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": (v18/*: any*/),
                          "type": "NFTRankedAuctionType",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "productSlug",
                      "storageKey": null
                    },
                    {
                      "alias": "rankedAuction",
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "auction",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v15/*: any*/),
                            (v16/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "auctionType",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "count",
                              "storageKey": null
                            },
                            (v12/*: any*/),
                            (v17/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "NFTRankedBidType",
                              "kind": "LinkedField",
                              "name": "highestBid",
                              "plural": false,
                              "selections": [
                                (v9/*: any*/),
                                (v10/*: any*/),
                                (v2/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "NFTRankedBidType",
                              "kind": "LinkedField",
                              "name": "lastBid",
                              "plural": false,
                              "selections": [
                                (v8/*: any*/),
                                (v9/*: any*/),
                                (v10/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "bidType",
                                  "storageKey": null
                                },
                                (v14/*: any*/),
                                (v13/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "startsAt",
                              "storageKey": null
                            }
                          ],
                          "type": "NFTRankedAuctionType",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": (v18/*: any*/),
                          "type": "NFTAuctionType",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reserveCurrency",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reservePriceInEth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reservePriceInUsd",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "saleCommission",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "shouldUseDropSaleContract",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NFTMetadataAbstractType",
                  "kind": "LinkedField",
                  "name": "metadata",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "artworkImageUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "artworkVideoUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AccountAbstractType",
                      "kind": "LinkedField",
                      "name": "author",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cardImage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cardVideoUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currentAccountLike",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasVideo",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "highResImage",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NFTMediaMetadataType",
                      "kind": "LinkedField",
                      "name": "mediaMetadata",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "filesize",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "height",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "width",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "previewImage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rawfileExtension",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalSupply",
                      "storageKey": null
                    },
                    (v11/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "videoUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "printEdition",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "05a42c7cf3d863aa57a8c871d366e4c3";

export default node;
