/**
 * @generated SignedSource<<dd0ddd1571bffb5c7285a26e63a79c0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ModularPagesQuery$variables = {
  slug: string;
  first?: number | null;
  password?: string | null;
};
export type ModularPagesQuery$data = {
  readonly modularPages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly author: {
          readonly biography: string | null;
          readonly followerCount: number;
          readonly fullName: string | null;
          readonly id: string;
          readonly pk: string;
          readonly profileImageUrl: string;
          readonly totalVolume: {
            readonly totalVolumeInEth: number | null;
            readonly totalVolumeInUsd: number | null;
          } | null;
          readonly username: string;
        } | null;
        readonly backgroundImageUrl: string;
        readonly description: string;
        readonly id: string;
        readonly pageUrl: string;
        readonly pk: string;
        readonly slug: string;
        readonly title: string | null;
        readonly type: string | null;
        readonly isPrivate: boolean;
        readonly isShowNewsletterSignUp: boolean;
      } | null;
    } | null>;
  } | null;
};
export type ModularPagesQuery = {
  variables: ModularPagesQuery$variables;
  response: ModularPagesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "ModularPageTypeConnection",
    "kind": "LinkedField",
    "name": "modularPages",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ModularPageTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ModularPageType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountAbstractType",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "biography",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "followerCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TotalVolumeType",
                    "kind": "LinkedField",
                    "name": "totalVolume",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalVolumeInEth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalVolumeInUsd",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundImageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageUrl",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPrivate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isShowNewsletterSignUp",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModularPagesQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModularPagesQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "48bd79db4386d2488907bbcbae456911",
    "id": null,
    "metadata": {},
    "name": "ModularPagesQuery",
    "operationKind": "query",
    "text": "query ModularPagesQuery(\n  $slug: String!\n  $first: Int\n  $password: String\n) {\n  modularPages(slug: $slug, first: $first, password: $password) {\n    edges {\n      node {\n        author {\n          biography\n          followerCount\n          fullName\n          id\n          pk\n          profileImageUrl\n          totalVolume {\n            totalVolumeInEth\n            totalVolumeInUsd\n          }\n          username\n        }\n        backgroundImageUrl\n        description\n        id\n        pageUrl\n        pk\n        slug\n        title\n        type\n        isPrivate\n        isShowNewsletterSignUp\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6e72013092bbdedbe1802554765cf48";

export default node;
