export function daysPassedSinceDate(dateStr: string): number {
  const date = new Date(dateStr);
  const invalidDate = new Date('');

  if (date === invalidDate) return 0;

  const timePassed = new Date().valueOf() - date.valueOf();
  return Math.floor(timePassed / (1000 * 60 * 60 * 24));
}

export default function timePassedSinceDate(dateStr: string) {
  // this type of format "2023-07-22T05:24:51.913064+00:00"
  // returns "2 days ago" or "3 months ago" or "1 year ago"

  const daysPassed = daysPassedSinceDate(dateStr);
  if (daysPassed === 0) return 'recently';
  if (daysPassed > 365) {
    const tAgo = Math.floor(daysPassed / 365);
    return `${tAgo} year${tAgo === 1 ? '' : 's'} ago`;
  }
  if (daysPassed > 29) {
    const tAgo = Math.floor(daysPassed / 30);
    return `${tAgo} month${tAgo === 1 ? '' : 's'} ago`;
  }
  return `${daysPassed.toString()} day${daysPassed === 1 ? '' : 's'} ago`;
}
