import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import pluralize from 'pluralize';

import {
  MPCheckbox,
  MPChip,
  MPDivider,
  MPDropdown,
  MPDropdownProps,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { UserSearchAccountTypesEnum } from 'types/__generated__/graphql';

import useSession from 'hooks/useSession';
import CSSGlobal from 'types/enums/css/Global';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';

import UsersFilterList from './UsersFilterList';
import UsersFilterSearchInput from './UsersFilterSearchInput';

import * as styles from 'css/components/filters/UsersFilter.module.css';

export type UsersFilterValue = Partial<{
  isShowFollowing: boolean;
  userNames: string[];
}>;

export interface UsersFilterProps extends Pick<MPDropdownProps, 'onToggle'> {
  accountSearchType: UserSearchAccountTypesEnum;
  onChange: (value: UsersFilterValue) => void;
  value: UsersFilterValue;
  hideFollowing?: boolean;
}

const useLabels = (accountSearchType: UserSearchAccountTypesEnum) =>
  useMemo(() => {
    const singular = capitalizeFirstLetter(accountSearchType.toLowerCase());
    const plural = pluralize(singular);

    return {
      checkboxLabel: `${plural} You Follow`,
      inputLabel: `${plural} Search`,
      inputPlaceholder: singular,
      title: plural,
    };
  }, [accountSearchType]);

function UsersFilter({
  value,
  onChange,
  onToggle,
  accountSearchType = undefined,
  hideFollowing = false,
}: UsersFilterProps) {
  const session = useSession();
  const [removedUserNames, setRemovedUserNames] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const { checkboxLabel, title, inputLabel, inputPlaceholder } =
    useLabels(accountSearchType);

  const handleIsFollowingChange = useCallback(
    (checked: boolean) => {
      onChange({ ...value, isShowFollowing: checked ? true : undefined });
    },
    [value, onChange]
  );

  const handleUserNamesChange = useCallback(
    (checked: boolean, userName: string) => {
      onChange({
        ...value,
        userNames: checked
          ? [...value.userNames, userName]
          : value.userNames.filter((u) => u !== userName),
      });
      if (!checked && searchResults.length > 0)
        setRemovedUserNames((prev) => [...prev, userName]);
    },
    [value, onChange, searchResults.length]
  );

  const showDivider =
    (value.userNames.length > 0 || session.isLoggedIn()) && !hideFollowing;

  return (
    <MPDropdown title={title} onToggle={onToggle}>
      <div className={styles.usersFilterContainer}>
        <ul className={joinClasses(CSSGlobal.Flex.Col, styles.usersFilter)}>
          {session.isLoggedIn() && !hideFollowing ? (
            <li key="user-following">
              <MPCheckbox
                label={checkboxLabel}
                name="user-following"
                isChecked={value.isShowFollowing}
                onChange={(
                  event: ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => handleIsFollowingChange(checked)}
              />
            </li>
          ) : null}
          <UsersFilterList
            userNames={value.userNames}
            userNamesToExclude={value.userNames}
            onChange={handleUserNamesChange}
          />
        </ul>
        {showDivider ? <MPDivider className={styles.divider} /> : null}
        <UsersFilterSearchInput
          accountSearchType={accountSearchType}
          label={inputLabel}
          placeholder={inputPlaceholder}
          userNames={value.userNames}
          userNamesToExclude={removedUserNames}
          setSearchResults={setSearchResults}
        />
        <ul
          className={joinClasses(
            CSSGlobal.Flex.Col,
            styles.usersFilter,
            styles.usersFilterListSection
          )}
        >
          <UsersFilterList
            userNames={searchResults}
            userNamesToExclude={value.userNames}
            onChange={handleUserNamesChange}
          />
        </ul>
      </div>
    </MPDropdown>
  );
}

interface UsersFilterStateProps {
  isShowFollowing: boolean;
  userNames: Array<string>;
}

const resetState: UsersFilterStateProps = {
  isShowFollowing: false,
  userNames: [],
};

export function useArtistsFilter(): [
  { isShowFollowing: boolean; userNames: Array<string> },
  boolean,
  () => void,
  JSX.Element,
  JSX.Element
] {
  const [state, setState] = useState(resetState);
  return [
    state,
    state.isShowFollowing || !!state.userNames.length,
    useCallback(() => setState(resetState), []),
    <>
      {!!state.isShowFollowing && (
        <MPChip
          label="Artists You Follow"
          onDelete={() =>
            setState((prev) => ({ ...prev, isShowFollowing: false }))
          }
        />
      )}
      {state.userNames.map((artist) => (
        <MPChip
          key={artist}
          label={artist}
          onDelete={() =>
            setState((prev) => ({
              ...prev,
              userNames: prev.userNames.filter((a) => a !== artist),
            }))
          }
        />
      ))}
    </>,
    <UsersFilter
      value={{
        isShowFollowing: state.isShowFollowing,
        userNames: state.userNames,
      }}
      onChange={(value) => {
        setState({
          isShowFollowing: value.isShowFollowing,
          userNames: value.userNames,
        });
      }}
      accountSearchType={UserSearchAccountTypesEnum.Artist}
    />,
  ];
}

export default UsersFilter;
