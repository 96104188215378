import { Route } from 'react-router-dom';

import { CURATORS_FILTER_DEFAULT_QUERY_VARIABLES } from 'components/filters/CuratorsFilter';
import Reload from 'components/Reload';

import ActivityPage from './ActivityPage';

const ActivityRoute = (
  <Route
    path="activity"
    element={
      <ActivityPage
        curatorsQuery={{ variables: CURATORS_FILTER_DEFAULT_QUERY_VARIABLES }}
      />
    }
  >
    <Route path="*" element={<Reload />} />
  </Route>
);

export default ActivityRoute;
