import { useMemo } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import { MPDropdownSelect } from '@mp-frontend/core-components';

import AccountSearchBasicUserQueryType, {
  AccountSearchBasicUserQuery,
} from 'graphql/__generated__/AccountSearchBasicUserQuery.graphql';
import { UserSearchAccountTypesEnum } from 'types/__generated__/graphql';

import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

export const CURATORS_FILTER_DEFAULT_QUERY_VARIABLES: AccountSearchBasicUserQuery['variables'] =
  {
    accountSearchType: UserSearchAccountTypesEnum.Curator,
  };

interface CuratorsFilterProps {
  onChange: (values: string[]) => void;
  values: string[];
}

const BaseCuratorsFilter = withDefaultErrorBoundary(
  ({
    accountsQueryRef,
    onChange,
    values,
  }: CuratorsFilterProps & {
    accountsQueryRef: PreloadedQuery<AccountSearchBasicUserQuery>;
  }) => {
    const { accounts } = usePreloadedQuery<AccountSearchBasicUserQuery>(
      AccountSearchBasicUserQueryType,
      accountsQueryRef
    );

    const config: Record<string, string> = useMemo(
      () =>
        accounts.edges.reduce((acc, { node: { fullName, username } }) => {
          acc[username] = fullName;
          return acc;
        }, {} as Record<string, string>),
      [accounts.edges]
    );

    return (
      <MPDropdownSelect
        config={config}
        selectionType="multiple"
        title="Curators"
        values={values}
        onChange={onChange}
      />
    );
  },

  { hideState: true, suspenseless: true }
);

const QuerylessCuratorsFilter = withLoadQuery(
  ({
    accountsQuery,
    ...props
  }: CuratorsFilterProps & {
    accountsQuery: WithLoadQueryProps<AccountSearchBasicUserQuery>;
  }) => (
    <BaseCuratorsFilter {...props} accountsQueryRef={accountsQuery.queryRef} />
  ),
  {
    accountsQuery: { concreteRequest: AccountSearchBasicUserQueryType },
  }
);

export default function CuratorsFilter({
  accountsQueryRef,
  ...props
}: CuratorsFilterProps & {
  accountsQueryRef?: PreloadedQuery<AccountSearchBasicUserQuery>;
}) {
  return accountsQueryRef ? (
    <BaseCuratorsFilter {...props} accountsQueryRef={accountsQueryRef} />
  ) : (
    <QuerylessCuratorsFilter
      {...props}
      accountsQuery={{
        variables: CURATORS_FILTER_DEFAULT_QUERY_VARIABLES,
      }}
    />
  );
}
