import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, usePreloadedQuery } from 'react-relay';

import {
  MPActionButton,
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { EtherscanIcon, OpenSeaIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountGetNewsletterSubscriptionStatusQueryType, {
  AccountGetNewsletterSubscriptionStatusQuery,
} from 'graphql/__generated__/AccountGetNewsletterSubscriptionStatusQuery.graphql';
import AccountUpdateNewsletterSubscriptionMutation, {
  AccountUpdateNewsletterSubscriptionMutation$data,
  AccountUpdateNewsletterSubscriptionMutation$variables,
} from 'graphql/__generated__/AccountUpdateNewsletterSubscriptionMutation.graphql';
import ProfilesQueryType, {
  ProfilesQuery,
} from 'graphql/__generated__/ProfilesQuery.graphql';

import User from 'components/accounts/User';
import OrderedSocialMediaMap from 'constants/SocialMediaLinks';
import useSession from 'hooks/useSession';
import SocialIconLink from 'pages/profilesV2/header/desktop/SocialIconLink';
import useProfileData from 'pages/profilesV2/useProfileData';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSPadding from 'types/enums/css/Padding';
import ensureHTTPBasedProtocol from 'utils/ensureHTTPBasedProtocol';
import { parseCookie } from 'utils/getToken';
import useNewsletterGTM, {
  NewsletterActionType,
  SUBSCRIPTION_SOURCE,
} from 'utils/GTM/newletter';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { COOKIE_KEYS } from 'utils/localStorageUtils';
import { validateEmailInput } from 'utils/loginUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';
import sanitizeUrl from 'utils/sanitizeUrl';

import * as styles from 'css/pages/modularPage/sections/FooterSection.module.css';

interface FooterAuthorSectionProps {
  isPrivate: boolean;
  profileQuery: WithLoadQueryProps<ProfilesQuery>;
}

const FooterAuthorSection = withDefaultErrorBoundary(
  withLoadQuery(
    ({ isPrivate, profileQuery: { queryRef } }: FooterAuthorSectionProps) => {
      const profileData = useProfileData(queryRef);

      const socialLinksMap = profileData.socialLinks.reduce((linkMap, link) => {
        /*  eslint-disable-next-line no-param-reassign */
        linkMap[link.linkType] = link.value;
        return linkMap;
      }, {});

      const socialLinks = OrderedSocialMediaMap.filter(
        (socialMedia) => socialMedia.linkType in socialLinksMap
      ).map((socialMedia) => ({
        ...socialMedia,
        href: socialMedia.baseUrl
          ? `${socialMedia.baseUrl}${socialLinksMap[socialMedia.linkType]}`
          : sanitizeUrl(
              ensureHTTPBasedProtocol(socialLinksMap[socialMedia.linkType])
            ) ?? 'about:blank',
      }));

      return (
        <section
          className={joinClasses(
            MPBackgroundColorClass.CommonWhite,
            CSSGlobal.Flex.InlineRow,
            CSSGap[16],
            CSSPadding.AROUND[24],
            styles.footer
          )}
        >
          <User
            className={joinClasses(
              CSSGlobal.Overflow.Hidden,
              CSSGlobal.Flex.RowCenterAlign,
              CSSGlobal.Flex.NoWrap,
              styles.section
            )}
            bottomSection="follow"
            size="large"
            user={profileData.user}
          />
          <div
            className={joinClasses(
              CSSGlobal.Flex.Col,
              CSSGap[16],
              styles.section
            )}
          >
            <div
              className={joinClasses(
                MPFonts.paragraphSmall,
                MPColorClass.SolidNeutralGray5,
                CSSGlobal.Cursor.Default
              )}
            >
              {isPrivate
                ? 'In this carefully curated selection, we are proud to present a series of unique and extraordinary digital artworks, available exclusively.'
                : profileData.shortDescription}
            </div>

            {!!socialLinks.length && (
              <div className={joinClasses('flexVCenter', CSSGap[16])}>
                {!!profileData.openseaUrl && (
                  <SocialIconLink
                    socialMedia={{
                      Icon: OpenSeaIcon,
                      href: profileData.openseaUrl,
                      linkType: 'opensea',
                      tooltip: 'OpenSea',
                    }}
                  />
                )}
                {!!profileData.etherscanUrl && (
                  <SocialIconLink
                    socialMedia={{
                      Icon: EtherscanIcon,
                      href: profileData.etherscanUrl,
                      linkType: 'etherscanUrl',
                      tooltip: 'Etherscan',
                    }}
                  />
                )}
                {socialLinks.map((socialMedia) => (
                  <SocialIconLink
                    key={socialMedia.linkType}
                    socialMedia={socialMedia}
                  />
                ))}
              </div>
            )}
          </div>
        </section>
      );
    },
    {
      profileQuery: { concreteRequest: ProfilesQueryType },
    }
  ),
  { errorFallback: null, hideState: true }
);

const FooterNewsletterSignUpSection = withDefaultErrorBoundary(
  withLoadQuery(
    ({
      subscriptionQuery: { queryRef },
    }: {
      subscriptionQuery: WithLoadQueryProps<AccountGetNewsletterSubscriptionStatusQuery>;
    }) => {
      const track = useNewsletterGTM();

      const session = useSession();
      const [value, setValue] = useState<string>(
        (!session.account?.hasAnonEmailAddress && session.account?.email) || ''
      );
      const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
      const [isSubscribing, setIsSubscribing] = useState<boolean>(false);
      const [isBlurred, setIsBlurred] = useState<boolean>(false);
      const emailValidation = useMemo(() => validateEmailInput(value), [value]);

      const hide = useMemo(
        () => !!parseCookie(document.cookie)[COOKIE_KEYS.NEWSLETTER],
        []
      );

      const { newsletterSubscriptionStatus } =
        usePreloadedQuery<AccountGetNewsletterSubscriptionStatusQuery>(
          AccountGetNewsletterSubscriptionStatusQueryType,
          queryRef
        );
      const commitUpdateNewsletterSubscriptionAsync =
        promisifyMutationWithRequestData<
          AccountUpdateNewsletterSubscriptionMutation$variables,
          AccountUpdateNewsletterSubscriptionMutation$data
        >(useMutation(AccountUpdateNewsletterSubscriptionMutation)[0]);

      useEffect(() => {
        if (newsletterSubscriptionStatus) {
          setIsSubscribed(newsletterSubscriptionStatus);
        }
      }, [newsletterSubscriptionStatus]);

      const handleBlur = useCallback(() => setIsBlurred(true), []);
      const handleSubmit = useCallback(async () => {
        setIsSubscribing(true);
        try {
          await commitUpdateNewsletterSubscriptionAsync({
            email: value,
            subscribe: true,
          });
          setIsSubscribed(true);
          track.registerAction(
            NewsletterActionType.Submit,
            SUBSCRIPTION_SOURCE.EDITORIAL_FOOTER
          );
        } finally {
          setIsSubscribing(false);
        }
      }, [commitUpdateNewsletterSubscriptionAsync, track, value]);

      return (
        !isSubscribed &&
        !hide && (
          <section
            className={joinClasses(
              MPBackgroundColorClass.CommonWhite,
              CSSGlobal.Flex.InlineRow,
              CSSGap[16],
              CSSPadding.AROUND[24],
              styles.footer
            )}
          >
            <div
              className={joinClasses(
                CSSGlobal.Flex.Col,
                CSSGap[12],
                styles.section
              )}
            >
              <div className={MPFonts.headline4}>Collect Better</div>
              <div
                className={joinClasses(
                  MPFonts.paragraphSmall,
                  MPColorClass.SolidNeutralGray5
                )}
              >
                Join our newsletter to stay on top of upcoming exhibitions,
                events, and the most interesting news in the digital art space.
              </div>
            </div>

            <div
              className={joinClasses(
                CSSGlobal.Flex.Col,
                CSSGap[16],
                styles.section
              )}
            >
              <MPStyledTextField
                label="Your Email"
                placeholder="you@email.com"
                value={value}
                setValue={setValue}
                inputMode="text"
                error={!!isBlurred && emailValidation.errorMessage}
                onBlur={handleBlur}
              />
              <MPActionButton
                disabled={isSubscribing || !value || emailValidation.isError}
                fullWidth
                isLoading={isSubscribing}
                size="large"
                variant="primary"
                onClick={handleSubmit}
              >
                Subscribe
              </MPActionButton>
            </div>
          </section>
        )
      );
    },
    {
      subscriptionQuery: {
        concreteRequest: AccountGetNewsletterSubscriptionStatusQueryType,
      },
    }
  ),
  { errorFallback: null, hideState: true }
);

export default function FooterSection({
  slug,
  showNewsletterSignUp,
  isPrivate,
}: {
  isPrivate: boolean;
  showNewsletterSignUp: boolean;
  slug: string;
}) {
  const session = useSession();
  return (
    <>
      {!!slug && (
        <FooterAuthorSection
          isPrivate={isPrivate}
          profileQuery={{ variables: { slug } }}
        />
      )}

      {!!showNewsletterSignUp && (
        <FooterNewsletterSignUpSection
          subscriptionQuery={{
            variables: {
              email: session.account?.email || 'email@makersplace.com',
            },
          }}
        />
      )}
    </>
  );
}
