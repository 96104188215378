/**
 * @generated SignedSource<<5995ea98256edbe458b6459c5f290d9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SeriesSortTypeEnum = "CREATED_AT" | "RECOMMENDED";
export type SortOrderEnum = "DESC" | "ASC";
export type PaginatedExploreSeriesQuery$variables = {
  artists?: ReadonlyArray<string | null> | null;
  cursor?: string | null;
  first?: number | null;
  query?: string | null;
  showFollowingArtists?: boolean | null;
  sortOrder?: SortOrderEnum | null;
  sortType?: SeriesSortTypeEnum | null;
};
export type PaginatedExploreSeriesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExploreSeriesPaginatedFragment">;
};
export type PaginatedExploreSeriesQuery = {
  variables: PaginatedExploreSeriesQuery$variables;
  response: PaginatedExploreSeriesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "artists"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showFollowingArtists"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortOrder"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortType"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "artists",
    "variableName": "artists"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "showFollowingArtists",
    "variableName": "showFollowingArtists"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "sortOrder"
  },
  {
    "kind": "Variable",
    "name": "sortType",
    "variableName": "sortType"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaginatedExploreSeriesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ExploreSeriesPaginatedFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaginatedExploreSeriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExploreSeriesQuery",
        "kind": "LinkedField",
        "name": "series",
        "plural": false,
        "selections": [
          {
            "alias": "results",
            "args": (v1/*: any*/),
            "concreteType": "SeriesConnection",
            "kind": "LinkedField",
            "name": "series",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SeriesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionAbstractType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountAbstractType",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "collectionName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTEthereumContractType",
                        "kind": "LinkedField",
                        "name": "contract",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isGenerative",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 2
                          }
                        ],
                        "concreteType": "NFTMetadataAbstractTypeConnection",
                        "kind": "LinkedField",
                        "name": "nftMetadatas",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NFTMetadataAbstractTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "NFTMetadataAbstractType",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "previewImage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "nftMetadatas(first:2)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numOfOwners",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pk",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalSupply",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTTotalVolumeNode",
                        "kind": "LinkedField",
                        "name": "totalVolume",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalVolumeInEth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalVolumeInUsd",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "results",
            "args": (v1/*: any*/),
            "filters": [
              "artists",
              "showFollowingArtists",
              "query",
              "sortOrder",
              "sortType"
            ],
            "handle": "connection",
            "key": "PaginatedExploreSeriesQuery__results",
            "kind": "LinkedHandle",
            "name": "series"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "52e8dfe9979b6ad8c0caf1a526cdc20c",
    "id": null,
    "metadata": {},
    "name": "PaginatedExploreSeriesQuery",
    "operationKind": "query",
    "text": "query PaginatedExploreSeriesQuery(\n  $artists: [String]\n  $cursor: String\n  $first: Int\n  $query: String\n  $showFollowingArtists: Boolean\n  $sortOrder: SortOrderEnum\n  $sortType: SeriesSortTypeEnum\n) {\n  ...ExploreSeriesPaginatedFragment\n}\n\nfragment ExploreSeriesPaginatedFragment on Query {\n  series {\n    results: series(first: $first, after: $cursor, artists: $artists, showFollowingArtists: $showFollowingArtists, query: $query, sortOrder: $sortOrder, sortType: $sortType) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          author {\n            fullName\n            username\n            id\n          }\n          collectionName\n          contract {\n            isGenerative\n            id\n          }\n          id\n          nftMetadatas(first: 2) {\n            edges {\n              node {\n                id\n                previewImage\n              }\n            }\n          }\n          numOfOwners\n          pk\n          slug\n          totalSupply\n          totalVolume {\n            totalVolumeInEth\n            totalVolumeInUsd\n          }\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "daeb7c3805ff82378b3358f86d1d6af0";

export default node;
