import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-relay';
import { Hash } from 'viem';
import { useAccount } from 'wagmi';
import * as Sentry from '@sentry/react';

import NFTAcceptGlobalBidWithCustodialWalletMutation, {
  NFTAcceptGlobalBidWithCustodialWalletMutation$data,
  NFTAcceptGlobalBidWithCustodialWalletMutation$variables,
} from 'graphql/__generated__/NFTAcceptGlobalBidWithCustodialWalletMutation.graphql';
import NFTAcceptGlobalBidWithPersonalWalletMutation, {
  NFTAcceptGlobalBidWithPersonalWalletMutation$data,
  NFTAcceptGlobalBidWithPersonalWalletMutation$variables,
} from 'graphql/__generated__/NFTAcceptGlobalBidWithPersonalWalletMutation.graphql';
import NFTAcceptProductBidWithCustodialWalletMutation, {
  NFTAcceptProductBidWithCustodialWalletMutation$data,
  NFTAcceptProductBidWithCustodialWalletMutation$variables,
} from 'graphql/__generated__/NFTAcceptProductBidWithCustodialWalletMutation.graphql';
import NFTAcceptEthBidPersonalWalletMutation, {
  NFTAcceptProductBidWithPersonalWalletMutation$data,
  NFTAcceptProductBidWithPersonalWalletMutation$variables,
} from 'graphql/__generated__/NFTAcceptProductBidWithPersonalWalletMutation.graphql';
import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import useDigitalMediaCoreContract from 'hooks/contracts/useDigitalMediaCoreContract';
import useGlobalDigitalMediaBidCoreContract from 'hooks/contracts/useGlobalDigitalMediaBidCoreContract';
import usePublicClient from 'hooks/useWeb3Client';
import { NFTType } from 'types/graphql/NFT';
import { HexString } from 'utils/jwt/walletUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

import useAcceptPersonalWalletEthBid from './useAcceptPersonalWalletEthBid';

interface ProductAcceptBidProps {
  nft: NFTType;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
  transferCoreContract: NFTContractQuery$data['nftContract'];
  setShowDialog?: Dispatch<SetStateAction<boolean>>;
}

const useProductAcceptBid = ({
  nft,
  transferCoreContract,
  setCurrentTransactionHash,
  setShowDialog,
}: ProductAcceptBidProps) => {
  const currentBid = nft.listing.liveBid;
  const provider = usePublicClient();
  const { address: currentUserAddress } = useAccount();

  const { useApprove } = useDigitalMediaCoreContract({
    abi: JSON.parse(nft.contract.abidata).abi,
    contractAddress: nft.contract.address as HexString,
  });

  const approveCCTransferCore = useApprove({
    tokenAddress: transferCoreContract.address as HexString,
    tokenId: parseInt(nft.onchainId, 10),
  });

  const { useAcceptGlobalBid } = useGlobalDigitalMediaBidCoreContract({
    abi: JSON.parse(currentBid.contract.abidata).abi,
    contractAddress: currentBid.contract.address as HexString,
  });

  const acceptGlobalBidETH = useAcceptGlobalBid({
    onchainId: parseInt(currentBid.onchainId, 10),
    tokenAddress: nft.contract.address as HexString,
    tokenId: parseInt(nft.onchainId, 10),
  });

  const [acceptCustodialProductBidMutation] = useMutation(
    NFTAcceptProductBidWithCustodialWalletMutation
  );
  const acceptCustodialProductBidMutationAsync =
    promisifyMutationWithRequestData<
      NFTAcceptProductBidWithCustodialWalletMutation$variables,
      NFTAcceptProductBidWithCustodialWalletMutation$data
    >(acceptCustodialProductBidMutation);

  const [acceptCustodialGlobalBidMutation] = useMutation(
    NFTAcceptGlobalBidWithCustodialWalletMutation
  );
  const acceptCustodialGlobalBidMutationAsync =
    promisifyMutationWithRequestData<
      NFTAcceptGlobalBidWithCustodialWalletMutation$variables,
      NFTAcceptGlobalBidWithCustodialWalletMutation$data
    >(acceptCustodialGlobalBidMutation);

  const [acceptEthBidPersonalWalletMutation] = useMutation(
    NFTAcceptEthBidPersonalWalletMutation
  );
  const acceptEthBidPersonalWalletMutationAsync =
    promisifyMutationWithRequestData<
      NFTAcceptProductBidWithPersonalWalletMutation$variables,
      NFTAcceptProductBidWithPersonalWalletMutation$data
    >(acceptEthBidPersonalWalletMutation);

  const [acceptEthGlobalBidPersonalWalletMutation] = useMutation(
    NFTAcceptGlobalBidWithPersonalWalletMutation
  );
  const acceptEthGlobalBidPersonalWalletMutationAsync =
    promisifyMutationWithRequestData<
      NFTAcceptGlobalBidWithPersonalWalletMutation$variables,
      NFTAcceptGlobalBidWithPersonalWalletMutation$data
    >(acceptEthGlobalBidPersonalWalletMutation);

  const acceptCustodialCCProductBid = async (): Promise<void> => {
    try {
      await acceptCustodialProductBidMutationAsync({
        bidId: parseInt(currentBid.pk, 10),
      });
    } catch (error) {
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
    }
  };

  const acceptCustodialCCGlobalBid = async (): Promise<void> => {
    try {
      await acceptCustodialGlobalBidMutationAsync({
        mediaBidId: parseInt(currentBid.pk, 10),
        productId: parseInt(nft.listing.pk, 10),
      });
    } catch (error) {
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
    }
  };

  const transitionTxHashShowDialog = (txHash: Hash) => {
    setCurrentTransactionHash(txHash);
    setTimeout(() => {
      setShowDialog?.(true);
    }, 200);
  };

  const acceptPersonalWalletGlobalEthBid = async (): Promise<boolean> => {
    try {
      const transactionResult = await acceptGlobalBidETH.mutate.writeAsync();
      const nonce =
        (await provider.getTransactionCount(currentUserAddress)) + 1;
      await acceptEthGlobalBidPersonalWalletMutationAsync({
        fromAddress: currentUserAddress,
        mediaBidId: parseInt(currentBid.pk, 10),
        nonce,
        productId: parseInt(nft.listing.pk, 10),
        transactionId: transactionResult,
      });
      transitionTxHashShowDialog(transactionResult);
      return true;
    } catch (error) {
      acceptGlobalBidETH.mutate.reset();
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
      return false;
    }
  };

  const acceptPersonalWalletEthBid = useAcceptPersonalWalletEthBid(nft);

  const acceptPersonalWalletGlobalCCBid = async (): Promise<boolean> => {
    try {
      const transactionResult = await approveCCTransferCore.mutate.writeAsync();
      const nonce =
        (await provider.getTransactionCount(currentUserAddress)) + 1;
      await acceptEthGlobalBidPersonalWalletMutationAsync({
        fromAddress: currentUserAddress,
        mediaBidId: parseInt(currentBid.pk, 10),
        nonce,
        productId: parseInt(nft.listing.pk, 10),
        transactionId: transactionResult,
      });
      transitionTxHashShowDialog(transactionResult);
      return true;
    } catch (error) {
      approveCCTransferCore.mutate.reset();
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
      return false;
    }
  };

  const acceptPersonalWalletCCBid = async (): Promise<boolean> => {
    try {
      const transactionResult = await approveCCTransferCore.mutate.writeAsync();
      const nonce =
        (await provider.getTransactionCount(currentUserAddress)) + 1;
      await acceptEthBidPersonalWalletMutationAsync({
        bidId: parseInt(currentBid.pk, 10),
        cancelledByCustomer: false,
        fromAddress: currentUserAddress,
        nonce,
        transactionId: transactionResult,
      });
      transitionTxHashShowDialog(transactionResult);
      return true;
    } catch (error) {
      approveCCTransferCore.mutate.reset();
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
      return false;
    }
  };

  const handleAcceptBidCustodial = () => {
    if (currentBid.isGlobalBid) {
      acceptCustodialCCGlobalBid();
    } else {
      acceptCustodialCCProductBid();
    }
  };

  const handleAcceptBidPersonalWallet = async (): Promise<boolean> => {
    // Personal Wallet Global CC and ETH Bid Accept.
    if (currentBid.isGlobalBid) {
      if (currentBid.isCcBid) {
        return acceptPersonalWalletGlobalCCBid();
      }
      return acceptPersonalWalletGlobalEthBid();
    }
    if (currentBid.isCcBid) {
      return acceptPersonalWalletCCBid();
    }
    return acceptPersonalWalletEthBid
      .acceptPersonalWalletEthBid()
      .then((transactionResult) => {
        transitionTxHashShowDialog(transactionResult);
        return true;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    acceptCustodialCCGlobalBid,
    acceptCustodialCCProductBid,
    acceptPersonalWalletCCBid,
    acceptPersonalWalletGlobalCCBid,
    acceptPersonalWalletGlobalEthBid,
    handleAcceptBidCustodial,
    handleAcceptBidPersonalWallet,
    handleAcceptBidPersonalWalletError:
      !currentBid.isCcBid && !currentBid.isGlobalBid
        ? acceptPersonalWalletEthBid.error
        : undefined,
  };
};

export default useProductAcceptBid;
