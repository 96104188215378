import { useCallback, useState } from 'react';

import { MPActionButton } from '@mp-frontend/core-components';

import useProductOwnership from 'hooks/product/useProductOwnership';
import useSession from 'hooks/useSession';
import { NFTType } from 'types/graphql/NFT';

import ManageNFTDialog from './Dialog';

interface ManageNFTProps {
  nft: NFTType;
  invalidate?: () => void;
}

export default function ManageNFT({ nft, invalidate }: ManageNFTProps) {
  const session = useSession();
  const isCurrentUserTokenOwner = useProductOwnership({ nft });
  const [showDialog, setShowDialog] = useState(false);
  const close = useCallback(() => setShowDialog(false), []);
  const open = useCallback(() => setShowDialog(true), []);
  /* TODO double check that this is needed for send
  https://app.asana.com/0/1208694122360326/1208961593726100/f

  const { liveSale } = nft.listing;
  const isPendingSale = isNFTPendingSale(nft.listing);
  const isOnChain = isMintedOnChain(nft);
  const canShowSellArtworkMessage =
    !liveSale && !nft.listing.hasReservePrice && isOnChain && nft.isSendable;
  */

  return !!isCurrentUserTokenOwner && session.isLoggedIn() ? (
    <>
      <MPActionButton fullWidth size="large" onClick={open}>
        Manage Artwork
      </MPActionButton>
      {!!showDialog && (
        <ManageNFTDialog nft={nft} invalidate={invalidate} close={close} />
      )}
    </>
  ) : null;
}
