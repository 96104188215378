import { useCallback } from 'react';
import { useMutation } from 'react-relay';
import { Hash } from 'viem';
import { useAccount } from 'wagmi';
import * as Sentry from '@sentry/react';

import NFTRejectProductBidWithPersonalWalletInETHMutation, {
  NFTRejectProductBidWithPersonalWalletInETHMutation$data,
  NFTRejectProductBidWithPersonalWalletInETHMutation$variables,
} from 'graphql/__generated__/NFTRejectProductBidWithPersonalWalletInETHMutation.graphql';

import useProductCancelBidEth from 'hooks/contracts/DigitalMediaSaleCore/useCancelBid';
import usePublicClient from 'hooks/useWeb3Client';
import { NFTType } from 'types/graphql/NFT';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

function useRejectPersonalWalletEthBid(nft: NFTType): {
  error: Error | null;
  rejectPersonalWalletEthBid: () => Promise<Hash>;
} {
  const { address: currentUserAddress } = useAccount();
  const provider = usePublicClient();
  const {
    writeAsync,
    reset,
    simulationError: cancelBidError,
  } = useProductCancelBidEth(nft);
  const currentBid = nft.listing.liveBid;

  const [rejectETHBidPersonalWalletMutation] = useMutation(
    NFTRejectProductBidWithPersonalWalletInETHMutation
  );

  const rejectETHBidPersonalWalletMutationAsync =
    promisifyMutationWithRequestData<
      NFTRejectProductBidWithPersonalWalletInETHMutation$variables,
      NFTRejectProductBidWithPersonalWalletInETHMutation$data
    >(rejectETHBidPersonalWalletMutation);

  const rejectPersonalWalletEthBid = useCallback(async (): Promise<Hash> => {
    if (cancelBidError) throw cancelBidError;
    try {
      const transactionResult = await writeAsync();
      const nonce =
        (await provider.getTransactionCount(currentUserAddress)) + 1;
      await rejectETHBidPersonalWalletMutationAsync({
        bidId: parseInt(currentBid.pk, 10),
        fromAddress: currentUserAddress,
        nonce,
        transactionId: transactionResult,
      }).catch((err) => {
        throw err;
      });
      return transactionResult;
    } catch (error) {
      reset();
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
      throw error;
    }
  }, [
    writeAsync,
    reset,
    cancelBidError,
    currentBid.pk,
    currentUserAddress,
    nft.pk,
    provider,
    rejectETHBidPersonalWalletMutationAsync,
  ]);

  return {
    error: cancelBidError,
    rejectPersonalWalletEthBid,
  };
}

export default useRejectPersonalWalletEthBid;
