.filteredNoResults {
  text-align: center;
  margin: 40px auto 0;
}

.container {
  width: 500px;
  padding: 32px;
  text-align: center;
  margin: 32px auto 0;
  box-sizing: border-box;
  background-color: var(--color-commonWhite);
}

@media (--mq-is-mobile) {
  .container {
    width: 332px;
  }
}
