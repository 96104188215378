import { useCallback } from 'react';
import { PreloadedQuery } from 'react-relay';

import {
  MPChip,
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { AccountSearchBasicUserQuery } from 'graphql/__generated__/AccountSearchBasicUserQuery.graphql';
import {
  ActivityActionEnum,
  DateTimeRangeType,
  UserSearchAccountTypesEnum,
} from 'types/__generated__/graphql';

import CuratorsFilter from 'components/filters/CuratorsFilter';
import UsersFilter from 'components/filters/UsersFilter';
import ActionsFilter, {
  ACTION_TITLES,
} from 'pages/activity/filters/ActionsFilter';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generatePriceString from 'utils/currency/generatePricing';
import useActivityGTM from 'utils/GTM/activity';

import DateFilter, {
  DEFAULT_DATE,
  formatDateToChipString,
  formatDateToQueryString,
} from './filters/DateFilter';
import PriceFilter, { PriceFilterValue } from './filters/PriceFilter';

import * as styles from 'css/pages/activity/ActivityFilters.module.css';

export interface FiltersState {
  actions: ActivityActionEnum[];
  activityDate: DateTimeRangeType;
  collectors: string[];
  curators: string[];
  isShowFollowing: boolean;
  price: PriceFilterValue;
  showFollowingCollectors: boolean;
  userNames: string[];
}

export type FiltersStateChangeEvent = Partial<FiltersState>;

interface ActivityPageFiltersProps {
  curatorsQueryRef: PreloadedQuery<AccountSearchBasicUserQuery>;
  filters: FiltersState;
  onChange: (filters: FiltersStateChangeEvent) => void;
}

const TITLE = 'Artists';

export default function ActivityPageFilters({
  filters,
  curatorsQueryRef,
  onChange,
}: ActivityPageFiltersProps) {
  const isMobile = useIsMobile();
  const track = useActivityGTM();
  const handleToggle = useCallback(
    (open: boolean) => track.toggleFilter(TITLE, open),
    [track]
  );

  return (
    <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[10])}>
      <div
        className={joinClasses(
          {
            [CSSGlobal.Flex.CenteredRow]: !isMobile,
            [CSSGlobal.Flex.Row]: isMobile,
          },
          CSSGlobal.NoScrollbar,
          styles.filters
        )}
      >
        <div className={joinClasses(CSSGlobal.Flex.RowCenterAlign, CSSGap[8])}>
          <span
            className={joinClasses(
              CSSGlobal.Cursor.Default,
              MPFonts.textSmallMedium,
              MPColorClass.SolidNeutralGray5
            )}
          >
            Filter by
          </span>

          <ActionsFilter
            values={filters.actions}
            onChange={(actions) => onChange({ actions })}
          />
          <PriceFilter
            value={filters.price}
            onChange={(price) => onChange({ price })}
          />
          <DateFilter
            value={{
              from: filters.activityDate.min,
              to: filters.activityDate.max,
            }}
            onChange={(activityDate) =>
              onChange({
                activityDate: {
                  max: activityDate.to,
                  min: activityDate.from,
                },
              })
            }
          />
          <UsersFilter
            value={{
              isShowFollowing: filters.isShowFollowing,
              userNames: filters.userNames,
            }}
            onChange={(value) => onChange(value)}
            onToggle={handleToggle}
            accountSearchType={UserSearchAccountTypesEnum.Artist}
          />
          <UsersFilter
            value={{
              isShowFollowing: filters.showFollowingCollectors,
              userNames: filters.collectors,
            }}
            onChange={(value) =>
              onChange({
                collectors: value.userNames,
                showFollowingCollectors: value.isShowFollowing,
              })
            }
            accountSearchType={UserSearchAccountTypesEnum.Collector}
          />
          <CuratorsFilter
            onChange={(values) => onChange({ curators: values })}
            values={filters.curators}
            accountsQueryRef={curatorsQueryRef}
          />
        </div>
      </div>

      <div
        className={joinClasses(
          {
            [CSSGlobal.Flex.CenteredRow]: !isMobile,
            [CSSGlobal.Flex.Row]: isMobile,
          },
          CSSGlobal.NoScrollbar,
          styles.filters
        )}
      >
        <div className={joinClasses(CSSGlobal.Flex.RowCenterAlign, CSSGap[8])}>
          {!!filters.actions.length &&
            filters.actions.map((action) => (
              <MPChip
                key={action}
                label={ACTION_TITLES[action]}
                onDelete={() =>
                  onChange({
                    actions: filters.actions.filter(
                      (actionToDelete) => actionToDelete !== action
                    ),
                  })
                }
              />
            ))}

          {!!(filters.price.min || filters.price.max) &&
            (!filters.price.min ? (
              <MPChip
                label={`Max ${generatePriceString(
                  filters.price.max,
                  CurrencyDisplayMode.USD
                )}`}
                onDelete={() =>
                  onChange({
                    price: {},
                  })
                }
              />
            ) : !filters.price.max ? (
              <MPChip
                label={`Min ${generatePriceString(
                  filters.price.min,
                  CurrencyDisplayMode.USD
                )}`}
                onDelete={() => onChange({ price: {} })}
              />
            ) : (
              <MPChip
                label={`Min ${generatePriceString(
                  filters.price.min,
                  CurrencyDisplayMode.USD
                )} - Max ${generatePriceString(
                  filters.price.max,
                  CurrencyDisplayMode.USD
                )}`}
                onDelete={() => onChange({ price: {} })}
              />
            ))}

          {(!filters.activityDate.min && !filters.activityDate.max) ||
          (filters.activityDate.min ===
            formatDateToQueryString(DEFAULT_DATE.FROM) &&
            filters.activityDate.max ===
              formatDateToQueryString(DEFAULT_DATE.TO)) ? null : filters
              .activityDate.max === formatDateToQueryString(DEFAULT_DATE.TO) ? (
            <MPChip
              label={`From ${formatDateToChipString(
                filters.activityDate.min
              )} to now`}
              onDelete={() =>
                onChange({
                  activityDate: {
                    max: '',
                    min: '',
                  },
                })
              }
            />
          ) : (
            <MPChip
              label={`From ${formatDateToChipString(
                filters.activityDate.min
              )} to ${formatDateToChipString(filters.activityDate.max)}`}
              onDelete={() =>
                onChange({
                  activityDate: {
                    max: '',
                    min: '',
                  },
                })
              }
            />
          )}
          {!!filters.isShowFollowing && (
            <MPChip
              key="artist-you-follow-chip"
              label="Artists You Follow"
              onDelete={() => onChange({ isShowFollowing: undefined })}
            />
          )}
          {!!filters.userNames.length &&
            filters.userNames.map((username) => (
              <MPChip
                key={username}
                label={username}
                onDelete={() =>
                  onChange({
                    userNames: filters.userNames.filter(
                      (userNameToDelete) => userNameToDelete !== username
                    ),
                  })
                }
              />
            ))}
          {!!filters.showFollowingCollectors && (
            <MPChip
              key="collectors-you-follow-chip"
              label="Collectors You Follow"
              onDelete={() => onChange({ showFollowingCollectors: undefined })}
            />
          )}
          {!!filters.collectors.length &&
            filters.collectors.map((username) => (
              <MPChip
                key={username}
                label={username}
                onDelete={() =>
                  onChange({
                    collectors: filters.collectors.filter(
                      (userNameToDelete) => userNameToDelete !== username
                    ),
                  })
                }
              />
            ))}
          {!!filters.curators.length &&
            filters.curators.map((username) => (
              <MPChip
                key={username}
                label={username}
                onDelete={() =>
                  onChange({
                    curators: filters.curators.filter(
                      (userNameToDelete) => userNameToDelete !== username
                    ),
                  })
                }
              />
            ))}
        </div>
      </div>
    </div>
  );
}
