import { Dispatch, useCallback, useEffect, useState } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { formatEther, Hash } from 'viem';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';

import AccountStripeCardsRequest, {
  AccountStripeCardsQuery,
} from 'graphql/__generated__/AccountStripeCardsQuery.graphql';
import GasRequestByIdRequest, {
  GasRequestByIdQuery,
} from 'graphql/__generated__/GasRequestByIdQuery.graphql';
import GasRequestSpeedUpByCCRequest, {
  GasRequestSpeedUpByCCMutation,
} from 'graphql/__generated__/GasRequestSpeedUpByCCMutation.graphql';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import ErrorDisplay from 'components/Error';
import { useCreditCard } from 'components/payment/CreditCard';
import TotalRow from 'components/payment/TotalRow';
import usePollLoadQuery from 'hooks/graphqlLogic/usePollLoadQuery';
import CSSMargin from 'types/enums/css/Margin';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { usePromisifyMutationWithRequestData } from 'utils/promisifyMutation';

import PendingTransferAndSuccess from './PendingAndSuccess';
import WaitOnTransactionHashDialog from './WaitOnTransactionHashDialog';

const emptyVariables = { variables: {} };

interface PayByCreditCardProps {
  address: Hash;
  closeAndInvalidate: () => void;
  gasRequestByIdRef: WithLoadQueryProps<GasRequestByIdQuery>['queryRef'];
  gasRequestID: number;
  nft: NFTType;
  setIsDisabled: Dispatch<boolean>;
  setIsLoading: Dispatch<boolean>;
  setSubmit: Dispatch<() => { submit: () => Promise<void> }>;
  stripeQuery: WithLoadQueryProps<AccountStripeCardsQuery>;
}

const PayByCreditCard = withLoadQuery(
  ({
    gasRequestByIdRef,
    stripeQuery,
    gasRequestID,
    setSubmit,
    setIsDisabled,
    setIsLoading,
    nft,
    closeAndInvalidate,
    address,
  }: PayByCreditCardProps) => {
    const [error, setError] = useState<Error>();
    const [transactionHash, setTransactionHash] = useState('');
    const [isWaitingOnHash, setIsWaitingOnHash] = useState(false);
    const gasRequest = usePreloadedQuery<GasRequestByIdQuery>(
      GasRequestByIdRequest,
      gasRequestByIdRef
    ).gasRequests.edges[0].node;
    const {
      JSX: creditCardJSX,
      getPaymentMethodId,
      withNextPaymentAction,
      isValid,
      shouldRememberCard,
    } = useCreditCard(stripeQuery.queryRef);
    useEffect(() => setIsDisabled(isValid), [isValid, setIsDisabled]);

    const [asyncSpeedupRequest, isRequestingSpeedup] =
      usePromisifyMutationWithRequestData<GasRequestSpeedUpByCCMutation>(
        GasRequestSpeedUpByCCRequest
      );

    // Too hard for linter
    const _asyncSpeedupRequestWithNextPayment = withNextPaymentAction<
      GasRequestSpeedUpByCCMutation['variables'],
      GasRequestSpeedUpByCCMutation['response']
    >(asyncSpeedupRequest);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const asyncSpeedupRequestWithNextPayment = useCallback(
      _asyncSpeedupRequestWithNextPayment,
      [withNextPaymentAction, asyncSpeedupRequest, withNextPaymentAction]
    );

    const requestSpeedup = useCallback(async () => {
      try {
        const paymentMethodId = await getPaymentMethodId();
        const {
          gasRequestSpeedUpTxInCc: { txid },
        } = await asyncSpeedupRequestWithNextPayment({
          paymentMethodId,
          rememberCard: shouldRememberCard,
          requestId: gasRequestID,
        });
        if (txid) {
          setTransactionHash(txid);
        } else {
          setIsWaitingOnHash(true);
        }
      } catch (err) {
        setError(err);
      }
    }, [
      asyncSpeedupRequestWithNextPayment,
      getPaymentMethodId,
      gasRequestID,
      shouldRememberCard,
    ]);

    useEffect(
      () => setIsLoading(isRequestingSpeedup),
      [isRequestingSpeedup, setIsLoading]
    );

    useEffect(
      () =>
        setSubmit(() => ({
          submit: requestSpeedup,
        })),
      [requestSpeedup, setSubmit]
    );

    const ethAmount = formatEther(gasRequest.amountInWei);
    const usdAmount = gasRequest.ethToUsd * parseFloat(ethAmount);
    return (
      <>
        <ErrorDisplay error={error} />
        {creditCardJSX}
        <TotalRow
          ethAmount={ethAmount}
          usdAmount={usdAmount}
          currencyDisplay={CurrencyDisplayMode.USD}
        />
        {!!transactionHash && (
          <PendingTransferAndSuccess
            nft={nft}
            currentTransactionHash={transactionHash}
            toAddress={address}
            closeAndInvalidate={closeAndInvalidate}
          />
        )}
        {!transactionHash && !!isWaitingOnHash && (
          <WaitOnTransactionHashDialog
            nft={nft}
            closeAndInvalidate={closeAndInvalidate}
          />
        )}
      </>
    );
  },
  {
    stripeQuery: { concreteRequest: AccountStripeCardsRequest },
  }
);

export default function PayByCreditCardDialog({
  gasRequestID,
  nft,
  closeAndInvalidate,
  close,
  address,
}) {
  const [submit, setSubmit] = useState<{ submit: () => void }>({
    submit: () => null,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [gasRequestByIdRef] = usePollLoadQuery<GasRequestByIdQuery>({
    intervalMs: 120000,
    queryType: GasRequestByIdRequest,
    queryVariables: { id: gasRequestID },
  });

  return (
    <StackStateDialog
      title="Transfer"
      onClose={close}
      actionButton={
        <MPActionButton
          onClick={submit.submit}
          disabled={isDisabled}
          isLoading={isLoading}
        >
          Confirm Transfer
        </MPActionButton>
      }
    >
      <>
        <div className={CSSMargin.BOTTOM[16]}>
          You will need to pay a transfer fee for this artwork. You are
          transferring this artwork to:{' '}
          <span className={MPFonts.textSmallMedium}>{address}</span>
        </div>
        {!!gasRequestByIdRef && (
          <PayByCreditCard
            gasRequestByIdRef={gasRequestByIdRef}
            stripeQuery={emptyVariables}
            address={address}
            setSubmit={setSubmit}
            gasRequestID={gasRequestID}
            setIsDisabled={setIsDisabled}
            setIsLoading={setIsLoading}
            nft={nft}
            closeAndInvalidate={closeAndInvalidate}
          />
        )}
      </>
    </StackStateDialog>
  );
}
