import {
  AnchorHTMLAttributes,
  ComponentType,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { noop } from 'lodash';
import { usePreloadedQuery } from 'react-relay';
import { Link } from 'react-router-dom';
import { ClickAwayListener, SvgIconProps } from '@mui/material';

import {
  MPActionButton,
  MPAnimations,
  MPBackgroundColorClass,
  MPColorClass,
  MPDivider,
  MPFonts,
  MPStandardDialog,
  useIsMobile,
} from '@mp-frontend/core-components';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CopyIcon,
  DiscordIcon,
  EtherscanIcon,
  InstagramIcon,
  MPSVGIconProps,
  TwitterIcon,
  WebsiteIcon,
} from '@mp-frontend/core-components/icons';
import {
  joinClasses,
  useOnEnterKey,
  useRefState,
} from '@mp-frontend/core-utils';

import AccountGetUserQueryType, {
  AccountGetUserQuery,
} from 'graphql/__generated__/AccountGetUserQuery.graphql';
import { StoreLinkLinkType } from 'types/__generated__/graphql';

import BaseUser from 'components/accounts/User';
import { FollowButton } from 'components/buttons/FollowButton';
import LineClamp from 'components/common/LineClamp';
import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import ROUTES from 'constants/Routes';
import THRESHOLDS from 'constants/Thresholds';
import { APP_NAME } from 'constants/Utils';
import useSession from 'hooks/useSession';
import Embed from 'pages/product/productPreview/Embed';
import Image from 'pages/product/productPreview/Image';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSPadding from 'types/enums/css/Padding';
import { CloudinaryParams, withCloudinaryParams } from 'utils/cloudinaryUtils';
import copyToClipboard from 'utils/copyToClipboard';
import ensureHTTPBasedProtocol from 'utils/ensureHTTPBasedProtocol';
import getAddressDisplay from 'utils/getAddressDisplay';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { getNFTPrimaryMedia, isNFTDynamic } from 'utils/nftUtils';
import toNumericShorthand from 'utils/numericShorthand';
import sanitizeUrl from 'utils/sanitizeUrl';

import HeroCard from './HeroCard';

import * as styles from 'css/components/cards/HeroUserCard.module.css';

type UserType = AccountGetUserQuery['response']['accounts']['edges'][0]['node'];
type FeaturedArtworkType = UserType['featuredArtwork'];

const CLOUDINARY_PARAMS: CloudinaryParams = {
  crop: 'limit',
  height: 800,
  quality: 'auto:good',
  width: 1300,
};

const MAX_STATS_FOR_CARD = 3;

interface UserProps {
  user: Omit<UserType, 'featuredArtwork'>;
}

function User({ user }: UserProps) {
  const eyeBrow = useMemo(() => {
    const result = [];
    if (user.isArtist) {
      result.push('Artist');
    }

    if (user.isCurator) {
      result.push('Curator');
    }

    return result.length ? result.join(' ・ ') : 'Collector';
  }, [user]);

  return (
    <div className={styles.user}>
      <BaseUser
        nameColorClassName={joinClasses(
          MPColorClass.CommonWhite,
          MPAnimations.Color.LightToDark
        )}
        size="large"
        bottomSection="none"
        topSection={
          <span
            className={joinClasses(
              MPColorClass.SolidNeutralGray2,
              MPFonts.textSmallMedium,
              styles.userLabel
            )}
          >
            {eyeBrow}
          </span>
        }
        user={user}
      />
    </div>
  );
}

const LinkComponent = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>
>(({ children, href, ...props }, ref) => (
  <Link {...props} ref={ref} to={href}>
    {children}
  </Link>
));

type SocialItemType = {
  Icon: ComponentType<MPSVGIconProps | SvgIconProps>;
  label: string;
  url: string;
  baseUrl?: string;
  isAddress?: boolean;
};

function SocialDropdown({ user }: UserProps) {
  const session = useSession();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [items, SocialIcon] = useMemo(() => {
    const commonItems: SocialItemType[] = (
      [
        user.address && {
          Icon: CopyIcon,
          isAddress: true,
          label: getAddressDisplay(user.address),
          url: user.address,
        },
        user.address && {
          Icon: EtherscanIcon,
          baseUrl: `${session.contractNetworkUrl}address/`,
          label: 'Etherscan',
          url: user.address,
        },
      ] as SocialItemType[]
    ).filter(Boolean);

    const socialLinks = user.socialLinks.reduce((acc, { linkType, url }) => {
      acc[linkType] = url;
      return acc;
    }, {});
    const socialItems: SocialItemType[] = (
      [
        socialLinks[StoreLinkLinkType.Twitter] && {
          Icon: TwitterIcon,
          label: 'Twitter',
          url: socialLinks[StoreLinkLinkType.Twitter],
        },
        socialLinks[StoreLinkLinkType.Instagram] && {
          Icon: InstagramIcon,
          label: 'Instagram',
          url: socialLinks[StoreLinkLinkType.Instagram],
        },
        socialLinks[StoreLinkLinkType.DiscordUser] && {
          Icon: DiscordIcon,
          label: 'Discord',
          url: socialLinks[StoreLinkLinkType.DiscordUser],
        },
        socialLinks[StoreLinkLinkType.Website] && {
          Icon: WebsiteIcon,
          label: 'Website',
          url: socialLinks[StoreLinkLinkType.Website],
        },
      ] as SocialItemType[]
    ).filter(Boolean);

    return [
      [...commonItems, ...socialItems],
      socialItems[0]?.Icon || TwitterIcon,
    ] as const;
  }, [user, session.contractNetworkUrl]);
  const itemClassName = useMemo(
    () =>
      joinClasses(
        MPAnimations.Color.DarkToLight,
        CSSGlobal.Cursor.Pointer,
        CSSGlobal.Flex.InlineRowCenterAlign,
        CSSGlobal.Flex.NoWrap,
        CSSGap[4],
        'invisibleAnchor'
      ),
    []
  );

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleCopy = useCallback(() => {
    copyToClipboard(user.address);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  }, [user.address]);
  const handleCopyOnEnter = useOnEnterKey(handleCopy);

  return (
    !!items.length && (
      <ClickAwayListener onClickAway={handleClose}>
        <div
          ref={containerRef}
          className={joinClasses(styles.socialDropdown, {
            [styles.open]: isOpen,
          })}
        >
          <MPActionButton
            size="small"
            variant={isOpen ? 'secondary-black-inverted' : 'secondary-black'}
            className={joinClasses(
              CSSGlobal.Flex.InlineRow,
              CSSGap[2],
              styles.toggleButton
            )}
            onClick={isOpen ? handleClose : handleOpen}
          >
            <SocialIcon fontSize="20" />
            {isOpen ? (
              <ArrowUpIcon fontSize="15" />
            ) : (
              <ArrowDownIcon fontSize="15" />
            )}
          </MPActionButton>

          <div
            className={joinClasses(
              MPBackgroundColorClass.SolidNeutralOffBlack,
              MPColorClass.SolidNeutralGray2,
              CSSGlobal.Flex.Col,
              CSSGap[20],
              CSSPadding.AROUND[16],
              styles.dropdown
            )}
          >
            {items.map(({ Icon, label, url, baseUrl, isAddress }) =>
              isAddress ? (
                <div
                  tabIndex={0}
                  role="button"
                  key={label}
                  className={itemClassName}
                  onClick={handleCopy}
                  onKeyDown={handleCopyOnEnter}
                >
                  <Icon />
                  {isCopied ? 'Copied' : label}
                </div>
              ) : (
                <a
                  key={label}
                  href={
                    baseUrl
                      ? `${baseUrl}${url}`
                      : sanitizeUrl(ensureHTTPBasedProtocol(url)) ??
                        'about:blank'
                  }
                  className={itemClassName}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleClose}
                >
                  <Icon />
                  <span>{label}</span>
                </a>
              )
            )}
          </div>
        </div>
      </ClickAwayListener>
    )
  );
}

interface DescriptionProps {
  additionalAction?: ReactNode;
  hideViewProfile?: boolean;
  showSocial?: boolean;
}

function Description({
  user,
  additionalAction,
  hideViewProfile,
  showSocial,
  onClick,
}: DescriptionProps & { onClick: () => void; user: UserType }) {
  const session = useSession();
  const handleOnEnter = useOnEnterKey(onClick);

  return (
    <div className={styles.userDescription}>
      {!!user.biography && (
        <div
          tabIndex={0}
          role="button"
          className={joinClasses(CSSGlobal.Flex.Col, CSSGap[4])}
          onClick={onClick}
          onKeyDown={handleOnEnter}
        >
          <LineClamp
            className={joinClasses(
              MPFonts.paragraphSmall,
              MPColorClass.CommonWhite,
              MPAnimations.Color.LightToDark,
              styles.biographyParagraph
            )}
            text={user.biography}
            lineClamp={3}
            heightPct={100}
            clampedNode={
              <span
                className={joinClasses(
                  MPFonts.textSmallMedium,
                  MPColorClass.SolidNeutralGray2,
                  MPAnimations.Color.LightToDark,
                  CSSGlobal.Cursor.Pointer
                )}
              >
                Read more
              </span>
            }
          />
        </div>
      )}

      <div className={joinClasses(CSSGlobal.Flex.Row, CSSGap[16])}>
        {session.account?.pk !== user.pk && (
          <FollowButton
            size="small"
            userId={parseInt(user.pk, 10)}
            username={user.username}
            variant="secondary-black"
          />
        )}

        {additionalAction}

        {!!showSocial && <SocialDropdown user={user} />}

        {!hideViewProfile && (
          <MPActionButton
            href={ROUTES.PROFILE(user.username)}
            size="small"
            variant="secondary-black"
            LinkComponent={LinkComponent}
          >
            View Profile
          </MPActionButton>
        )}
      </div>
    </div>
  );
}

type StatType = {
  label: string;
  metThreshold: boolean;
  value: string;
};

const useUserStats = (
  user: Omit<UserType, 'featuredArtwork'>,
  hideDateJoined = false
): StatType[] => {
  const stats = useMemo(() => {
    const collectorStats: StatType[] = [
      {
        label: 'Collected Artworks',
        metThreshold:
          user.stats.collectedArtworks > THRESHOLDS.COLLECTED_ARTWORKS,
        value: toNumericShorthand(user.stats.collectedArtworks),
      },
      {
        label: 'Collected Artists',
        metThreshold:
          user.stats.collectedArtists > THRESHOLDS.COLLECTED_ARTISTS,
        value: toNumericShorthand(user.stats.collectedArtists),
      },
      {
        label: 'Followers',
        metThreshold: user.stats.followers > THRESHOLDS.AUDIENCE,
        value: toNumericShorthand(user.stats.followers),
      },
    ];

    const artistStats: StatType[] = [
      {
        label: 'Sales',
        metThreshold:
          user.totalVolume?.totalVolumeInUsd > THRESHOLDS.TOTAL_SALES,
        value: `$${toNumericShorthand(user.totalVolume?.totalVolumeInUsd)}`,
      },
      {
        label: 'Collectors',
        metThreshold: user.stats.collectors > THRESHOLDS.COLLECTORS,
        value: toNumericShorthand(user.stats.collectors),
      },
      {
        label: 'Created Artworks',
        metThreshold: user.stats.createdArtworks > THRESHOLDS.CREATED_ARTWORKS,
        value: toNumericShorthand(user.stats.createdArtworks),
      },
      {
        label: 'Exhibitions',
        metThreshold: user.stats.exhibitions > THRESHOLDS.EXHIBITIONS,
        value: toNumericShorthand(user.stats.exhibitions),
      },
    ];

    const curatorStats: StatType[] = [
      {
        label: 'Curated Exhibitions',
        metThreshold:
          user.stats.curatedExhibitions > THRESHOLDS.CURATED_EXHIBITIONS,
        value: toNumericShorthand(user.stats.curatedExhibitions),
      },
    ];

    return [
      ...(!hideDateJoined
        ? [
            {
              label: `Joined ${APP_NAME}`,
              metThreshold: true,
              value: new Date(user.createdAt).getFullYear().toString(),
            },
          ]
        : []),
      ...(user.isArtist ? artistStats : []),
      ...(user.isCurator ? curatorStats : []),
      ...collectorStats,
    ].filter((stat) => !!stat?.metThreshold);
  }, [user, hideDateJoined]);

  return stats;
};

function Stats({
  user,
  onClick,
}: UserProps & {
  onClick: () => void;
}) {
  const stats = useUserStats(user, true);
  const onEnter = useOnEnterKey(onClick);

  const { displayedStats, showMoreStats } = useMemo(
    () => ({
      displayedStats: stats.slice(0, MAX_STATS_FOR_CARD),
      showMoreStats: stats.length > MAX_STATS_FOR_CARD,
    }),
    [stats]
  );

  if (!stats.length) {
    return null;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={joinClasses(
        MPColorClass.CommonWhite,
        MPColorClass.SolidNeutralGray2,
        MPFonts.textSmallMedium,
        CSSGlobal.Flex.Col,
        CSSGap[10],
        styles.userStats
      )}
      onClick={onClick}
      onKeyDown={onEnter}
    >
      {displayedStats.map(({ label, value }) => (
        <div
          className={joinClasses(
            MPAnimations.Color.LightToDark,
            CSSGlobal.Flex.RowSpaceBetween
          )}
          key={label}
        >
          <span>{label}</span>
          <span className={MPColorClass.CommonWhite}>{value}</span>
        </div>
      ))}

      {!!showMoreStats && (
        <div
          className={joinClasses(
            MPAnimations.Color.LightToDark,
            CSSGlobal.Cursor.Pointer
          )}
        >
          View more stats
        </div>
      )}
    </div>
  );
}

interface ArtworkProps {
  nft: FeaturedArtworkType;
}

function Artwork({ nft }: ArtworkProps) {
  const isMobile = useIsMobile();
  const media = useMemo(
    () => (nft?.metadata ? getNFTPrimaryMedia(nft.metadata, isMobile) : null),
    [nft?.metadata, isMobile]
  );

  return media && nft?.listing ? (
    <Link
      to={ROUTES.NFT(nft.listing.productSlug)}
      className={styles.artworkContainer}
    >
      {nft.metadata.hasVideo ? (
        <video autoPlay className={styles.artwork} loop muted playsInline>
          <source
            src={withCloudinaryParams(nft.metadata.videoUrl, CLOUDINARY_PARAMS)}
            type="video/mp4"
          />
        </video>
      ) : isNFTDynamic(nft.metadata.rawfileExtension) ? (
        <Embed className={styles.artwork} media={media} />
      ) : (
        <Image
          className={styles.artwork}
          media={media}
          size={nft.metadata.mediaMetadata}
          isFullScreen={false}
          onClick={noop}
          onLoad={noop}
          cloudinaryParams={CLOUDINARY_PARAMS}
        />
      )}
    </Link>
  ) : (
    <div
      className={joinClasses(
        MPColorClass.SolidNeutralGray4,
        MPFonts.paragraphSmall,
        styles.artworkContainer
      )}
    >
      No Artworks Collected
    </div>
  );
}

enum AboutDialogState {
  Closed,
  OpenedDescription,
  OpenedStats,
}

interface HeroUserCardProps extends DescriptionProps {
  username: string;
  user?: UserType;
}

function BaseHeroUserCard({
  user,
  additionalAction,
  hideViewProfile,
  showSocial,
}: Omit<HeroUserCardProps, 'username'>) {
  const track = useHomepageGTM();
  const isMobile = useIsMobile();
  const [aboutDialogState, setAboutDialogState] = useState<AboutDialogState>(
    AboutDialogState.Closed
  );
  const [aboutDialogStatsRef, setAboutDialogStatsRef] =
    useRefState<HTMLDivElement>(null);
  const stats = useUserStats(user);

  const handleClick = useCallback(
    () => track.clickCard(CardType.HeroArtistCard, user.pk, user.fullName),
    [track, user.pk, user.fullName]
  );
  const handleOpenAboutDialogDescription = useCallback(() => {
    handleClick();
    setAboutDialogState(AboutDialogState.OpenedDescription);
  }, [handleClick]);
  const handleOpenAboutDialogStats = useCallback(() => {
    handleClick();
    setAboutDialogState(AboutDialogState.OpenedStats);
  }, [handleClick]);
  const handleCloseAboutDialog = useCallback(() => {
    setAboutDialogState(AboutDialogState.Closed);
    setAboutDialogStatsRef(null);
  }, [setAboutDialogStatsRef]);

  useEffect(() => {
    if (
      aboutDialogState !== AboutDialogState.OpenedStats ||
      !aboutDialogStatsRef
    )
      return;

    aboutDialogStatsRef.scrollIntoView();
  }, [aboutDialogState, aboutDialogStatsRef]);

  return (
    <>
      <HeroCard
        disableBrowserNavigate
        className={joinClasses(
          CSSGlobal.Cursor.Default,
          MPColorClass.CommonWhite,
          styles.container
        )}
        onClick={handleClick}
      >
        {isMobile ? (
          <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[32])}>
            <User user={user} />
            <Artwork nft={user.featuredArtwork} />
            <Description
              user={user}
              additionalAction={additionalAction}
              hideViewProfile={hideViewProfile}
              showSocial={showSocial}
              onClick={handleOpenAboutDialogDescription}
            />
            <Stats user={user} onClick={handleOpenAboutDialogStats} />
          </div>
        ) : (
          <>
            <div
              className={joinClasses(
                MPBackgroundColorClass.CommonBlack,
                styles.userRail
              )}
            >
              <div
                className={joinClasses(
                  CSSGlobal.Flex.ColCenterAlign,
                  styles.userContainer
                )}
              >
                <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[24])}>
                  <User user={user} />
                  <Description
                    user={user}
                    additionalAction={additionalAction}
                    hideViewProfile={hideViewProfile}
                    showSocial={showSocial}
                    onClick={handleOpenAboutDialogDescription}
                  />
                </div>
              </div>

              <Stats user={user} onClick={handleOpenAboutDialogStats} />
            </div>

            <Artwork nft={user.featuredArtwork} />
          </>
        )}
      </HeroCard>

      {aboutDialogState !== AboutDialogState.Closed && (
        <MPStandardDialog
          title={user.fullName}
          onClose={handleCloseAboutDialog}
        >
          <div
            className={joinClasses(
              CSSGlobal.Cursor.Default,
              CSSGlobal.Flex.Col,
              CSSGap[24]
            )}
          >
            {!!user.biography && (
              <>
                <div>
                  <div
                    className={joinClasses(
                      MPFonts.paragraphSmall,
                      styles.biographyParagraph
                    )}
                  >
                    {user.biography}
                  </div>
                </div>
                <MPDivider />
              </>
            )}

            <div
              ref={setAboutDialogStatsRef}
              className={joinClasses(CSSGlobal.Flex.Col, CSSGap[8])}
            >
              {stats.map(({ label, value }) => (
                <div
                  className={joinClasses(
                    MPFonts.textSmallMedium,
                    MPColorClass.SolidNeutralGray5,
                    CSSGlobal.Flex.RowSpaceBetween
                  )}
                  key={label}
                >
                  <span>{label}</span>
                  <span className={MPColorClass.CommonBlack}>{value}</span>
                </div>
              ))}
            </div>
          </div>
        </MPStandardDialog>
      )}
    </>
  );
}

export default function HeroUserCard(props: HeroUserCardProps) {
  return props.user ? (
    <BaseHeroUserCard {...props} />
  ) : (
    <DefaultErrorBoundary hideState errorFallback={null}>
      {withLoadQuery(
        ({
          userQuery,
        }: {
          userQuery: WithLoadQueryProps<AccountGetUserQuery>;
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const result = usePreloadedQuery<AccountGetUserQuery>(
            AccountGetUserQueryType,
            userQuery.queryRef
          );
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const user = useMemo(
            () => result.accounts?.edges?.[0]?.node,
            [result]
          );
          return user && <BaseHeroUserCard {...props} user={user} />;
        },
        {
          userQuery: { concreteRequest: AccountGetUserQueryType },
        }
      )({
        userQuery: {
          variables: { username: props.username },
        },
      })}
    </DefaultErrorBoundary>
  );
}
