/**
 * @generated SignedSource<<0a7e5f52ce8462f61b197c5588816ac9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityActionEnum = "SALE" | "TRANSFER" | "OFFER" | "MINT" | "BID" | "LISTING" | "SOLD" | "PURCHASE" | "RECEIVE_BID" | "PLACE_BID" | "REJECT_BID" | "RECEIVE_OFFER" | "PLACE_OFFER" | "REJECT_OFFER";
export type ActivitySortTypeEnum = "CREATED_AT" | "PRICE";
export type CurrencyModeEnum = "ETH" | "USD";
export type SortOrderEnum = "DESC" | "ASC";
export type DateTimeRangeType = {
  min?: any | null;
  max?: any | null;
};
export type PriceRangeType = {
  min?: number | null;
  max?: number | null;
};
export type PaginatedActivityNFTsQuery$variables = {
  actions?: ReadonlyArray<ActivityActionEnum | null> | null;
  activityDate?: DateTimeRangeType | null;
  collectors?: ReadonlyArray<string | null> | null;
  curators?: ReadonlyArray<string | null> | null;
  currencyMode?: CurrencyModeEnum | null;
  cursor?: string | null;
  first?: number | null;
  isSettings?: boolean | null;
  isShowFollowing?: boolean | null;
  price?: PriceRangeType | null;
  showFollowingCollectors?: boolean | null;
  sortOrder?: SortOrderEnum | null;
  sortType?: ActivitySortTypeEnum | null;
  storeCollectionSlug?: string | null;
  userNames?: ReadonlyArray<string | null> | null;
};
export type PaginatedActivityNFTsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActivityPaginatedFragment">;
};
export type PaginatedActivityNFTsQuery = {
  variables: PaginatedActivityNFTsQuery$variables;
  response: PaginatedActivityNFTsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "actions"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "activityDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collectors"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "curators"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currencyMode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSettings"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isShowFollowing"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "price"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showFollowingCollectors"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortOrder"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "storeCollectionSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userNames"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "actions",
    "variableName": "actions"
  },
  {
    "kind": "Variable",
    "name": "activityDate",
    "variableName": "activityDate"
  },
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "collectors",
    "variableName": "collectors"
  },
  {
    "kind": "Variable",
    "name": "curators",
    "variableName": "curators"
  },
  {
    "kind": "Variable",
    "name": "currencyMode",
    "variableName": "currencyMode"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isSettings",
    "variableName": "isSettings"
  },
  {
    "kind": "Variable",
    "name": "isShowFollowing",
    "variableName": "isShowFollowing"
  },
  {
    "kind": "Variable",
    "name": "price",
    "variableName": "price"
  },
  {
    "kind": "Variable",
    "name": "showFollowingCollectors",
    "variableName": "showFollowingCollectors"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "sortOrder"
  },
  {
    "kind": "Variable",
    "name": "sortType",
    "variableName": "sortType"
  },
  {
    "kind": "Variable",
    "name": "storeCollectionSlug",
    "variableName": "storeCollectionSlug"
  },
  {
    "kind": "Variable",
    "name": "userNames",
    "variableName": "userNames"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaginatedActivityNFTsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ActivityPaginatedFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaginatedActivityNFTsQuery",
    "selections": [
      {
        "alias": "results",
        "args": (v1/*: any*/),
        "concreteType": "ActivityConnection",
        "kind": "LinkedField",
        "name": "activities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "transactionUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "action",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceUsd",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceEth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isEthTransaction",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountAbstractType",
                    "kind": "LinkedField",
                    "name": "fromUser",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountAbstractType",
                    "kind": "LinkedField",
                    "name": "toUser",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTAbstractType",
                    "kind": "LinkedField",
                    "name": "digitalMediaRelease",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "printEdition",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTMetadataAbstractType",
                        "kind": "LinkedField",
                        "name": "metadata",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnailImage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalSupply",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountAbstractType",
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTListingAbstractType",
                        "kind": "LinkedField",
                        "name": "listing",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "productSlug",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "results",
        "args": (v1/*: any*/),
        "filters": [
          "storeCollectionSlug",
          "sortOrder",
          "sortType",
          "currencyMode",
          "actions",
          "price",
          "activityDate",
          "isShowFollowing",
          "userNames",
          "collectors",
          "curators",
          "showFollowingCollectors",
          "isSettings"
        ],
        "handle": "connection",
        "key": "PaginatedActivityNFTsQuery__results",
        "kind": "LinkedHandle",
        "name": "activities"
      }
    ]
  },
  "params": {
    "cacheID": "e79032cef23db741b6d039fe4f279f3d",
    "id": null,
    "metadata": {},
    "name": "PaginatedActivityNFTsQuery",
    "operationKind": "query",
    "text": "query PaginatedActivityNFTsQuery(\n  $actions: [ActivityActionEnum]\n  $activityDate: DateTimeRangeType\n  $collectors: [String]\n  $curators: [String]\n  $currencyMode: CurrencyModeEnum\n  $cursor: String\n  $first: Int\n  $isSettings: Boolean\n  $isShowFollowing: Boolean\n  $price: PriceRangeType\n  $showFollowingCollectors: Boolean\n  $sortOrder: SortOrderEnum\n  $sortType: ActivitySortTypeEnum\n  $storeCollectionSlug: String\n  $userNames: [String]\n) {\n  ...ActivityPaginatedFragment\n}\n\nfragment ActivityPaginatedFragment on Query {\n  results: activities(first: $first, after: $cursor, storeCollectionSlug: $storeCollectionSlug, sortOrder: $sortOrder, sortType: $sortType, currencyMode: $currencyMode, actions: $actions, price: $price, activityDate: $activityDate, isShowFollowing: $isShowFollowing, userNames: $userNames, collectors: $collectors, curators: $curators, showFollowingCollectors: $showFollowingCollectors, isSettings: $isSettings) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        transactionUrl\n        action\n        createdAt\n        priceUsd\n        priceEth\n        isEthTransaction\n        fromUser {\n          id\n          pk\n          username\n          fullName\n        }\n        toUser {\n          id\n          pk\n          username\n          fullName\n        }\n        digitalMediaRelease {\n          id\n          pk\n          printEdition\n          metadata {\n            id\n            pk\n            thumbnailImage\n            title\n            totalSupply\n            author {\n              pk\n              id\n              fullName\n            }\n          }\n          listing {\n            id\n            pk\n            productSlug\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f3dcfcb9a67eeaa0422c6890f51ec194";

export default node;
