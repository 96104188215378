import { RecoilState, selector, useRecoilValue } from 'recoil';
import { graphQLSelector } from 'recoil-relay';

import ConcreteNFTContractRequest, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import { ContractNameEnum } from 'types/__generated__/graphql';

import RelayEnvironment from '../../../RelayEnvironment';

export const ApprovedCreatorRegistryQuery: RecoilState<
  NFTContractQuery['response']['nftContract']
> = graphQLSelector({
  environment: RelayEnvironment,
  key: 'approvedCreator',
  mapResponse: (data) => data.nftContract,
  query: ConcreteNFTContractRequest,
  variables: () => ({ name: ContractNameEnum.ApprovedCreatorRegistry }),
});

const ParsedContract = selector({
  get: ({ get }) => {
    const contract = get(ApprovedCreatorRegistryQuery);
    return {
      abi: JSON.parse(contract.abidata).abi,
      abidata: contract.abidata,
      address: contract.address,
      symbol: contract.symbol,
      title: contract.title,
    };
  },
  key: 'parsedApprovedCreator',
});

export default function useApprovedCreatorRegistry() {
  return useRecoilValue(ParsedContract);
}
