import {
  forwardRef,
  HTMLProps,
  MouseEvent,
  MouseEventHandler,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MPIconButton, useIsMobile } from '@mp-frontend/core-components';
import { MuteIcon, UnmuteIcon } from '@mp-frontend/core-components/icons';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import useForceUpdate from 'hooks/useForceUpdate';
import useVideo from 'hooks/useVideo';

import * as styles from 'css/pages/product/ProductPreview.module.css';

const SHORT_VIDEO_DURATION_SECONDS = 5;
const MOBILE_CONTROLS_TIMEOUT_MS = 4000;

const hasAudio = (videoEl: HTMLVideoElement): boolean => {
  const el = videoEl as any;
  if (!el) return false;

  return !!(
    el.mozHasAudio ||
    el.webkitAudioDecodedByteCount ||
    el.audioTracks?.length
  );
};

interface PlayButtonProps {
  isPlaying: boolean;
  percentage: number;
  showProgress: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

function PlayButton({
  isPlaying,
  percentage,
  showProgress,
  onClick,
}: PlayButtonProps) {
  const r = 15;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100;

  return (
    <button
      type="button"
      aria-label={isPlaying ? 'Pause' : 'Play'}
      onClick={onClick}
    >
      <svg width={32} height={32}>
        {showProgress ? (
          <g transform="rotate(-90, 16, 16)">
            <circle
              r={r}
              cx={16}
              cy={16}
              fill="transparent"
              stroke={strokePct !== circ ? `currentColor` : ''}
              strokeWidth="1.9px"
              strokeDasharray={circ}
              strokeDashoffset={0}
            />
            <circle
              r={r}
              cx={16}
              cy={16}
              fill="transparent"
              stroke={strokePct !== circ ? 'var(--color-commonBlack)' : ''}
              strokeWidth="2.1px"
              strokeDasharray={circ}
              strokeDashoffset={percentage ? strokePct : 0}
            />
          </g>
        ) : null}

        {isPlaying ? (
          <>
            <rect
              x="11.7144"
              y="22"
              width="12"
              height="2.57143"
              transform="rotate(-90 11.7144 22)"
              fill="currentColor"
            />
            <rect
              x="17.7144"
              y="22"
              width="12"
              height="2.57143"
              transform="rotate(-90 17.7144 22)"
              fill="currentColor"
            />
          </>
        ) : (
          <path
            d="M13.7605 10.6345L21.3244 16.0006L13.7605 21.3667L13.7605 10.6345Z"
            fill="currentColor"
          />
        )}
      </svg>
    </button>
  );
}

export type VideoProps = Pick<HTMLProps<HTMLVideoElement>, 'src'> &
  Partial<{
    currentTime: number;
    height: number;
    isFullScreen: boolean;
    isMuted: boolean;
    isPlaying: boolean;
    onClick: (event: MouseEvent) => void;
    onLoaded: () => void;
    onMutedUpdate: (isMuted: boolean) => void;
    onPlayingUpdate: (isPlaying: boolean) => void;
    onTimeUpdate: (currentTime: number) => void;
    width: number;
    autoPlayOnLoad?: boolean;
  }>;

export default forwardRef(
  (
    {
      currentTime: currentTimeInitialValue,
      isPlaying: isPlayingInitialValue,
      isMuted: isMutedInitialValue,
      isFullScreen,
      src,
      onClick,
      onLoaded,
      onTimeUpdate,
      onPlayingUpdate,
      onMutedUpdate,
      height,
      width,
      autoPlayOnLoad = true,
    }: VideoProps,
    setForwardRef: (instance: HTMLVideoElement | null) => void
  ) => {
    const inViewVideo = useVideo({
      autoPlayOnLoad,
    });
    const isMobile = useIsMobile();
    const [ref, , setRef] = useRefState<HTMLVideoElement>(undefined);
    const [progress, setProgress] = useState<number>(0);
    const [durationSeconds, setDurationSeconds] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(isPlayingInitialValue);
    const [isMuted, setIsMuted] = useState<boolean>(isMutedInitialValue);
    const controlsTimeoutRef = useRef<number>(null);
    const [forceUpdate] = useForceUpdate();
    const [show, setShow] = useState<boolean>(true);

    const handleShowControls = useCallback((): void => {
      if (!ref || !isFullScreen || !isMobile) return;

      if (controlsTimeoutRef.current)
        window.clearTimeout(controlsTimeoutRef.current);

      controlsTimeoutRef.current = window.setTimeout(() => {
        controlsTimeoutRef.current = null;
        forceUpdate();
      }, MOBILE_CONTROLS_TIMEOUT_MS);
      forceUpdate();
    }, [ref, controlsTimeoutRef, isFullScreen, isMobile, forceUpdate]);

    const handlePlayToggleClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();
        if (!ref) return;

        if (ref.paused) {
          ref.play();
        } else {
          ref.pause();
        }
        handleShowControls();
      },
      [ref, handleShowControls]
    );

    const handleMuteToggleClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();
        if (!ref) return;

        ref.muted = !ref.muted;
        setIsMuted((prev) => !prev);
        handleShowControls();
      },
      [ref, handleShowControls]
    );

    const handleVideoTimeUpdate = useCallback(() => {
      if (!ref) return;

      setDurationSeconds(ref.duration);
      setProgress(((ref.currentTime / ref.duration) * 100) % 100);
      onTimeUpdate?.(ref.currentTime);
    }, [ref, onTimeUpdate]);

    const handleVideoPlay = useCallback(() => {
      setIsPlaying(true);
      onPlayingUpdate?.(true);
    }, [onPlayingUpdate]);

    const handleVideoPause = useCallback(() => {
      setIsPlaying(false);
      onPlayingUpdate?.(false);
    }, [onPlayingUpdate]);

    const handleVideoVolumeChange = useCallback(() => {
      if (!ref) return;

      setIsMuted(ref.muted);
      onMutedUpdate?.(ref.muted);
    }, [ref, onMutedUpdate]);

    const handleVideoClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();

        onClick?.(event);
        handleShowControls();
      },
      [onClick, handleShowControls]
    );

    const handleLoaded = useCallback(() => {
      inViewVideo.setLoaded();
      onLoaded?.();
    }, [inViewVideo, onLoaded]);

    useEffect(() => {
      setShow(false);
      startTransition(() => setShow(true));
    }, [src]);

    useEffect(() => {
      if (!ref) return undefined;

      if (currentTimeInitialValue) ref.currentTime = currentTimeInitialValue;

      ref.addEventListener('timeupdate', handleVideoTimeUpdate);
      ref.addEventListener('play', handleVideoPlay);
      ref.addEventListener('pause', handleVideoPause);
      ref.addEventListener('volumechange', handleVideoVolumeChange);

      return () => {
        ref.removeEventListener('timeupdate', handleVideoTimeUpdate);
        ref.removeEventListener('play', handleVideoPlay);
        ref.removeEventListener('pause', handleVideoPause);
        ref.removeEventListener('volumechange', handleVideoVolumeChange);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      ref,
      handleVideoTimeUpdate,
      handleVideoPlay,
      handleVideoPause,
      handleVideoVolumeChange,
    ]);

    useEffect(() => {
      if (!ref) return undefined;

      handleShowControls();

      return () => {
        if (controlsTimeoutRef.current) {
          window.clearTimeout(controlsTimeoutRef.current);
        }
      };
    }, [ref, controlsTimeoutRef, handleShowControls]);

    const VideoControls = (
      <div
        className={joinClasses(styles.videoControls, {
          [styles.visible]: !!(
            isMobile &&
            isFullScreen &&
            controlsTimeoutRef.current
          ),
        })}
      >
        <PlayButton
          onClick={handlePlayToggleClick}
          isPlaying={isPlaying}
          percentage={progress}
          showProgress={durationSeconds > SHORT_VIDEO_DURATION_SECONDS}
        />
        {hasAudio(ref) ? (
          <MPIconButton
            aria-label={isMuted ? 'Unmute' : 'Mute'}
            as="button"
            onClick={handleMuteToggleClick}
          >
            {isMuted ? <MuteIcon /> : <UnmuteIcon />}
          </MPIconButton>
        ) : null}
      </div>
    );

    return (
      <>
        <div className={styles.video} style={{ height, width }}>
          {!!show && (
            <video
              ref={(node) => {
                setRef(node);
                setForwardRef?.(node);
                inViewVideo.videoRef(node);
              }}
              autoPlay={isPlayingInitialValue}
              playsInline
              loop
              muted={isMuted}
              controls={false}
              onClick={handleVideoClick}
              onLoadedData={handleLoaded}
              onLoadedMetadata={handleLoaded}
              onMouseEnter={inViewVideo.onMouseEnterHandler}
              height={height}
              width={width}
            >
              <source src={src} type="video/mp4" />
            </video>
          )}

          {(!isMobile || !isFullScreen) && VideoControls}
        </div>

        {!!isFullScreen && !!isMobile && VideoControls}
      </>
    );
  }
);
