/**
 * @generated SignedSource<<b1169f6829cba6793a2adf757408a84d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GasRequestSpeedUpTxInCCArguments = {
  requestId: number;
  paymentIntentId?: string | null;
  paymentMethodId?: string | null;
  rememberCard?: boolean | null;
};
export type GasRequestSpeedUpByCCMutation$variables = {
  requestData: GasRequestSpeedUpTxInCCArguments;
};
export type GasRequestSpeedUpByCCMutation$data = {
  readonly gasRequestSpeedUpTxInCc: {
    readonly txid: string | null;
    readonly intentId: string | null;
    readonly success: boolean | null;
  } | null;
};
export type GasRequestSpeedUpByCCMutation = {
  variables: GasRequestSpeedUpByCCMutation$variables;
  response: GasRequestSpeedUpByCCMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "GasRequestSpeedUpTxInCCMutation",
    "kind": "LinkedField",
    "name": "gasRequestSpeedUpTxInCc",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "txid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "intentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GasRequestSpeedUpByCCMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GasRequestSpeedUpByCCMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e88bf2cecc5ed9d8cbd687cc4c28dbac",
    "id": null,
    "metadata": {},
    "name": "GasRequestSpeedUpByCCMutation",
    "operationKind": "mutation",
    "text": "mutation GasRequestSpeedUpByCCMutation(\n  $requestData: GasRequestSpeedUpTxInCCArguments!\n) {\n  gasRequestSpeedUpTxInCc(requestData: $requestData) {\n    txid\n    intentId\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8ee34fe1fea64d797fffb46df24f1a4";

export default node;
