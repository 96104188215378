import { useMemo } from 'react';

import { PaginatedExploreEditorialsQuery } from 'graphql/__generated__/PaginatedExploreEditorialsQuery.graphql';
import { UserSearchAccountTypesEnum } from 'types/__generated__/graphql';

import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import FiltersSection from 'components/filters/FilterSection';
import {
  SortEditorialsFilter,
  SortEditorialsFilterValue,
} from 'components/filters/SortFilter';
import UsersFilter from 'components/filters/UsersFilter';
import useFiltersGTM, { FilterType } from 'utils/GTM/filters';

export interface EditorialsFiltersState {
  query: string;
  sort: SortEditorialsFilterValue;
  usernames: Array<string>;
}

export const EDITORIALS_FILTERS_DEFAULT_STATE: EditorialsFiltersState = {
  query: '',
  sort: {},
  usernames: [],
};

export const useEditorialsFiltersQueryValues = (
  filters: EditorialsFiltersState
): PaginatedExploreEditorialsQuery['variables'] => {
  const usernames = useMemo(
    () => (filters.usernames?.length ? filters.usernames : undefined),
    [filters.usernames]
  );

  return {
    first: 9,
    query: filters.query,
    sortOrder: filters.sort?.order,
    sortType: filters.sort?.type,
    usernames,
  };
};

interface EditorialsFiltersProps {
  onChange: (state: Partial<EditorialsFiltersState>) => void;
  state: EditorialsFiltersState;
}

export default function Filters({ state, onChange }: EditorialsFiltersProps) {
  const { updateFilter } = useFiltersGTM();
  return (
    <FilterScrollContainer>
      <FiltersSection inline inlineMobile>
        <FiltersGroup>
          <FiltersGroup title="Sorting">
            <SortEditorialsFilter
              value={state.sort}
              onChange={(sort) => {
                onChange({ sort });
                updateFilter(FilterType.SortFilter, sort);
              }}
            />
          </FiltersGroup>
        </FiltersGroup>

        <FiltersGroup title="Filters">
          <UsersFilter
            value={{ userNames: state.usernames }}
            onChange={(value) => {
              onChange({ usernames: value.userNames });
              updateFilter(FilterType.ArtistsFilter, value);
            }}
            accountSearchType={UserSearchAccountTypesEnum.Artist}
            hideFollowing
          />
        </FiltersGroup>
      </FiltersSection>
    </FilterScrollContainer>
  );
}
