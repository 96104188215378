import { startTransition, useCallback, useState } from 'react';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import { NFTType } from 'types/graphql/NFT';

import { ProductOwnerStates } from '../../../ProductOwnerUpdateListing';
import FixedSale from '../Listing/FixedSale';
import ReservePrice from '../Listing/ReservePrice';

import * as updateListingStyles from 'css/pages/product/ProductOwnerUpdateListing.module.css';
import * as modalStyles from 'css/pages/product/ProductSetBuyNow.module.css';

interface ChooseListingTypeProps {
  invalidate: () => void;
  nft: NFTType;
  onClose: () => void;
}

function ChooseListingType({
  nft,
  invalidate,
  onClose,
}: ChooseListingTypeProps) {
  const [ownershipState, setOwnershipState] =
    useState<ProductOwnerStates>(undefined);
  const [selectedState, setSelectedState] =
    useState<ProductOwnerStates>(undefined);
  const selectFixedSale = useCallback(
    () =>
      startTransition(() => setSelectedState(ProductOwnerStates.FIXED_SALE)),
    []
  );
  const selectReserve = useCallback(
    () => startTransition(() => setSelectedState(ProductOwnerStates.RESERVE)),
    []
  );
  const resetOwnershipState = useCallback(
    () => setOwnershipState(undefined),
    []
  );
  const finalizeType = useCallback(
    () => startTransition(() => setOwnershipState(selectedState)),
    [selectedState]
  );

  const didCheckSaleContractStatusState = useState(false);

  return (
    <>
      <StackStateDialog
        title="Sell Artwork"
        onClose={onClose}
        actionButton={
          <MPActionButton
            fullWidth
            onClick={finalizeType}
            disabled={!selectedState}
            size="large"
          >
            Continue
          </MPActionButton>
        }
      >
        <div className={modalStyles.productSellMessageContainer}>
          <div className={MPFonts.textNormalRegular}>
            Choose how you would like to list your artwork
          </div>
          <div className={updateListingStyles.productListingSelectionContainer}>
            <div
              className={joinClasses(
                updateListingStyles.productListingSelection,
                updateListingStyles.projSettings,
                selectedState === ProductOwnerStates.FIXED_SALE
                  ? updateListingStyles.productListingSelectionSelected
                  : ''
              )}
              onClick={selectFixedSale}
              onKeyPress={selectFixedSale}
              role="button"
              tabIndex={0}
            >
              <div className={MPFonts.textNormalMedium}>Buy Now</div>
              <div
                className={joinClasses(
                  MPFonts.textSmallRegular,
                  updateListingStyles.productListingSelectionDescription
                )}
              >
                Set a fixed price
              </div>
            </div>
            <div
              className={joinClasses(
                updateListingStyles.productListingSelection,
                updateListingStyles.projSettings,
                selectedState === ProductOwnerStates.RESERVE
                  ? updateListingStyles.productListingSelectionSelected
                  : ''
              )}
              onClick={selectReserve}
              onKeyPress={selectReserve}
              role="button"
              tabIndex={0}
            >
              <div className={MPFonts.textNormalMedium}>Reserve</div>
              <div
                className={joinClasses(
                  MPFonts.textSmallRegular,
                  updateListingStyles.productListingSelectionDescription
                )}
              >
                Set a price with a timer
              </div>
            </div>
          </div>
        </div>
        {ownershipState === ProductOwnerStates.FIXED_SALE && (
          <FixedSale
            close={resetOwnershipState}
            nft={nft}
            invalidate={invalidate}
            didCheckSaleContractStatusState={didCheckSaleContractStatusState}
          />
        )}
        {ownershipState === ProductOwnerStates.RESERVE && (
          <ReservePrice
            close={resetOwnershipState}
            nft={nft}
            invalidate={invalidate}
          />
        )}
      </StackStateDialog>
    </>
  );
}

export default function NFTListAction({ nft, invalidate }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const open = useCallback(() => setIsDialogOpen(true), []);
  const close = useCallback(() => setIsDialogOpen(false), []);

  return (
    <>
      <MPActionButton variant="tertiary" onClick={open}>
        Sell&nbsp;Artwork
      </MPActionButton>
      {!!isDialogOpen && (
        <ChooseListingType nft={nft} invalidate={invalidate} onClose={close} />
      )}
    </>
  );
}
