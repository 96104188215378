/**
 * @generated SignedSource<<3718ebc6c602d3c7c84bff2a3970360f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TransferPortalSendStatusEnum = "SAFEBOX" | "CUSTODIAL" | "PENDING" | "SENT" | "PERSONAL";
export type TransferPortalSendTypeEnum = "SAFEBOX" | "CUSTODIAL" | "PERSONAL";
export type SettingsSendableNftsQuery$variables = {};
export type SettingsSendableNftsQuery$data = {
  readonly sendableNfts: ReadonlyArray<{
    readonly nft: {
      readonly id: string;
      readonly pk: string;
      readonly printEdition: number;
      readonly listing: {
        readonly productSlug: string;
      } | null;
      readonly metadata: {
        readonly hasVideo: boolean | null;
        readonly highResImage: string | null;
        readonly id: string;
        readonly rawfileExtension: string;
        readonly standardImage: string | null;
        readonly title: string;
        readonly thumbnailImage: string | null;
        readonly totalSupply: number;
        readonly videoUrl: string | null;
        readonly author: {
          readonly fullName: string | null;
        } | null;
      } | null;
    } | null;
    readonly status: TransferPortalSendStatusEnum;
    readonly type: TransferPortalSendTypeEnum;
  } | null> | null;
};
export type SettingsSendableNftsQuery = {
  variables: SettingsSendableNftsQuery$variables;
  response: SettingsSendableNftsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printEdition",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productSlug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasVideo",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highResImage",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawfileExtension",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "standardImage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailImage",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSupply",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoUrl",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsSendableNftsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SendableNFTType",
        "kind": "LinkedField",
        "name": "sendableNfts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractType",
            "kind": "LinkedField",
            "name": "nft",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTListingAbstractType",
                "kind": "LinkedField",
                "name": "listing",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTMetadataAbstractType",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v0/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountAbstractType",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsSendableNftsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SendableNFTType",
        "kind": "LinkedField",
        "name": "sendableNfts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractType",
            "kind": "LinkedField",
            "name": "nft",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTListingAbstractType",
                "kind": "LinkedField",
                "name": "listing",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTMetadataAbstractType",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v0/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountAbstractType",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c605908e9533719dbfc0e7c6fe2d1c8",
    "id": null,
    "metadata": {},
    "name": "SettingsSendableNftsQuery",
    "operationKind": "query",
    "text": "query SettingsSendableNftsQuery {\n  sendableNfts {\n    nft {\n      id\n      pk\n      printEdition\n      listing {\n        productSlug\n        id\n      }\n      metadata {\n        hasVideo\n        highResImage\n        id\n        rawfileExtension\n        standardImage\n        title\n        thumbnailImage\n        totalSupply\n        videoUrl\n        author {\n          fullName\n          id\n        }\n      }\n    }\n    status\n    type\n  }\n}\n"
  }
};
})();

(node as any).hash = "43ee48f29926ea8e0acc558601109467";

export default node;
