/**
 * @generated SignedSource<<0cb6daa9ac4240529b8f9d64f8fa9433>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExploreEditorialsPaginatedFragment$data = {
  readonly editorial: {
    readonly results: {
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly endCursor: string | null;
      };
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly author: {
            readonly biography: string | null;
            readonly followerCount: number;
            readonly fullName: string | null;
            readonly id: string;
            readonly pk: string;
            readonly profileImageUrl: string;
            readonly totalVolume: {
              readonly totalVolumeInEth: number | null;
              readonly totalVolumeInUsd: number | null;
            } | null;
            readonly username: string;
          } | null;
          readonly backgroundImageUrl: string;
          readonly description: string;
          readonly id: string;
          readonly pageUrl: string;
          readonly pk: string;
          readonly slug: string;
          readonly title: string | null;
          readonly type: string | null;
        } | null;
        readonly cursor: string;
      } | null>;
    } | null;
  } | null;
  readonly " $fragmentType": "ExploreEditorialsPaginatedFragment";
};
export type ExploreEditorialsPaginatedFragment$key = {
  readonly " $data"?: ExploreEditorialsPaginatedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExploreEditorialsPaginatedFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "editorial",
  "results"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "query"
    },
    {
      "kind": "RootArgument",
      "name": "sortOrder"
    },
    {
      "kind": "RootArgument",
      "name": "sortType"
    },
    {
      "kind": "RootArgument",
      "name": "usernames"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PaginatedExploreEditorialsQuery.graphql')
    }
  },
  "name": "ExploreEditorialsPaginatedFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExploreEditorialQuery",
      "kind": "LinkedField",
      "name": "editorial",
      "plural": false,
      "selections": [
        {
          "alias": "results",
          "args": [
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "query"
            },
            {
              "kind": "Variable",
              "name": "sortOrder",
              "variableName": "sortOrder"
            },
            {
              "kind": "Variable",
              "name": "sortType",
              "variableName": "sortType"
            },
            {
              "kind": "Variable",
              "name": "usernames",
              "variableName": "usernames"
            }
          ],
          "concreteType": "EditorialConnection",
          "kind": "LinkedField",
          "name": "__PaginatedExploreEditorialsQuery__results_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EditorialEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModularPageType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AccountAbstractType",
                      "kind": "LinkedField",
                      "name": "author",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "biography",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "followerCount",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fullName",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profileImageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TotalVolumeType",
                          "kind": "LinkedField",
                          "name": "totalVolume",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "totalVolumeInEth",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "totalVolumeInUsd",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "backgroundImageUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pageUrl",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a4540923b4970588f9599c0c6c4e703a";

export default node;
