import * as styles from 'css/global/Margin.module.css';

const CSSMargin = {
  BOTTOM: {
    '10': styles.marginBottom10,
    '16': styles.marginBottom16,
    '20': styles.marginBottom20,
    '24': styles.marginBottom24,
    '32': styles.marginBottom32,
    '4': styles.marginBottom4,
    '6': styles.marginBottom6,
    '8': styles.marginBottom8,
  } as const,
  LEFT: {
    '16': styles.marginLeft16,
    '2': styles.marginLeft2,
  } as const,
  RIGHT: {
    '4': styles.marginRight4,
  } as const,
  TOP: {
    '16': styles.marginTop16,
    '24': styles.marginTop24,
    '32': styles.marginTop32,
  } as const,
} as const;

export default CSSMargin;
