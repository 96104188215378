.containerWithPill {
  height: 100%;
  overflow: hidden;
}

.subsectionWithoutPill {
  overflow: auto;
  padding: 20px 24px 0;
  height: calc(100% - 69px);
  box-sizing: border-box;
}

.pills {
  height: 29px;
  padding-bottom: 20px;
}
