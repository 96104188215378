import {
  startTransition,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useMutation } from 'react-relay';
import { Hash } from 'viem';
import { useAccount } from 'wagmi';
import * as Sentry from '@sentry/react';

import CreateSaleWithPersonalWalletMutation from 'graphql/__generated__/ListingCreateSaleWithPersonalWalletMutation.graphql';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import WalletActionButton from 'components/WalletActionButton';
import useDigitalMediaSaleCoreContract from 'hooks/contracts/useDigitalMediaSaleCoreContract';
import { useHandleSetETH } from 'hooks/pricing/useMax';
import useSaleContract from 'hooks/useSaleContract';
import usePublicClient from 'hooks/useWeb3Client';
import ProductPendingOnChain from 'pages/product/ProductPendingOnChain';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';
import getSafeBigNumberFromUserInput from 'utils/getSafeBigNumberFromUserInput';

import Listing from '..';
import Congratulations from './Congratulations';

function PendingListing({ nft, currentTransactionHash, closeAndInvalidate }) {
  const queryVariables = useMemo(
    () => ({ txtId: currentTransactionHash }),
    [currentTransactionHash]
  );
  const [finishedListing, setFinishedListing] = useState(false);
  const onSuccess = useCallback(() => setFinishedListing(true), []);
  return (
    <StackStateDialog
      title={
        nft.listing.liveSale
          ? 'Editing Price Pending'
          : 'Listing Artwork Pending'
      }
      shouldBreak
    >
      <Suspense>
        <ProductPendingOnChain
          queryVariables={queryVariables}
          onSuccess={onSuccess}
          nft={nft}
        />
      </Suspense>
      {!!finishedListing && (
        <Congratulations
          onClose={closeAndInvalidate}
          didList={nft.listing.liveSale}
        />
      )}
    </StackStateDialog>
  );
}

interface CustodialListingProps {
  close: () => void;
  invalidate: () => void;
  nft: NFTType;
}

function zeroToBlank(n: number): string {
  return n ? n.toString() : '';
}

export default function WalletListing({
  nft,
  close,
  invalidate,
}: CustodialListingProps) {
  const [buyNowPrice, setBuyNowPrice] = useState(
    zeroToBlank(nft.listing.lowestAskInEth)
  );
  const [, handleSetBuyNowUSD] = useHandleSetETH(setBuyNowPrice);
  const [isValidating, setIsValidating] = useState(false);
  const saleContract = useSaleContract(nft);
  const [commitCreateSaleWithPersonalWalletMutation] = useMutation(
    CreateSaleWithPersonalWalletMutation
  );
  const provider = usePublicClient();
  const { address: currentUserAddress } = useAccount();
  const { useCreateSale } = useDigitalMediaSaleCoreContract({
    abi: JSON.parse(saleContract.abidata).abi,
    contractAddress: saleContract.address as Hash,
  });
  const [currentTransactionHash, setCurrentTransactionHash] =
    useState<string>(null);

  const { mutate: createSale } = useCreateSale({
    acceptFiat: nft.currentOwner.store.acceptsCreditCard,
    price: getSafeBigNumberFromUserInput(buyNowPrice).toBigInt(),
    tokenAddress: nft.contract.address as Hash,
    tokenId: parseInt(nft.onchainId, 10),
  });

  const [error, setError] = useState<Error>(undefined);

  const createListingWithPersonalWallet = useCallback(
    async ({ fromAddress, nonce, price, releaseId, transactionId }) =>
      new Promise((resolve, reject) => {
        setError(undefined);
        commitCreateSaleWithPersonalWalletMutation({
          onCompleted: (data: any) => {
            resolve(data);
          },
          onError(err) {
            setError(err);
            reject(err);
          },
          variables: {
            request_data: {
              fromAddress,
              nonce,
              price,
              releaseId,
              transactionId,
            },
          },
        });
      }),
    [commitCreateSaleWithPersonalWalletMutation]
  );

  const confirmPersonalListing = useCallback(async () => {
    try {
      setIsValidating(true);
      const transactionResult = await createSale.writeAsync();
      const nonce =
        (await provider.getTransactionCount(currentUserAddress)) + 1;
      await createListingWithPersonalWallet({
        fromAddress: currentUserAddress,
        nonce: nonce.toString(),
        price: buyNowPrice,
        releaseId: nft.pk,
        transactionId: transactionResult,
      });
      startTransition(() => setCurrentTransactionHash(transactionResult));
    } catch (err) {
      createSale.reset();
      setError(err);
      err.errorContext = { nftId: nft.pk };
      Sentry.captureException(err);
    }
    setIsValidating(false);
    createSale.reset();
  }, [
    buyNowPrice,
    currentUserAddress,
    createListingWithPersonalWallet,
    provider,
    createSale,
    nft.pk,
  ]);

  const closeAndInvalidate = useCallback(() => {
    startTransition(() => {
      invalidate();
      close();
    });
  }, [close, invalidate]);

  const isActionDisabled =
    isValidating ||
    (parseFloat(buyNowPrice) || 0) === 0 ||
    (!!nft.listing.lowestAskInUsd &&
      nft.listing.lowestAskInUsd.toString() === buyNowPrice);
  const actionButtonJSX = useMemo(
    () => (
      <WalletActionButton
        fullWidth
        onClick={confirmPersonalListing}
        warnIfAddressNotRegistered
        disabled={isActionDisabled}
        size="large"
      >
        {nft.listing.liveSale ? 'Confirm' : 'List Artwork'}
      </WalletActionButton>
    ),
    [confirmPersonalListing, nft.listing.liveSale, isActionDisabled]
  );

  return (
    <StackStateDialog
      title={nft.listing.liveSale ? 'Edit Price' : 'Sell Artwork'}
      onClose={close}
      actionButton={actionButtonJSX}
    >
      <Listing
        nft={nft}
        error={error}
        currencyDisplay={CurrencyDisplayMode.ETH}
        price={buyNowPrice}
        handleSetPrice={handleSetBuyNowUSD}
      />
      {!!currentTransactionHash && (
        <PendingListing
          closeAndInvalidate={closeAndInvalidate}
          nft={nft}
          currentTransactionHash={currentTransactionHash}
        />
      )}
    </StackStateDialog>
  );
}
