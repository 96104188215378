import { forwardRef, KeyboardEvent, useCallback, useMemo } from 'react';
import { merge } from 'lodash';
import { inputBaseClasses, outlinedInputClasses } from '@mui/material';

import {
  MPColorValue,
  MPFonts,
  MPTextField,
} from '@mp-frontend/core-components';
import { SearchIcon } from '@mp-frontend/core-components/icons';
import { joinClasses, KeyboardEventKey } from '@mp-frontend/core-utils';

import { APP_NAME } from 'constants/Utils';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/input/SearchInput.module.css';

interface SearchInputProps {
  onChange: (value: string) => void;
  setValue: (value: string) => void;
  value: string;
  variant: 'light' | 'dark';
  className?: string;
  placeholder?: string;
}

const SearchInput = forwardRef(
  (
    {
      className,
      value,
      variant = 'light',
      setValue,
      onChange,
      placeholder = `Search ${APP_NAME}`,
    }: SearchInputProps,
    ref
  ) => {
    const color = MPColorValue.SolidNeutralGray3;
    const sx = useMemo(
      () => ({
        [` .${inputBaseClasses.root}.${outlinedInputClasses.root}`]: merge(
          {},
          {
            borderRadius: '100px',
            color,
            padding: '8px 16px',
          },
          {
            dark: {
              backgroundColor: MPColorValue.BackgroundDefault,
              borderColor: MPColorValue.BackgroundDefault,
            },
            light: {
              backgroundColor: MPColorValue.CommonWhite,
              borderColor: MPColorValue.CommonWhite,
            },
          }[variant]
        ),
      }),
      [color, variant]
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
          case KeyboardEventKey.tab:
            event.preventDefault();
            break;
          case KeyboardEventKey.enter:
            event.preventDefault();
            onChange((event.target as HTMLInputElement).value);
            break;
          default:
            break;
        }
      },
      [onChange]
    );

    return (
      <MPTextField
        autoFocus
        className={joinClasses(styles.field, className)}
        endAdornment={
          <SearchIcon
            className={joinClasses(CSSGlobal.Cursor.Default, color)}
            fontSize="21"
          />
        }
        inputClassName={joinClasses(MPFonts.textNormalRegular, styles.input)}
        inputRef={ref}
        label=""
        placeholder={placeholder}
        showFocusedPlaceholder
        sx={sx}
        tabIndex={-1}
        value={value}
        setValue={setValue}
        onKeyDown={handleKeyDown}
      />
    );
  }
);

export default SearchInput;
