import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const ArrowUpIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9931 17.13L11.9931 11.3787L5.99307 17.13L3.63605 14.8707L11.9931 6.86L20.3501 14.8707L17.9931 17.13Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default ArrowUpIcon;
