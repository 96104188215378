import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const ArrowDownIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25633 6.87L12 12.6137L17.7437 6.87L20 9.12633L12 17.1263L4 9.12633L6.25633 6.87Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default ArrowDownIcon;
