/**
 * @generated SignedSource<<fd7a4003bd50ebd2932d8c20c3a10e96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendNFTFromCustodialWalletArguments = {
  nftId: number;
  recipientAddress: `0x${string}`;
};
export type NFTSendFromCustodialWalletMutation$variables = {
  requestData: SendNFTFromCustodialWalletArguments;
};
export type NFTSendFromCustodialWalletMutation$data = {
  readonly sendNftFromCustodialWallet: {
    readonly success: boolean | null;
    readonly gasId: number | null;
    readonly sendId: number | null;
  } | null;
};
export type NFTSendFromCustodialWalletMutation = {
  variables: NFTSendFromCustodialWalletMutation$variables;
  response: NFTSendFromCustodialWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "SendNFTFromCustodialWalletMutation",
    "kind": "LinkedField",
    "name": "sendNftFromCustodialWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gasId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sendId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTSendFromCustodialWalletMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NFTSendFromCustodialWalletMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c570daca70b5a5a907c3c6d4ee6af8a6",
    "id": null,
    "metadata": {},
    "name": "NFTSendFromCustodialWalletMutation",
    "operationKind": "mutation",
    "text": "mutation NFTSendFromCustodialWalletMutation(\n  $requestData: SendNFTFromCustodialWalletArguments!\n) {\n  sendNftFromCustodialWallet(requestData: $requestData) {\n    success\n    gasId\n    sendId\n  }\n}\n"
  }
};
})();

(node as any).hash = "b05801f620733c747ceb159cc0901045";

export default node;
