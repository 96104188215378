import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  MPActionButton,
  MPChip,
  MPChipInlineSearchInput,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import SettingsCollectionNFTsPaginatedFragmentRequest, {
  SettingsCollectionNFTsPaginatedFragment$key,
} from 'graphql/__generated__/SettingsCollectionNFTsPaginatedFragment.graphql';
import SettingsCollectionNFTsPaginatedRequest, {
  SettingsCollectionNFTsPaginatedQuery,
} from 'graphql/__generated__/SettingsCollectionNFTsPaginatedQuery.graphql';
import {
  OwnershipEnum,
  SettingsCollectionSortTypeEnum,
  SortOrderEnum,
} from 'types/__generated__/graphql';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import FiltersSection from 'components/filters/FilterSection';
import SortFilter from 'components/filters/SortFilter';
import { useToggleFilter } from 'components/filters/ToggleFilter';
import { useArtistsFilter } from 'components/filters/UsersFilter';
import useLoadQuery from 'hooks/useLoadQuery';
import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import useSetDocTitle from '../useSetDocTitle';
import SetMinOffer from './collection/SetMinOffer';
import useOwnershipFilter from './collection/useOwnershipFilter';
import SettingsGrid from './Grid';
import { CollectionPageUrlParamEnum } from './UrlParamEnum';

import * as gridStyles from 'css/components/grid/ThreeItemGrid.module.css';
import * as pageStyles from 'css/pages/settings/collection/index.module.css';

function CollectionInner({ queryRef, hasFilters }) {
  const session = useSession();

  return (
    <div
      className={joinClasses(
        pageStyles.threeItemGridMinHeight,
        gridStyles.gridMinHeight
      )}
    >
      {queryRef ? (
        <DefaultErrorBoundary>
          <SettingsGrid<
            SettingsCollectionNFTsPaginatedFragment$key,
            SettingsCollectionNFTsPaginatedQuery
          >
            queryRef={queryRef}
            fragmentRequest={SettingsCollectionNFTsPaginatedFragmentRequest}
            paginatedRequest={SettingsCollectionNFTsPaginatedRequest}
            unFilteredMessage={
              session.isCreator()
                ? 'You have not created or collected any artworks yet'
                : 'You have not collected any artworks yet'
            }
            isFiltered={hasFilters}
          />
        </DefaultErrorBoundary>
      ) : null}
    </div>
  );
}

const SEARCH_KEY = 'CST';

enum SortTypeEnum {
  SETTINGS_COLLECTION = 'SETTINGS_COLLECTION',
}

const Label = {
  [`${SortTypeEnum.SETTINGS_COLLECTION}-${SortOrderEnum.Desc}`]: 'Newest',
  [`${SortTypeEnum.SETTINGS_COLLECTION}-${SortOrderEnum.Asc}`]: 'Oldest',
};

function useCollectionSortFilter(urlParam): [SortOrderEnum, JSX.Element] {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSortOrder = searchParams.get(urlParam)
    ? SortOrderEnum.Asc
    : SortOrderEnum.Desc;

  const [orderValue, setOrderValue] = useState<SortOrderEnum>(urlSortOrder);
  const value = useMemo(
    () => ({ order: orderValue, type: SortTypeEnum.SETTINGS_COLLECTION }),
    [orderValue]
  );

  useEffect(() => {
    if (!!urlParam && urlSortOrder !== orderValue) {
      if (orderValue === SortOrderEnum.Asc) {
        searchParams.set(urlParam, '1');
      } else {
        searchParams.delete(urlParam);
      }
      setSearchParams(searchParams, { replace: true });
    }
  }, [urlParam, setSearchParams, searchParams, orderValue, urlSortOrder]);

  return [
    orderValue,
    <SortFilter<SortTypeEnum, SortOrderEnum>
      config={Label}
      value={value}
      onChange={(val) => setOrderValue(val.order)}
    />,
  ];
}

export default function Collection() {
  useSetDocTitle('My Collection');
  const session = useSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortOrder, sortFilterJSX] = useCollectionSortFilter(
    CollectionPageUrlParamEnum.CollectionSortOrder
  );
  const [
    artistsFilterState,
    hasArtistsFilter,
    clearArtistFilter,
    ArtistsChipJSX,
    ArtistFilterJSX,
  ] = useArtistsFilter();
  const [searchTerm, setSearchTerm] = useState(
    searchParams.get(SEARCH_KEY) || ''
  );
  const resetSearchTerm = useCallback(() => setSearchTerm(''), []);
  const [searchTermChip, setSearchTermChip] = useState('');
  const [isListedForSaleJSX, isListedForSale] = useToggleFilter(
    'Listed For Sale',
    CollectionPageUrlParamEnum.CollectedListed
  );
  const [hasOffersBidsJSX, hasOffersBids] = useToggleFilter(
    'Has Offers/Bids',
    CollectionPageUrlParamEnum.ColectionOfferBids
  );
  const [ownershipJSX, ownership] = useOwnershipFilter(
    CollectionPageUrlParamEnum.CollectionOwnership
  );

  const urlSearchTerm = searchParams.get(SEARCH_KEY);
  useEffect(() => {
    if (urlSearchTerm !== searchTerm) {
      if (searchTerm) {
        searchParams.set(SEARCH_KEY, searchTerm);
        setSearchParams(searchParams, { replace: true });
      } else if (urlSearchTerm) {
        searchParams.delete(SEARCH_KEY);
        setSearchParams(searchParams, { replace: true });
      }
    }
  }, [searchParams, setSearchParams, urlSearchTerm, searchTerm]);

  const artists = useMemo(
    () => ({
      showFollowingArtists: artistsFilterState.isShowFollowing,
      usernames: artistsFilterState.userNames,
    }),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [
      artistsFilterState.isShowFollowing,
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
      artistsFilterState.userNames.join('=='),
    ]
  );

  const [queryRef] = useLoadQuery<SettingsCollectionNFTsPaginatedQuery>(
    SettingsCollectionNFTsPaginatedRequest,
    {
      artists,
      authorSlug:
        ownership === OwnershipEnum.Created
          ? session.account.username
          : undefined,
      collectorSlug:
        ownership === OwnershipEnum.Collected
          ? session.account.username
          : undefined,
      first: 6,
      hasBidOffer: hasOffersBids || undefined,
      listedForSale: isListedForSale || undefined,

      query: searchTerm.trim() === '' ? undefined : searchTerm,
      sortOrder,
      sortType: SettingsCollectionSortTypeEnum.HighestBidPrice,
    },
    'store-and-network'
  );
  const hasFilters =
    artistsFilterState.isShowFollowing ||
    artistsFilterState.userNames.length ||
    hasOffersBids ||
    isListedForSale ||
    ownership ||
    searchTerm.trim();

  const reset = useCallback(() => {
    clearArtistFilter();
    resetSearchTerm();
  }, [clearArtistFilter, resetSearchTerm]);

  return (
    <div
      className={joinClasses(
        gridStyles.container,
        CSSGlobal.Flex.Col,
        CSSGap[16]
      )}
    >
      <FilterScrollContainer>
        <FiltersSection inline inlineMobile>
          <FiltersGroup>
            <SetMinOffer />
          </FiltersGroup>
          <FiltersGroup>
            <MPChipInlineSearchInput
              placeholder="Search Your Artworks"
              value={searchTerm}
              onChange={useCallback((e) => {
                setSearchTerm(e.target.value);
              }, [])}
              onBlur={useCallback((e) => setSearchTermChip(e.target.value), [])}
            />
          </FiltersGroup>
          <FiltersGroup>
            <FiltersGroup title="Sorting">{sortFilterJSX}</FiltersGroup>
          </FiltersGroup>
          <FiltersGroup title="Filters">
            <FiltersGroup>
              <>
                {isListedForSaleJSX}
                {hasOffersBidsJSX}
                {ownershipJSX}
              </>
            </FiltersGroup>
            <FiltersGroup>{ArtistFilterJSX}</FiltersGroup>
          </FiltersGroup>
        </FiltersSection>
      </FilterScrollContainer>
      {!!(hasArtistsFilter || searchTermChip.trim() !== '') && (
        <FilterScrollContainer>
          {searchTermChip.trim() !== '' && (
            <MPChip label={searchTermChip} onDelete={resetSearchTerm} />
          )}
          {ArtistsChipJSX}{' '}
          <MPActionButton
            className={CSSGlobal.Width.MaxContent}
            variant="tertiary"
            onClick={reset}
          >
            Clear All
          </MPActionButton>
        </FilterScrollContainer>
      )}
      <CollectionInner queryRef={queryRef} hasFilters={!!hasFilters} />
    </div>
  );
}
