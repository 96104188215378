import { useRef } from 'react';
import moment from 'moment-timezone';
import { usePaginationFragment, usePreloadedQuery } from 'react-relay';

import {
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ActivityPaginatedFragmentNode, {
  ActivityPaginatedFragment$data,
  ActivityPaginatedFragment$key,
} from 'graphql/__generated__/ActivityPaginatedFragment.graphql';
import ActivityPaginatedQueryType, {
  ActivityPaginatedQuery,
} from 'graphql/__generated__/ActivityPaginatedQuery.graphql';

import { DefaultLoader } from 'components/DefaultSuspense';
import useInfiniteQueryScroll from 'hooks/useInfiniteQueryScroll';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import ActivityCard from './card/ActivityCard';
import ActivityCardHeader from './card/ActivityCardHeader';
import { ACTIVITY_PER_PAGE } from './ActivityPage';

import * as styles from 'css/pages/activity/ActivityPage.module.css';

type ActivityType =
  ActivityPaginatedFragment$data['results']['edges'][number]['node'];

interface ActivityPageContainerProps {
  activityQuery: WithLoadQueryProps<ActivityPaginatedQuery>;
  cardClassName?: string;
  className?: string;
  hasInfiniteScroll?: boolean;
  noResultsReplaceJSX?: JSX.Element;
  showActivityHeader?: boolean;
  showCollector?: boolean;
}

export function ActivityPageTable({
  activityQuery,
  hasInfiniteScroll = true,
  showActivityHeader = true,
  showCollector = false,
  className,
  cardClassName,
  noResultsReplaceJSX,
}: ActivityPageContainerProps) {
  const visibilityRef = useRef(null);
  const scrollRef = useRef(null);
  const isMobile = useIsMobile();
  const { data: activities, loading } = useInfiniteQueryScroll({
    getConnectionField: (data) => data.results,
    pageSize: ACTIVITY_PER_PAGE,
    paginatedQueryResults: usePaginationFragment<
      ActivityPaginatedQuery,
      ActivityPaginatedFragment$key
    >(
      ActivityPaginatedFragmentNode,
      usePreloadedQuery<ActivityPaginatedQuery>(
        ActivityPaginatedQueryType,
        activityQuery.queryRef
      )
    ),
    ref: visibilityRef,
    scrollRef,
  });

  return (
    <div ref={scrollRef} className={joinClasses(className, styles.table)}>
      {activities.length ? (
        <>
          {!isMobile && !!showActivityHeader && (
            <ActivityCardHeader showCollector={showCollector} />
          )}

          {activities.map((activity: ActivityType) => (
            <ActivityCard
              key={activity.id}
              className={joinClasses(cardClassName, styles.card)}
              imageSrc={activity.digitalMediaRelease.metadata.thumbnailImage}
              title={activity.digitalMediaRelease.metadata.title}
              artistName={activity.digitalMediaRelease.metadata.author.fullName}
              collectorName={activity.toUser?.fullName}
              type={activity.action}
              priceEth={activity.priceEth}
              priceUsd={activity.priceUsd}
              timeAgo={moment(activity.createdAt).fromNow()}
              productSlug={activity.digitalMediaRelease.listing?.productSlug}
              editionInfo={`${activity.digitalMediaRelease.printEdition} of ${activity.digitalMediaRelease.metadata.totalSupply}`}
              currencyDisplayMode={
                activity.isEthTransaction
                  ? CurrencyDisplayMode.ETH
                  : CurrencyDisplayMode.USD
              }
              showCollector={showCollector}
            />
          ))}
        </>
      ) : (
        noResultsReplaceJSX || (
          <div
            className={joinClasses(
              MPFonts.textLargeRegular,
              MPColorClass.SolidNeutralGray5,
              CSSGlobal.Cursor.Default,
              styles.table,
              styles.noResults
            )}
          >
            No&nbsp;results&nbsp;found. Consider&nbsp;removing
            or&nbsp;broadening&nbsp;filters.
          </div>
        )
      )}

      {!!hasInfiniteScroll && (
        <div ref={visibilityRef} className="gridSpanRow">
          &nbsp;
        </div>
      )}
      {!!loading && <DefaultLoader />}
    </div>
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(ActivityPageTable, {
    activityQuery: { concreteRequest: ActivityPaginatedQueryType },
  })
);
