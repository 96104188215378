import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  joinClasses,
  useOnEnterKey,
  useRefState,
} from '@mp-frontend/core-utils';

import useOnBodyResize from 'hooks/useOnBodyResize';
import { NFTMedia } from 'utils/nftUtils';

import { getRenderedMediaSize } from './Image';
import { MediaSize } from '.';

import * as styles from 'css/pages/product/ProductPreview.module.css';

interface EmbedProps {
  media: NFTMedia;
  autoplay?: boolean;
  className?: string;
  isFullScreen?: boolean;
  onClick?: (event: MouseEvent) => void;
  onLoad?: () => void;
}

export default function Embed({
  className,
  media,
  isFullScreen = false,
  autoplay = false,
  onClick,
  onLoad,
}: EmbedProps) {
  const containerRef = useRef<HTMLIFrameElement>(null);
  const [iframeRef, setIframeRef] = useRefState<HTMLIFrameElement>(null);
  const [contentSize, setContentSize] = useState<MediaSize>({
    height: 0,
    width: 0,
  });
  const [renderedSize, setRenderedSize] = useState<MediaSize>(contentSize);
  const { width: resizeBodyWidth } = useOnBodyResize();

  const src = useMemo(
    () =>
      !media?.highResUrl ||
      media.highResUrl.toLowerCase().includes('makersplace.com')
        ? null
        : media.highResUrl,
    [media?.highResUrl]
  );

  const allow = useMemo(
    () =>
      [
        'accelerometer',
        autoplay && 'autoplay',
        'encrypted-media',
        'gyroscope',
        'picture-in-picture',
      ]
        .filter(Boolean)
        .join('; '),

    [autoplay]
  );

  useEffect(() => {
    if (!iframeRef) return;

    iframeRef.addEventListener('load', () => {
      window.addEventListener('message', (event) => {
        if (!iframeRef || event.source !== iframeRef.contentWindow) return;
        if (!Array.isArray(event.data) || event.data.length !== 2) return;
        const [type, size] = event.data;
        if (
          type !== 'size' ||
          !size ||
          !size.height ||
          !size.width ||
          Number.isNaN(+size.height) ||
          Number.isNaN(+size.width)
        )
          return;

        setContentSize(size);
      });
      iframeRef.contentWindow.postMessage('getSize', '*');

      if (isFullScreen) iframeRef.focus();
    });
  }, [media.id, iframeRef, isFullScreen]);

  useEffect(() => {
    if (!containerRef.current || !contentSize.height || !contentSize.width)
      return;

    setRenderedSize(
      getRenderedMediaSize(
        contentSize,
        isFullScreen
          ? {
              height: window.innerHeight,
              width: window.innerWidth,
            }
          : containerRef.current.getBoundingClientRect()
      )
    );
  }, [isFullScreen, contentSize, resizeBodyWidth]);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (!onClick) return;

      event.preventDefault();
      event.stopPropagation();

      onClick(event);
    },
    [onClick]
  );
  const ariaOnEnter = useOnEnterKey(handleClick);

  return src ? (
    <div ref={containerRef} className={joinClasses(styles.iframe, className)}>
      <iframe
        ref={setIframeRef}
        onLoad={onLoad}
        title="Artwork Preview"
        allow={allow}
        sandbox="allow-scripts"
        src={src}
        width={renderedSize?.width || '100%'}
        height={renderedSize?.height || '100%'}
      />

      {isFullScreen ? null : (
        <div
          role="button"
          tabIndex={-1}
          onClick={handleClick}
          onKeyPress={ariaOnEnter}
        >
          Open Full Screen Preview
        </div>
      )}
    </div>
  ) : null;
}
