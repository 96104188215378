import { MouseEvent, ReactNode } from 'react';

import {
  MPAnimations,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { FollowButton } from 'components/buttons/FollowButton';
import THRESHOLDS from 'constants/Thresholds';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { AccountArtistFragment } from 'types/graphql/Account';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import toNumericShorthand from 'utils/numericShorthand';

import UserProfileImage, {
  Size,
  UserProfileLinkOrNot,
} from './UserProfileImage';

import * as styles from 'css/components/accounts/User.module.css';

const nameFontSizeMap: Record<Size, string> = {
  large: MPFonts.textLargeMedium,
  medium: MPFonts.textNormalMedium,
  small: MPFonts.textNormalMedium,
  'x-small': MPFonts.textSmallMedium,
};

type BottomSectionType = 'stats' | 'follow' | 'none' | ReactNode;

export interface UserProps {
  user: Pick<
    AccountArtistFragment,
    'pk' | 'username' | 'profileImageUrl' | 'fullName'
  > & {
    followerCount?: AccountArtistFragment['followerCount'];
    totalVolume?: AccountArtistFragment['totalVolume'];
  };
  bottomSection?: BottomSectionType;
  className?: string;
  nameColorClassName?: string;
  onClick?: (event: MouseEvent) => void;
  size?: Size;
  statsColorClassName?: string;
  topSection?: ReactNode;
}

export default function User({
  user,
  bottomSection = 'stats',
  className = joinClasses(
    CSSGlobal.Overflow.Hidden,
    CSSGlobal.Flex.RowCenterAlign,
    CSSGlobal.Flex.NoWrap
  ),
  nameColorClassName = joinClasses(
    MPColorClass.CommonBlack,
    MPAnimations.Color.DarkToLight
  ),
  size = 'medium',
  statsColorClassName = MPColorClass.SolidNeutralGray5,
  topSection,
  onClick,
}: UserProps) {
  return (
    <div className={joinClasses(CSSGap[8], className)}>
      <UserProfileImage
        fullName={user.fullName}
        usernameToNavigate={user.username}
        profileImageUrl={user.profileImageUrl}
        size={size}
        onClick={onClick}
      />
      <div
        className={joinClasses(
          CSSGlobal.Flex.ColCenterAlign,
          styles.rightSection
        )}
      >
        {topSection}

        <UserProfileLinkOrNot
          className={joinClasses(
            CSSGlobal.Ellipsis,
            nameFontSizeMap[size],
            nameColorClassName
          )}
          usernameToNavigate={user.username}
          onClick={onClick}
        >
          {generateFormattedUserFullName(user.fullName)}
        </UserProfileLinkOrNot>

        {bottomSection === 'stats' ? (
          user.totalVolume?.totalVolumeInUsd > THRESHOLDS.TOTAL_SALES ? (
            <div
              className={joinClasses(
                CSSGlobal.Cursor.Default,
                CSSGlobal.Ellipsis,
                MPFonts.textSmallMedium,
                statsColorClassName
              )}
            >
              {`$${toNumericShorthand(
                user.totalVolume?.totalVolumeInUsd
              )} in sales`}
            </div>
          ) : user.followerCount > THRESHOLDS.AUDIENCE ? (
            <div
              className={joinClasses(
                CSSGlobal.Cursor.Default,
                CSSGlobal.Ellipsis,
                MPFonts.textSmallMedium,
                statsColorClassName
              )}
            >
              {`${toNumericShorthand(user.followerCount)} followers`}
            </div>
          ) : null
        ) : bottomSection === 'follow' ? (
          <FollowButton
            username={user.username}
            userId={parseInt(user.pk, 10)}
            className={styles.action}
          />
        ) : bottomSection === 'none' ? null : (
          bottomSection
        )}
      </div>
    </div>
  );
}
