import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { OwnershipEnum } from 'types/__generated__/graphql';

import ToggleFilter from 'components/filters/ToggleFilter';
import useSession from 'hooks/useSession';

export default function useOwnershipFilter(
  urlParam
): [JSX.Element, OwnershipEnum, () => void] {
  const session = useSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialVal =
    searchParams.get(urlParam) === OwnershipEnum.Created ||
    searchParams.get(urlParam) === OwnershipEnum.Collected
      ? searchParams.get(urlParam)
      : undefined;
  const [ownership, setOwnership] = useState<OwnershipEnum>(
    initialVal as OwnershipEnum
  );
  const toggleCreated = useCallback(
    () =>
      setOwnership((prev) =>
        prev === OwnershipEnum.Created ? undefined : OwnershipEnum.Created
      ),
    []
  );
  const toggleCollected = useCallback(
    () =>
      setOwnership((prev) =>
        prev === OwnershipEnum.Collected ? undefined : OwnershipEnum.Collected
      ),
    []
  );

  useEffect(() => {
    if (initialVal !== ownership) {
      if (ownership) {
        searchParams.set(urlParam, ownership);
      } else {
        searchParams.delete(urlParam);
      }
      setSearchParams(searchParams, { replace: true });
    }
  }, [urlParam, setSearchParams, searchParams, initialVal, ownership]);

  return [
    <>
      {(session.isCreator() || session.isDowngradedCreator()) && (
        <ToggleFilter
          isSelected={ownership === OwnershipEnum.Created}
          title="Created Artworks"
          onToggle={toggleCreated}
        />
      )}
      <ToggleFilter
        isSelected={ownership === OwnershipEnum.Collected}
        title="Collected Artworks"
        onToggle={toggleCollected}
      />
    </>,
    ownership,
    useCallback(() => setOwnership(undefined), []),
  ];
}
