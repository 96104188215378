import { Suspense, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';

import { MPFonts } from '@mp-frontend/core-components';
import { LoaderIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTsManagementRequest, {
  NFTsManagementQuery,
} from 'graphql/__generated__/NFTsManagementQuery.graphql';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import usePollLoadQuery from 'hooks/graphqlLogic/usePollLoadQuery';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { NFTType } from 'types/graphql/NFT';

import PendingTransferAndSuccess from './PendingAndSuccess';

import * as modalStyles from 'css/pages/product/ProductSetBuyNow.module.css';

function Content({ queryRef, nft, closeAndInvalidate }) {
  const { pendingSend } = usePreloadedQuery<NFTsManagementQuery>(
    NFTsManagementRequest,
    queryRef
  ).nfts.edges[0].node;

  return (
    <div className={modalStyles.productSellContainer}>
      {!!pendingSend && (
        <div
          className={joinClasses(
            CSSGlobal.Flex.CenteredCol,
            CSSGap[16],
            modalStyles.productSellMessageContainer
          )}
        >
          <LoaderIcon fontSize="68" />
          <div
            className={joinClasses(
              MPFonts.paragraphNormal,
              CSSGlobal.Cursor.Default
            )}
          >
            We are now waiting on the transaction to send &#34;
            <span className={MPFonts.textNormalMedium}>
              {nft.metadata.title}
            </span>
            &#34; to{' '}
            <span className={MPFonts.textNormalMedium}>
              {pendingSend.recipientAddress}
            </span>{' '}
            and will update here shortly.
          </div>
        </div>
      )}
      {!!pendingSend?.ethTx?.txid && (
        <PendingTransferAndSuccess
          currentTransactionHash={pendingSend.ethTx.txid}
          nft={nft}
          toAddress={pendingSend.recipientAddress}
          closeAndInvalidate={closeAndInvalidate}
        />
      )}
    </div>
  );
}

interface WaitOnTransactionHashDialogProps {
  closeAndInvalidate: () => void;
  nft: NFTType;
  close?: () => void;
}

export default function WaitOnTransactionHashDialog({
  nft,
  closeAndInvalidate,
  close,
}: WaitOnTransactionHashDialogProps) {
  const [queryRef] = usePollLoadQuery<NFTsManagementQuery>({
    intervalMs: 60000,
    queryType: NFTsManagementRequest,
    queryVariables: { productSlug: nft.listing.productSlug },
  });

  const dialogProps = useMemo(
    () => (close ? { onClose: close } : { shouldBreak: true }),
    [close]
  );

  return (
    <StackStateDialog title="Transferring" {...dialogProps}>
      <Suspense>
        {!!queryRef && (
          <Content
            nft={nft}
            queryRef={queryRef}
            closeAndInvalidate={closeAndInvalidate}
          />
        )}
      </Suspense>
    </StackStateDialog>
  );
}
