import { useCallback } from 'react';
import { UseMPMutationConfig, useMutation } from 'react-relay';
import {
  GraphQLTaggedNode,
  MutationParameters,
  UploadableMap,
} from 'relay-runtime';

type MutationOverrides = Partial<
  Omit<
    UseMPMutationConfig<MutationParameters>,
    'onCompleted' | 'onError' | 'variables'
  >
>;

export function promisifyMutation<VariablesType, ResponseType>(
  mutation: (config: UseMPMutationConfig<MutationParameters>) => void,
  overrides?: MutationOverrides
): (
  variables: VariablesType,
  uploadables?: UploadableMap
) => Promise<ResponseType> {
  const innerFunction = (
    variables: VariablesType,
    uploadables?: UploadableMap
  ) =>
    new Promise<ResponseType>((onCompleted, onError) => {
      mutation({
        onCompleted,
        onError,
        uploadables,
        variables,
        ...overrides,
      });
    });

  return innerFunction;
}

/**
 * This is a helper function to promisify mutations that wraps variables in a requestData object
 * @param mutation
 * @param overrides
 * @returns a function that takes in the variables and returns a promise of the response type
 */
export function promisifyMutationWithRequestData<
  VariablesType extends { requestData: unknown },
  ResponseType
>(
  mutation: (config: UseMPMutationConfig<MutationParameters>) => void,
  overrides?: Partial<
    Omit<
      UseMPMutationConfig<MutationParameters>,
      'onCompleted' | 'onError' | 'variables'
    >
  >
): (
  variables: VariablesType['requestData'],
  uploadables?: UploadableMap
) => Promise<ResponseType> {
  return (
    variables: VariablesType['requestData'],
    uploadables?: UploadableMap
  ) =>
    promisifyMutation<
      { requestData: VariablesType['requestData'] },
      ResponseType
    >(mutation, overrides)({ requestData: variables }, uploadables);
}

interface Mutation {
  response: object;
  variables: { requestData: unknown };
}

export function usePromisifyMutationWithCallback<M extends Mutation>(
  mutation,
  overrides?: MutationOverrides
) {
  return useCallback(
    () =>
      promisifyMutation<M['variables'], M['response']>(mutation[0], overrides),
    [mutation, overrides]
  );
}

/**
 * Ovverrides must be memomized for the memo chain to work correctly.
 */
export function usePromisifyMutationWithRequestData<M extends Mutation>(
  concreteRequest: GraphQLTaggedNode,
  overrides?: MutationOverrides
): [
  (variables: M['variables']['requestData']) => Promise<M['response']>,
  boolean
] {
  const mutation = useMutation(concreteRequest);
  const requestGenerator = useCallback(
    () =>
      promisifyMutationWithRequestData<M['variables'], M['response']>(
        mutation[0],
        overrides
      ),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [mutation[0], overrides]
  );

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const promisifiedMutation = useCallback(requestGenerator(), [
    requestGenerator,
  ]);

  return [promisifiedMutation, mutation[1]];
}

export default promisifyMutation;
