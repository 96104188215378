import { CheckCircleIcon } from '@mp-frontend/core-components/icons';

import SuccessView from 'pages/product/purchaseOfferDialog/SuccessView';

export default function Congratulations({ didList, onClose }) {
  return (
    <div>
      <SuccessView
        Icon={CheckCircleIcon}
        title="Success!"
        message={didList ? 'Artwork Price Changed' : 'Your Artwork was Listed'}
        onClose={onClose}
      />
    </div>
  );
}
