import {
  Dispatch,
  SetStateAction,
  startTransition,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { CheckCircleIcon } from '@mp-frontend/core-components/icons';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import useSaleContract from 'hooks/useSaleContract';
import ProductPendingOnChain from 'pages/product/ProductPendingOnChain';
import SuccessView from 'pages/product/purchaseOfferDialog/SuccessView';
import CSSMargin from 'types/enums/css/Margin';
import { NFTType } from 'types/graphql/NFT';
import { nftHasCustodialSale } from 'utils/nftUtils';

import CustodialDelistButton from '../../Delist/CustodialDelistButton';
import WalletDelistButton from '../../Delist/WalletDelistButton';
import { ProductOwnerStates } from '../../ProductOwnerUpdateListing';

interface ConfirmDelistingProps {
  nft: NFTType;
  onClose: () => void;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
}

function ConfirmDelisting({
  nft,
  onClose,
  setCurrentTransactionHash,
  setOwnershipState,
}: ConfirmDelistingProps) {
  const saleContract = useSaleContract(nft);

  const isCustodialDelisting =
    nftHasCustodialSale(nft) || nft.listing.hasReservePrice;

  const showDelistingBlockchainMsg =
    !nft.isCustodialOwner && !nft.listing.hasReservePrice;

  return (
    <StackStateDialog
      title="Delist Artwork"
      onClose={onClose}
      actionButton={
        isCustodialDelisting ? (
          <CustodialDelistButton
            nft={nft}
            setOwnershipState={setOwnershipState}
          >
            Confirm
          </CustodialDelistButton>
        ) : (
          <WalletDelistButton
            nft={nft}
            saleContract={saleContract}
            setCurrentTransactionHash={setCurrentTransactionHash}
            setOwnershipState={setOwnershipState}
          >
            Confirm
          </WalletDelistButton>
        )
      }
    >
      <div className={CSSMargin.BOTTOM[16]}>
        <p className={MPFonts.paragraphNormal}>
          Delisting will cancel your lowest ask price. Buyers will continue to
          see that you&apos;re looking to sell this artwork.{' '}
          {!!showDelistingBlockchainMsg && (
            <>
              You will incur applicable blockchain fees associated with this
              action, since it will be performed with your digital wallet.
            </>
          )}
        </p>
      </div>
    </StackStateDialog>
  );
}

function PendingDelisting({ nft, currentTransactionHash, onSuccess }) {
  const queryVariables = useMemo(
    () => ({ txtId: currentTransactionHash }),
    [currentTransactionHash]
  );
  return (
    <StackStateDialog title="Delist Artwork Pending" shouldBreak>
      <Suspense>
        <ProductPendingOnChain
          queryVariables={queryVariables}
          onSuccess={onSuccess}
          nft={nft}
        />
      </Suspense>
    </StackStateDialog>
  );
}

interface CongratulationsProps {
  onClose: () => void;
}

function Congratulations({ onClose }: CongratulationsProps) {
  return (
    <div>
      <SuccessView
        Icon={CheckCircleIcon}
        title="Success!"
        message="Your Artwork was Delisted"
        onClose={onClose}
      />
    </div>
  );
}

interface NFTDelistActionProps {
  invalidate: () => void;
  nft: NFTType;
}

export default function NFTDelistAction({
  nft,
  invalidate,
}: NFTDelistActionProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ownershipState, setOwnershipState] = useState(
    ProductOwnerStates.DELIST
  );
  const [currentTransactionHash, setCurrentTransactionHash] = useState(null);
  const open = useCallback(() => setIsDialogOpen(true), []);
  const close = useCallback(() => setIsDialogOpen(false), []);
  const closeAndInvalidate = useCallback(
    () =>
      startTransition(() => {
        invalidate();
        setIsDialogOpen(false);
      }),
    [invalidate]
  );

  const updateOwnerShipStatesAfterSuccessOnChain = useCallback(() => {
    startTransition(() => {
      if (ownershipState === ProductOwnerStates.PENDING_APPROVAL) {
        setOwnershipState(ProductOwnerStates.APPROVED);
      } else if (ownershipState === ProductOwnerStates.PENDING_FIXED_SALE) {
        setOwnershipState(ProductOwnerStates.FIXED_SALE_LISTED);
      } else if (ownershipState === ProductOwnerStates.DELIST_PENDING) {
        setOwnershipState(ProductOwnerStates.DELISTED);
      }
      setCurrentTransactionHash(null);
    });
  }, [ownershipState]);

  return (
    <>
      <MPActionButton variant="tertiary" onClick={open}>
        Delist
      </MPActionButton>
      {!!isDialogOpen && (
        <>
          {ownershipState === ProductOwnerStates.DELIST && (
            <ConfirmDelisting
              nft={nft}
              onClose={close}
              setOwnershipState={setOwnershipState}
              setCurrentTransactionHash={setCurrentTransactionHash}
            />
          )}
          {ownershipState === ProductOwnerStates.DELIST_PENDING && (
            <PendingDelisting
              nft={nft}
              onSuccess={updateOwnerShipStatesAfterSuccessOnChain}
              currentTransactionHash={currentTransactionHash}
            />
          )}
          {ownershipState === ProductOwnerStates.DELISTED && (
            <Congratulations onClose={closeAndInvalidate} />
          )}
        </>
      )}
    </>
  );
}
