import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, usePreloadedQuery } from 'react-relay';

import {
  MPActionButton,
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
  MPStandardDialog,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountGetNewsletterSubscriptionStatusQueryType, {
  AccountGetNewsletterSubscriptionStatusQuery,
} from 'graphql/__generated__/AccountGetNewsletterSubscriptionStatusQuery.graphql';
import AccountUpdateNewsletterSubscriptionMutation, {
  AccountUpdateNewsletterSubscriptionMutation$data,
  AccountUpdateNewsletterSubscriptionMutation$variables,
} from 'graphql/__generated__/AccountUpdateNewsletterSubscriptionMutation.graphql';

import { APP_NAME } from 'constants/Utils';
import ENV_VARS from 'envVars';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSPadding from 'types/enums/css/Padding';
import { parseCookie } from 'utils/getToken';
import useNewsletterGTM, {
  NewsletterActionType,
  SUBSCRIPTION_SOURCE,
} from 'utils/GTM/newletter';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { COOKIE_KEYS } from 'utils/localStorageUtils';
import { validateEmailInput } from 'utils/loginUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

import useSession from './useSession';

import * as styles from 'css/components/NewsletterSignUp.module.css';

type ExampleType = { description: string; imageUrl: string; title: string };
const EXAMPLES: ExampleType[] = [
  {
    description: 'Round up editorial email with digital art news for the week.',
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/newsletter/editorial.png',
    title: '2x Month',
  },
  {
    description: 'Upcoming and Live Exhibition and Events emails.',
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/newsletter/exhibitions.png',
    title: '1-3x Week',
  },
];

const TITLE = 'Collect Better';

function useNewsletterSignUp(source: string) {
  const track = useNewsletterGTM();
  const session = useSession();
  const [visible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>(
    (!session.account?.hasAnonEmailAddress && session.account?.email) || ''
  );
  const [isBlurred, setIsBlurred] = useState<boolean>(false);
  const [autoOpened, setAutoOpened] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const commitUpdateNewsletterSubscriptionAsync =
    promisifyMutationWithRequestData<
      AccountUpdateNewsletterSubscriptionMutation$variables,
      AccountUpdateNewsletterSubscriptionMutation$data
    >(useMutation(AccountUpdateNewsletterSubscriptionMutation)[0]);

  const show = useCallback(
    (isAutoOpen = false) => {
      if (!isAutoOpen) track.registerAction(NewsletterActionType.Open, source);

      setIsSuccess(false);
      setVisible(true);
      setAutoOpened(isAutoOpen);
    },
    [source, track]
  );
  const hide = useCallback(async () => {
    track.registerAction(
      autoOpened ? NewsletterActionType.Reject : NewsletterActionType.Close,
      source
    );

    if (autoOpened) {
      await commitUpdateNewsletterSubscriptionAsync({ subscribe: false });
    }
    setVisible(false);
  }, [autoOpened, source, track, commitUpdateNewsletterSubscriptionAsync]);
  const emailValidation = useMemo(() => validateEmailInput(value), [value]);

  const handleBlur = useCallback(() => setIsBlurred(true), []);

  const handleSubmit = useCallback(async () => {
    await commitUpdateNewsletterSubscriptionAsync({
      email: value,
      subscribe: true,
    });
    track.registerAction(NewsletterActionType.Submit, source);
    setIsSuccess(true);
  }, [source, track, value, commitUpdateNewsletterSubscriptionAsync]);

  const handleCloseSuccess = useCallback(() => setVisible(false), []);

  return {
    Dialog: visible ? (
      !isSuccess ? (
        <MPStandardDialog title={TITLE} onClose={hide}>
          <div
            className={joinClasses(
              MPColorClass.CommonBlack,
              CSSGlobal.Cursor.Default,
              CSSGlobal.Flex.Col,
              CSSGap[16]
            )}
          >
            <div className={MPFonts.paragraphSmall}>
              Join our newsletter to stay on top of upcoming exhibitions,
              events, and the most interesting news in the digital art space.
            </div>
            <MPStyledTextField
              label="Your Email"
              placeholder="you@email.com"
              value={value}
              setValue={setValue}
              inputMode="text"
              error={!!isBlurred && emailValidation.errorMessage}
              onBlur={handleBlur}
            />
            <MPActionButton
              disabled={!value || emailValidation.isError}
              fullWidth
              variant="primary"
              size="large"
              onClick={handleSubmit}
            >
              Subscribe
            </MPActionButton>

            {EXAMPLES.map(({ imageUrl, title, description }) => (
              <div
                key={title}
                className={joinClasses(
                  MPBackgroundColorClass.BackgroundDefault,
                  MPColorClass.CommonBlack,
                  CSSPadding.AROUND[16],
                  CSSGlobal.Flex.RowCenterAlign,
                  CSSGap[16],
                  styles.example
                )}
              >
                <div
                  className={joinClasses(
                    CSSGlobal.BackgroundImage.Cover,
                    styles.image
                  )}
                  style={{ backgroundImage: `url(${imageUrl})` }}
                />
                <div
                  className={joinClasses(
                    CSSGlobal.Flex.Col,
                    CSSGap[4],
                    styles.details
                  )}
                >
                  <div
                    className={joinClasses(
                      MPFonts.textSmallRegular,
                      MPColorClass.SolidNeutralGray5
                    )}
                  >
                    {title}
                  </div>
                  <div className={MPFonts.paragraphSmall}>{description}</div>
                </div>
              </div>
            ))}
          </div>
        </MPStandardDialog>
      ) : (
        <MPStandardDialog title={TITLE} onClose={handleCloseSuccess}>
          <div
            className={joinClasses(
              MPColorClass.CommonBlack,
              CSSGlobal.Cursor.Default,
              CSSGlobal.Flex.Col,
              CSSGap[24]
            )}
          >
            <div className={MPFonts.paragraphSmall}>
              You have successfully subscribed to the {APP_NAME} newsletter. You
              can unsubscribe at any time.
            </div>

            <MPActionButton
              fullWidth
              variant="primary"
              size="large"
              onClick={handleCloseSuccess}
            >
              Continue
            </MPActionButton>
          </div>
        </MPStandardDialog>
      )
    ) : null,
    show,
  };
}

const DEFAULT_TIMEOUT_MS = 3000;

const CheckSubscription = withDefaultErrorBoundary(
  withLoadQuery(
    ({
      subscriptionQuery: { queryRef },
    }: {
      subscriptionQuery: WithLoadQueryProps<AccountGetNewsletterSubscriptionStatusQuery>;
    }) => {
      const { newsletterSubscriptionStatus } =
        usePreloadedQuery<AccountGetNewsletterSubscriptionStatusQuery>(
          AccountGetNewsletterSubscriptionStatusQueryType,
          queryRef
        );
      const newsletter = useNewsletterSignUp(SUBSCRIPTION_SOURCE.AUTO_OPEN);

      useEffect(() => {
        if (newsletterSubscriptionStatus || ENV_VARS.isDev) return;

        setTimeout(() => newsletter.show(true), DEFAULT_TIMEOUT_MS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [newsletterSubscriptionStatus]);

      return !newsletterSubscriptionStatus ? <>{newsletter.Dialog}</> : null;
    },
    {
      subscriptionQuery: {
        concreteRequest: AccountGetNewsletterSubscriptionStatusQueryType,
      },
    }
  ),
  { hideState: true }
);

export function NewsletterSignUpAutoOpen() {
  const session = useSession();
  const newsletter = useNewsletterSignUp(SUBSCRIPTION_SOURCE.AUTO_OPEN);
  const hide = useMemo(
    () => !!parseCookie(document.cookie)[COOKIE_KEYS.NEWSLETTER],
    []
  );
  const hasEmail = useMemo(
    () => session.isLoggedIn() && session.account?.email,
    [session]
  );

  // TODO(unbrick): remove this to restore the newsletter popup functionality
  const disableHack = true;

  useEffect(() => {
    if (hide || hasEmail || ENV_VARS.isDev || disableHack) return;

    setTimeout(() => newsletter.show(true), DEFAULT_TIMEOUT_MS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hide ? null : hasEmail ? (
    <CheckSubscription
      subscriptionQuery={{ variables: { email: session.account.email } }}
    />
  ) : (
    <>{newsletter.Dialog}</>
  );
}

export default useNewsletterSignUp;
