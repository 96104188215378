.productListingSelectionContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 32px 0;
  width: 100%;
}

.productListingSelection {
  display: flex;
  padding: 24px;
  align-items: center;
  flex-direction: row;
  border: 1px solid var(--color-SolidNeutralGray1);
  background: var(--color-backgroundPaper);
}

.productListingSelection.projSettings {
  padding: 25px;
  border: 1px solid var(--color-SolidNeutralGray1);
}

.productListingSelectionItem {
  display: flex;
  flex-direction: row;
}

.productListingSelection:hover,
.productListingSelectionSelected {
  cursor: pointer;
  border: 2px solid var(--color-commonBlack);
  background: var(--color-backgroundPaper);
}

.productListingSelection.projSettings:hover,
.productListingSelectionSelected.projSettings {
  border: 2px solid var(--color-commonBlack);
  padding: 24px;
}

.productListingSelectionDescription {
  color: var(--color-SolidNeutralGray5);
  margin-left: auto;
}

.disconnectedWalletContainer, .disconnectedWalletBtnContainer {
  margin: 32px;
}

.disconnectedWalletFooter {
  margin-top: 32px;
}

.ownerModeDisplayMessage {
  margin: 8px 0 24px 0;
}

@media (--mq-is-mobile) {
  .productListingSelectionContainer {
    flex-direction: column;
    gap: 24px;
  }
  .productListingSelection {
    width: initial;
  }
}
