import {
  Dispatch,
  SetStateAction,
  startTransition,
  useEffect,
  useState,
} from 'react';
import { useMutation } from 'react-relay';
import { Hash } from 'viem';
import { useAccount } from 'wagmi';
import * as Sentry from '@sentry/react';

import CreateSaleWithPersonalWalletMutation from 'graphql/__generated__/ListingCreateSaleWithPersonalWalletMutation.graphql';
import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import WalletActionButton from 'components/WalletActionButton';
import useDigitalMediaSaleCoreContract from 'hooks/contracts/useDigitalMediaSaleCoreContract';
import usePublicClient from 'hooks/useWeb3Client';
import { NFTType } from 'types/graphql/NFT';
import IsDigitalSaleCoreContractError from 'utils/errors/contracts/DigialMediaSaleCore';
import getSafeBigNumberFromUserInput from 'utils/getSafeBigNumberFromUserInput';

import { ProductOwnerStates } from '../ProductOwnerUpdateListing';
import Content from './Content';
import useBuyNowState from './useBuyNowState';

interface WalletProps {
  nft: NFTType;
  saleContract: Pick<
    NFTContractQuery$data['nftContract'],
    'abidata' | 'address'
  >;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
}

export default function Custodial({
  nft,
  saleContract,
  setCurrentTransactionHash,
  setOwnershipState,
}: WalletProps) {
  const { address: currentUserAddress } = useAccount();
  const provider = usePublicClient();
  const [isValidating, setIsValidating] = useState(false);

  const [commitCreateSaleWithPersonalWalletMutation] = useMutation(
    CreateSaleWithPersonalWalletMutation
  );

  const buyNowState = useBuyNowState(nft);
  const [buyNowPrice] = buyNowState;

  const [error, setError] = useState<Error>(undefined);

  // We need to look at the contract address of the live sale since it might be an older DigitalMedia SaleCore
  const saleContractAddress = nft.listing?.liveSale?.contract
    ? nft.listing?.liveSale.contract.address
    : saleContract.address;

  const saleAbiData = nft.listing?.liveSale?.contract
    ? nft.listing?.liveSale.contract.abidata
    : saleContract.abidata;

  // Sale should be created with the most recent contract.
  const { useCreateSale } = useDigitalMediaSaleCoreContract({
    abi: JSON.parse(saleAbiData).abi,
    contractAddress: saleContractAddress as Hash,
  });

  const {
    mutate: createSale,
    simulate: {
      isPending: createSaleFetching,
      isFetching: createSaleRefetching,
      error: _createSaleError,
    },
  } = useCreateSale({
    acceptFiat: nft.currentOwner.store.acceptsCreditCard,
    price: getSafeBigNumberFromUserInput(buyNowPrice).toBigInt(),
    tokenAddress: nft.contract.address as Hash,
    tokenId: parseInt(nft.onchainId, 10),
  });
  const createSaleError =
    IsDigitalSaleCoreContractError.createSale.WeiPriceMustBePositive(
      _createSaleError
    )
      ? null
      : _createSaleError;

  useEffect(() => {
    if (createSaleError) {
      Sentry.captureException(
        new Error(
          `NFTException: (${nft.pk}, ${JSON.stringify(createSaleError)})`
        )
      );
    }
  }, [createSaleError, nft.pk]);

  const createListingWithPersonalWallet = async ({
    fromAddress,
    nonce,
    price,
    releaseId,
    transactionId,
  }) =>
    new Promise((resolve, reject) => {
      setError(undefined);
      commitCreateSaleWithPersonalWalletMutation({
        onCompleted: (data: any) => {
          resolve(data);
        },
        onError(err) {
          setError(err);
          reject(err);
        },
        variables: {
          request_data: {
            fromAddress,
            nonce,
            price,
            releaseId,
            transactionId,
          },
        },
      });
    });

  const confirmPersonalListing = async () => {
    try {
      setIsValidating(true);
      const transactionResult = await createSale.writeAsync();
      const nonce =
        (await provider.getTransactionCount(currentUserAddress)) + 1;
      await createListingWithPersonalWallet({
        fromAddress: currentUserAddress,
        nonce: nonce.toString(),
        price: buyNowPrice,
        releaseId: nft.pk,
        transactionId: transactionResult,
      });
      startTransition(() => {
        setOwnershipState(ProductOwnerStates.PENDING_FIXED_SALE);
        setCurrentTransactionHash(transactionResult);
      });
      setIsValidating(false);
    } catch (err) {
      setIsValidating(false);
      createSale.reset();
      setError(err);
      err.errorContext = { nftId: nft.pk };
      Sentry.captureException(err);
    }
    createSale.reset();
  };

  const isLoading = createSaleFetching || createSaleRefetching || isValidating;

  return (
    <Content
      setOwnershipState={setOwnershipState}
      nft={nft}
      error={error}
      buyNowState={buyNowState}
      button={
        <WalletActionButton
          fullWidth
          onClick={confirmPersonalListing}
          disabled={isLoading || (parseFloat(buyNowPrice) || 0) === 0}
          warnIfAddressNotRegistered
          size="large"
        >
          {nft.listing.liveSale ? 'Confirm' : 'List Artwork'}
        </WalletActionButton>
      }
    />
  );
}
