import { ChangeEvent } from 'react';

import { MPFonts, MPStyledTextField } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ErrorDisplay from 'components/Error';
import CSSMargin from 'types/enums/css/Margin';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';
import toNumericShorthand from 'utils/numericShorthand';

import * as styles from 'css/pages/product/ProductSetBuyNow.module.css';

interface ListingProps {
  currencyDisplay: CurrencyDisplayMode;
  handleSetPrice: (event: ChangeEvent<HTMLInputElement>) => void;
  nft: NFTType;
  price: string;
  error?: Error;
}

export default function Listing({
  nft,
  currencyDisplay,
  price,
  handleSetPrice,
  error,
}: ListingProps) {
  const priceFloat = parseFloat(price) || 0;
  const dollarDisplayAmt = toNumericShorthand(priceFloat * nft.currentEthRate);
  const dollarAmount =
    currencyDisplay === CurrencyDisplayMode.ETH
      ? priceFloat * nft.currentEthRate < 1
        ? parseFloat(dollarDisplayAmt).toFixed(2)
        : dollarDisplayAmt
      : price ?? 0;
  const ethAmount =
    currencyDisplay === CurrencyDisplayMode.ETH
      ? price ?? 0
      : toNumericShorthand(priceFloat / nft.currentEthRate);

  return (
    <>
      {!!error && (
        <div className={CSSMargin.TOP[16]}>
          <ErrorDisplay error={error} />
        </div>
      )}
      <div>
        <MPStyledTextField
          startAdornment={
            currencyDisplay === CurrencyDisplayMode.USD ? '$' : undefined
          }
          value={price || ''}
          onChange={handleSetPrice}
          inputMode="decimal"
          placeholder={`Amount In ${currencyDisplay}`}
        />
      </div>
      <div className={styles.buyReservePricingContainer}>
        <div
          className={joinClasses(
            styles.buyNowPriceItem,
            MPFonts.textNormalMedium
          )}
        >
          Total
        </div>
        <div className={styles.buyNowPriceItem}>
          <span className={MPFonts.price}>
            {currencyDisplay === CurrencyDisplayMode.USD
              ? `$${dollarAmount}`
              : `ETH ${ethAmount}`}
            &nbsp;
          </span>
          <span
            className={joinClasses(
              MPFonts.subPrice,
              styles.buyReserveSecondaryPrice
            )}
          >
            {currencyDisplay === CurrencyDisplayMode.USD
              ? `${ethAmount} ETH`
              : `$${dollarAmount}`}
          </span>
        </div>
      </div>
    </>
  );
}
