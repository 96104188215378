/**
 * @generated SignedSource<<bb37f587a0b3a3145c462ee7c71df1a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddressIsNotCustodialQuery$variables = {
  address: string;
};
export type AddressIsNotCustodialQuery$data = {
  readonly isValidRecipientAddress: boolean | null;
};
export type AddressIsNotCustodialQuery = {
  variables: AddressIsNotCustodialQuery$variables;
  response: AddressIsNotCustodialQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "address",
        "variableName": "address"
      }
    ],
    "kind": "ScalarField",
    "name": "isValidRecipientAddress",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddressIsNotCustodialQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddressIsNotCustodialQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a86be84e5ab710e139a4bd21666a4319",
    "id": null,
    "metadata": {},
    "name": "AddressIsNotCustodialQuery",
    "operationKind": "query",
    "text": "query AddressIsNotCustodialQuery(\n  $address: String!\n) {\n  isValidRecipientAddress(address: $address)\n}\n"
  }
};
})();

(node as any).hash = "882d467771b1e46d2fe68ec4847a8062";

export default node;
