import { startTransition, useCallback } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import NFTsManagementRequest, {
  NFTsManagementQuery,
} from 'graphql/__generated__/NFTsManagementQuery.graphql';

import useLoadQuery from 'hooks/useLoadQuery';
import CSSGlobal from 'types/enums/css/Global';
import { NFTType } from 'types/graphql/NFT';

import NFTDelistAction from './Delist';
import NFTEditAction from './Edit';
import NFTListAction from './List';
import ToggleVisibility from './ToggleVisibility';
import NFTTransferAction from './Transfer';

import * as styles from 'css/pages/product/owner/Manage/Actions/index.module.css';

interface NFTActionsProps {
  invalidate: () => void;
  nft: NFTType;
}

export default function NFTActions({ nft, invalidate }: NFTActionsProps) {
  const hasListing = nft.listing.hasReservePrice || nft.listing.liveSale;
  const closeAndInvalidate = useCallback(
    () => startTransition(invalidate),
    [invalidate]
  );

  const [managementQueryRef] = useLoadQuery<NFTsManagementQuery>(
    NFTsManagementRequest,
    {
      productSlug: nft.listing.productSlug,
    }
  );

  return (
    <div className={joinClasses(CSSGlobal.Flex.Row, styles.actions)}>
      {hasListing ? (
        <>
          <NFTEditAction nft={nft} invalidate={invalidate} />
          <NFTDelistAction nft={nft} invalidate={invalidate} />
        </>
      ) : (
        <>
          <NFTListAction nft={nft} invalidate={invalidate} />
          {!!managementQueryRef && (
            <NFTTransferAction
              nft={nft}
              managementQueryRef={managementQueryRef}
              closeAndInvalidate={closeAndInvalidate}
            />
          )}
        </>
      )}
      <ToggleVisibility nft={nft} invalidate={invalidate} />
    </div>
  );
}
