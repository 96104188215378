.transferPage {
  --transferpage-gap: 60px;
  --transferpage-leftMargin: 32px;
  --transferpage-rightMargin: 16px;

  @media screen and (min-width: 1920px) {
    --transferpage-leftMargin: 112px;
    --transferpage-rightMargin: 96px;
  }

  @media (--mq-is-mobile) {
    --transferpage-gap: 32px;
    --transferpage-leftMargin: 0px;
    --transferpage-rightMargin: 0px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--transferpage-gap);
  margin: 0 auto var(--transferpage-gap) auto;
  max-width: 1440px;

  @media screen and (min-width: 1920px) {
    max-width: 1920px;
  }
}

.content > section {
  margin: 0 var(--transferpage-rightMargin) 0 var(--transferpage-leftMargin);
}
