import { useState } from 'react';
import { useMutation } from 'react-relay';
import * as Sentry from '@sentry/react';
import { useStatsigClient } from '@statsig/react-bindings';

import BidOnNftInCreditCardMutationRequest, {
  type BidOnNftInCreditCardMutation,
} from 'graphql/__generated__/BidOnNftInCreditCardMutation.graphql';
import { BidType } from 'types/__generated__/graphql';

import { useTrackingContext } from 'components/trackingContext';
import { STATSIG_EVENT } from 'constants/Statsig';
import MPError, { MPErrorName } from 'errors/MPError';
import GTM, { EcommercePaymentType, EcommercePurchaseType } from 'GTM';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { PurchasableNFTType } from 'types/graphql/NFT';
import getCurrentWalletName from 'utils/getCurrentWalletName';
import {
  getMinimumAcceptableBid,
  NFTCardSelectionType,
  nftHasRankedAuction,
} from 'utils/nftUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

import {
  CreditCardPaymentFormState,
  useStripePayment,
} from '../usePaymentFormState';

function usePlaceBid({
  cardSelectionType,
  savedCardId,
  cardHolderName,
  bidAmountInCents,
  nftId,
  remember,
}) {
  const { getPaymentMethodId, withNextPaymentAction } = useStripePayment();
  const asyncBid = withNextPaymentAction<
    BidOnNftInCreditCardMutation['variables'],
    BidOnNftInCreditCardMutation['response']
  >(
    promisifyMutationWithRequestData(
      useMutation(BidOnNftInCreditCardMutationRequest)[0]
    )
  );

  const placeBid = async (bidType: BidType): Promise<string> => {
    const paymentMethodId = await getPaymentMethodId(
      cardSelectionType,
      savedCardId,
      cardHolderName
    );
    const mutationResult = await asyncBid({
      bidAmountInCents,
      bidType,
      nftId,
      paymentMethodId,
      rememberCard: remember,
    });

    if (!mutationResult.createGeneralBidOnNftInCc.success) {
      throw new Error('Could not place bid with credit card');
    }

    return mutationResult.createGeneralBidOnNftInCc.intentId;
  };

  return placeBid;
}

const useBidProductWithCreditCard = ({
  nft,
  bidAmount,
  bidAmountEth,
  enableGlobalOffer,
  cardHolderName,
  cardIsValid,
  cardSelectionType,
  remember,
  savedCardId,
}: CreditCardPaymentFormState & {
  bidAmount: string;
  bidAmountEth: string;
  enableGlobalOffer: boolean;
  nft: PurchasableNFTType;
}) => {
  const statsigClient = useStatsigClient();
  const bidAmountFloat = parseFloat(bidAmount) || 0;
  const bidAmountInCents = bidAmountFloat * 100;
  const minimumAcceptableBid = getMinimumAcceptableBid(
    nft,
    CurrencyDisplayMode.USD
  );

  const { source } = useTrackingContext();
  const asyncPlaceBid = usePlaceBid({
    bidAmountInCents,
    cardHolderName,
    cardSelectionType,
    nftId: nft.pk,
    remember,
    savedCardId,
  });
  const hasRankedAuction = nftHasRankedAuction(nft);
  const [isPending, setIsPending] = useState(false);
  const [mutationError, setMutationError] = useState(undefined);

  const placeBid = async () => {
    statsigClient.logEvent(STATSIG_EVENT.MP.PRODUCT.BEGIN_CHECKOUT, bidAmount, {
      is_presale: `${false}`,
      nf_price_eth: bidAmountEth,
      nft_id: nft.pk,
      nft_name: nft.metadata.title,
      nft_price_usd: bidAmount,
      offer_or_purchase: EcommercePurchaseType.Offer,
      payment_type: EcommercePaymentType.CreditCard,
    });
    setIsPending(true);
    setMutationError(undefined);
    try {
      const intentId = await asyncPlaceBid(
        enableGlobalOffer
          ? BidType.GlobalBid
          : hasRankedAuction
          ? BidType.RankedAuction
          : BidType.Product
      );

      GTM.ecommerce.addPaymentInfo(nft, {
        payment_type: EcommercePaymentType.CreditCard,
      });

      GTM.ecommerce.purchase(
        nft,
        {
          coupon: null,
          offer_or_purchase: EcommercePurchaseType.Offer,
          payment_type: EcommercePaymentType.CreditCard,
          total_order_count: 1,
          transaction_id: intentId,
          value: nft.listing?.lowestAskInUsd?.toString() ?? null,
          value_ether: nft.listing?.lowestAskInEth ?? null,
          wallet_type: getCurrentWalletName(),
        },
        source
      );
      statsigClient.logEvent(
        STATSIG_EVENT.MP.PRODUCT.PURCHASE_SUCCESS,
        bidAmount,
        {
          is_presale: `${false}`,
          nf_price_eth: bidAmountEth,
          nft_id: nft.pk,
          nft_name: nft.metadata.title,
          nft_price_usd: bidAmount,
          offer_or_purchase: EcommercePurchaseType.Offer,
          payment_type: EcommercePaymentType.CreditCard,
        }
      );
    } catch (error) {
      GTM.ecommerce.error(error.message || error.toString(), {
        offer_or_purchase: EcommercePurchaseType.Offer,
      });
      setMutationError(error);
      statsigClient.logEvent(
        STATSIG_EVENT.MP.PRODUCT.PURCHASE_ERROR,
        bidAmount,
        {
          is_presale: `${false}`,
          nft_id: nft.pk,
          nft_name: nft.metadata.title,
          nft_price_eth: bidAmountEth,
          nft_price_usd: bidAmount,
          offer_or_purchase: EcommercePurchaseType.Offer,
          payment_type: EcommercePaymentType.CreditCard,
        }
      );
      error.errorContext = { nftId: nft.pk };
      Sentry.captureException(error);
      throw error;
    } finally {
      setIsPending(false);
    }
  };

  const validationError =
    bidAmountFloat < minimumAcceptableBid
      ? new MPError(MPErrorName.BELOW_MINIMUM_BID, [
          `$${minimumAcceptableBid.toFixed(2)}`,
        ])
      : undefined;

  return [
    placeBid,
    {
      isDisabled:
        isPending ||
        !(
          (cardSelectionType === NFTCardSelectionType.New &&
            !!cardHolderName &&
            cardIsValid) ||
          (cardSelectionType === NFTCardSelectionType.Saved && !!savedCardId)
        ) ||
        !bidAmount ||
        !!validationError,
      isPending,
      mutationError,
      simulationError: undefined,
      validationError,
    },
  ] as const;
};

export default useBidProductWithCreditCard;
