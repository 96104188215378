import { useCallback } from 'react';
import { useExplore } from 'pages/explore';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { EcommerceSourceType } from 'GTM';
import { ExploreType } from 'pages/explore/types';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/settings/collection/NoResults.module.css';

export default function NoResults({ unFilteredMessage, isFiltered }) {
  const explore = useExplore();
  const handleClick = useCallback(
    () =>
      explore.open(EcommerceSourceType.Settings, {
        type: ExploreType.Artworks,
      }),
    [explore]
  );

  return isFiltered ? (
    <div
      className={joinClasses(
        MPFonts.paragraphNormal,
        MPColorClass.SolidNeutralGray5,
        styles.filteredNoResults
      )}
    >
      No results found. Consider removing or broadening filters.
    </div>
  ) : (
    <div
      className={joinClasses(
        CSSGlobal.Flex.CenteredCol,
        CSSGap[16],
        styles.container
      )}
    >
      <div
        className={joinClasses(
          MPFonts.paragraphNormal,
          MPColorClass.SolidNeutralGray5
        )}
      >
        {unFilteredMessage}
      </div>
      <MPActionButton fullWidth onClick={handleClick}>
        Explore Artworks
      </MPActionButton>
    </div>
  );
}
