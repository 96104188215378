import { useCallback, useState } from 'react';

import { MPActionButton } from '@mp-frontend/core-components';

import { NFTType } from 'types/graphql/NFT';

import FixedSale from '../Listing/FixedSale';
import ReservePrice from '../Listing/ReservePrice';

interface NFTEditActionProps {
  invalidate: () => void;
  nft: NFTType;
}

export default function NFTEditAction({ nft, invalidate }: NFTEditActionProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const open = useCallback(() => setIsDialogOpen(true), []);
  const close = useCallback(() => setIsDialogOpen(false), []);
  const { hasReservePrice, liveSale } = nft.listing;
  const didCheckSaleContractStatusState = useState(false);

  return (
    <>
      <MPActionButton variant="tertiary" onClick={open}>
        Edit&nbsp;Price
      </MPActionButton>
      {!!isDialogOpen && (
        <>
          {!!liveSale && (
            <FixedSale
              close={close}
              nft={nft}
              invalidate={invalidate}
              didCheckSaleContractStatusState={didCheckSaleContractStatusState}
            />
          )}
          {!!hasReservePrice && (
            <ReservePrice close={close} nft={nft} invalidate={invalidate} />
          )}
        </>
      )}
    </>
  );
}
