.user {
  align-items: center;
}

.rightSection {
  align-items: flex-start;
  overflow: hidden;
}

.action {
  margin-top: 7px;
}
